import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { LeadCrudComponent } from 'src/app/shared/components/order-to-cash/lead-crud/lead-crud.component';

@Component({
  selector: 'app-xrm-lead-to-order',
  templateUrl: './xrm-lead-to-order.component.html',
  styleUrls: ['./xrm-lead-to-order.component.css']
})
export class XrmLeadToOrderComponent implements OnInit, OnDestroy {

  public activeStepIndex;
  public maxStepIndex;

  newElementDialog = false;

  actual_lead;
  actual_contact;
  actual_bp;
  actual_project;

  constructor(private route: ActivatedRoute, private location: Location, private xrmServiceV2: XRMServiceV2, private appLeadCrudComponent: LeadCrudComponent) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const stringValue = params['stringValue'];
      const booleanValue = params['booleanValue'];
      const edit = params['edit'];

      console.log(edit);

      if(stringValue == 'contact' && edit != 'false'){
        this.openEditDialog(stringValue, booleanValue);
      }
      else{

        if (edit != 'false') {
          this.xrmServiceV2.getDataForLeadToOrderStep(edit).subscribe((data) => {
  
            console.log('test lead step data in lead-to-order component');
  
            this.actual_lead = data.actual_lead;
            sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.actual_lead));
  
            this.actual_contact = data.actual_contact;
            sessionStorage.setItem('otc_actual_businesspartner_contact', JSON.stringify(this.actual_contact));
  
            this.actual_bp = data.actual_bp;
            sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.actual_bp));     
  
            this.actual_project = data.actual_project;
            sessionStorage.setItem('otc_actual_project', JSON.stringify(this.actual_project));
  
            this.openEditDialog(stringValue, booleanValue);
  
            // this.actual_bp = data.all_bps.find((element) => element.BusinesspartnerID == this.actual_contact.BusinesspartnerID);
            // sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.actual_bp));        
            
            // Call the openEditDialog function inside the subscribe block
            
          });
        } else {
          // If edit is falsy, you can call openEditDialog directly
          this.openEditDialog(stringValue, booleanValue);
        }
      }
    });
  }

  ngOnDestroy(){

    this.removeSessionStorage();
    //this.location.back();

  }

  openEditDialog(type, clear_storage) {
    
    switch (type) {
      case 'bp':
        this.activeStepIndex = 0;
        this.maxStepIndex = 0;
        break;

      case 'contact':
        this.activeStepIndex = 1;

        if(this.actual_lead){
          this.maxStepIndex = 2;
        }
        else{
          this.maxStepIndex = 1;
        }
        break;

      case 'lead':
        this.activeStepIndex = 2;
        this.maxStepIndex = 2;
        break;

      // case 'project':
      //   this.activeStepIndex = 3;
      //   this.maxStepIndex = 3;
      //   break;

      // case 'position':
      //   this.activeStepIndex = 4;
      //   this.maxStepIndex = 4;
      //   break;
        
      default:
        break;
    }

    if (clear_storage == 'true'){
      this.removeSessionStorage();
    }

    this.newElementDialog = true;
    
  
    // this.data_for_passing = data;
    // this.is_new_data = is_new_data;
    
  }

  removeSessionStorage() {

    

    if (sessionStorage.length > 0) {
      //sessionStorage.clear();

      //sessionStorage.removeItem('otc_actual_businesspartner_contact');
      //sessionStorage.removeItem('otc_actual_businesspartner');
      sessionStorage.removeItem('otc_actual_lead');
      sessionStorage.removeItem('otc_actual_project');
      sessionStorage.removeItem('otc_actual_position');
      sessionStorage.removeItem('otc_actual_assignment');
      sessionStorage.removeItem('otc_actual_contract_client');
      sessionStorage.removeItem('otc_actual_contract_offer');
      sessionStorage.removeItem('otc_actual_contract_resource');
      sessionStorage.removeItem('otc_actual_preassignment');

      console.log(sessionStorage);

      this.actual_lead = null;
      this.actual_contact = null;
      this.actual_bp = null;
      //this.appLeadCrudComponent.ngOnInit();
    }
    else {
      console.log('Session storage was empty');
    }
  }

  closeDialog(){

    this.location.back();

  }

}

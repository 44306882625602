// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-dialog-content {
  min-height: 30vw;
}

.no-color-change {
  color: var(--surface-300) !important;
}

@media (min-width: 768px) {
  .mobile-pt-5 {
    padding-top: 0 !important;
    /* Reset padding for non-mobile screens */
  }
  .custom-margin {
    margin-right: 1.25rem;
    /* This is equivalent to the mr-1 class */
  }
  .custom-flex {
    display: flex !important;
    margin: 1rem;
  }
  :host ::ng-deep .p-sidebar.p-sidebar-left {
    top: 0;
    left: 0;
    width: 60rem;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .mobile-pt-5 {
    padding-top: 2rem !important;
    /* Apply padding for mobile screens */
  }
  .custom-flex {
    flex: 1 1 auto !important;
    margin: 1rem;
  }
  :host ::ng-deep .p-sidebar.p-sidebar-left {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/xrm/xrm-dashboard/xrm-dashboard.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEE;EAEE,oCAAA;AAAJ;;AAIE;EACE;IACE,yBAAA;IACA,yCAAA;EADJ;EAIE;IACE,qBAAA;IACA,yCAAA;EAFJ;EAKE;IACE,wBAAA;IACA,YAAA;EAHJ;EAOI;IACE,MAAA;IACA,OAAA;IACA,YAAA;IACA,YAAA;EALN;AACF;AASI;EACE;IACE,4BAAA;IACA,qCAAA;EAPN;EAUI;IACE,yBAAA;IACA,YAAA;EARN;EAYM;IACE,MAAA;IACA,OAAA;IACA,WAAA;IACA,YAAA;EAVR;AACF","sourcesContent":[":host ::ng-deep .p-dialog-content {\n    min-height: 30vw;\n  }\n\n  .no-color-change {\n\n    color: var(--surface-300) !important;\n  \n  }\n\n  @media (min-width: 768px) {\n    .mobile-pt-5 {\n      padding-top: 0 !important;\n      /* Reset padding for non-mobile screens */\n    }\n  \n    .custom-margin {\n      margin-right: 1.25rem;\n      /* This is equivalent to the mr-1 class */\n    }\n  \n    .custom-flex {\n      display: flex !important;\n      margin: 1rem;\n    }\n  \n    :host ::ng-deep {\n      .p-sidebar.p-sidebar-left {\n        top: 0;\n        left: 0;\n        width: 60rem;\n        height: 100%;\n      }\n    }\n  }\n    \n    @media (max-width: 767px) {\n      .mobile-pt-5 {\n        padding-top: 2rem !important;\n        /* Apply padding for mobile screens */\n      }\n    \n      .custom-flex {\n        flex: 1 1 auto !important;\n        margin: 1rem;\n      }\n  \n      :host ::ng-deep {\n        .p-sidebar.p-sidebar-left {\n          top: 0;\n          left: 0;\n          width: 100%;\n          height: 100%;\n        }\n      }\n  \n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lead-card {
    background-color: #14B8A6 !important;
    color: black;
}

.interview-card {
    background-color: #87D87A !important;
}

.projects-card {
    background-color: #7AC9D8 !important;
    color: black;
}

.test-card {
  background-color: #7AC9D8 !important;
}

.hover {
    cursor: pointer;
  }

  :host ::ng-deep {
    .p-sidebar.p-sidebar-right {
      width: 75rem;
      height: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/app/components/xrm/reports/report-project/report-project-detail/report-project-detail.component.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,YAAY;AAChB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;IACI,eAAe;EACjB;;EAEA;IACE;MACE,YAAY;MACZ,YAAY;IACd;EACF","sourcesContent":[".lead-card {\n    background-color: #14B8A6 !important;\n    color: black;\n}\n\n.interview-card {\n    background-color: #87D87A !important;\n}\n\n.projects-card {\n    background-color: #7AC9D8 !important;\n    color: black;\n}\n\n.test-card {\n  background-color: #7AC9D8 !important;\n}\n\n.hover {\n    cursor: pointer;\n  }\n\n  :host ::ng-deep {\n    .p-sidebar.p-sidebar-right {\n      width: 75rem;\n      height: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

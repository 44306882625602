export class BusinesspartnerV2 {
  BusinesspartnerID: number;
  BusinesspartnerName: string;
  BusinesspartnerStatus: string;
  BusinesspartnerType: string;
  Comment: string;
  Website: string;
  EngagementPartner: number;
  EngagementPartnerID: number;
  EngagementPartnerName: string;
  CooperationInfo: string;
  BusinesspartnerSector: string;
  BusinesspartnerClass: string;
  PrimaryRole: string;
  SecondaryRole: string;
  BpAbbreviation: string;
  grandegaDokumenteLink: string;
  clientEngagementPlan: string; 
  Phone1: string;
  ParentCompany: number;

  updated_at: string;

  //For Solution-Manager
  supportDescription: string;
}

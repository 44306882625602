import { Component, OnInit } from '@angular/core';
import { LeadCrudComponent } from '../lead-crud/lead-crud.component';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { PositionV2 } from 'src/app/shared/model/xrm/V2/positionV2';
import { Assignment } from 'src/app/shared/model/xrm/assignment';
import { ContactV2 } from 'src/app/shared/model/xrm/V2/contactV2';
import { MessageService } from 'primeng/api';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { Contract } from 'src/app/shared/model/businessdivision/contract';
import { ProcessStepperPositionComponent } from '../process-stepper-position/process-stepper-position.component';
import { ReportProjectDetailComponent } from 'src/app/components/xrm/reports/report-project/report-project-detail/report-project-detail.component';

@Component({
  selector: 'app-psp-element-crud',
  templateUrl: './psp-element-crud.component.html',
  styleUrls: ['./psp-element-crud.component.css']
})
export class PspElementCrudComponent implements OnInit {

  data_loading = true;

  storedPosition: PositionV2;
  storedBusinessPartner: BusinesspartnerV2;
  storedAssignment: Assignment;
  storedPreAssignment;
  storedContractClient: Contract;
  storedContractOffer: Contract;
  storedContractResource: Contract;

  save_loading;

  constructor(
    private appLeadCrudComponent: LeadCrudComponent,
    private appProcessStepperPositionComponent: ProcessStepperPositionComponent,
    private appReportProjectDetailComponent: ReportProjectDetailComponent,
    private messageService: MessageService,
    private xrmServiceV2: XRMServiceV2
  ) {

    this.storedPosition = JSON.parse(sessionStorage.getItem('otc_actual_position'));
    this.storedAssignment = JSON.parse(sessionStorage.getItem('otc_actual_assignment'));
    this.storedBusinessPartner = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner'));
    this.storedContractClient = JSON.parse(sessionStorage.getItem('otc_actual_contract_client'));
    this.storedContractOffer = JSON.parse(sessionStorage.getItem('otc_actual_contract_offer'));
    this.storedContractResource = JSON.parse(sessionStorage.getItem('otc_actual_contract_resource'));

    console.log(this.storedContractClient);

    this.storedPreAssignment = JSON.parse(sessionStorage.getItem('otc_actual_preassignment'));

   }

  ngOnInit() {

    this.appLeadCrudComponent.activePositionStepIndex = 5;
    this.appLeadCrudComponent.maxPositionStepIndex = 5;
    this.data_loading = false;
  }

  check_mandatory_items(){

    if(this.storedAssignment.ConsultantPersonType == 'extern' && (!this.storedPosition.WorkingHoursDay || !this.storedPosition.Amount || !this.storedPosition.PositionTitle || !this.storedPosition.PositionDescription || !this.storedPosition.PositionLocation || !this.storedPosition.PositionStartDate || !this.storedPosition.PositionEndDate || !this.storedPosition.ContractType || !this.storedAssignment.VKDailyRateOnSite || !this.storedAssignment.VKDailyRateRemote || !this.storedContractClient.ContractTitle || !this.storedContractClient.ContractOwner || !this.storedContractClient.ContractStart 
      || !this.storedContractClient.ContractEnd || !this.storedContractClient.Client || !this.storedContractClient.Helper || !this.storedContractClient.Contractor || !this.storedContractClient.ContractNumber)){
      
      return true;


    }else{

      if(!this.storedPosition.WorkingHoursDay || !this.storedPosition.Amount || !this.storedPosition.PositionTitle || !this.storedPosition.PositionDescription || !this.storedPosition.PositionLocation
         || !this.storedPosition.PositionStartDate || !this.storedPosition.PositionEndDate || !this.storedPosition.ContractType
         || !this.storedContractClient.ContractTitle || !this.storedContractClient.ContractOwner || !this.storedContractClient.ContractStart 
         || !this.storedContractClient.ContractEnd || !this.storedContractClient.Client || !this.storedContractClient.Helper || !this.storedContractClient.Contractor || !this.storedContractClient.ContractNumber)
      {
        return true;
      }
            
    }

    return false;
  }

  check_optional_items(){

    return true;

  }

  save_psp_element() {

    this.storedPreAssignment.LeadManager_ms_profile_picture = null;
    this.storedPreAssignment.Delivery_ms_profile_picture = null;
    this.storedPreAssignment.ms_profile_picture = null;

    this.storedPreAssignment.Status = 'abgeschlossen';

    this.xrmServiceV2.editPreAssignment(this.storedPreAssignment).subscribe((response) => {
      if (response.result == 'success') {

        if(this.appProcessStepperPositionComponent.extension == true){

          this.messageService.add({ severity: 'success', summary: 'Information', detail: 'Verlängerung erfolgreich angelegt.' });

          this.appReportProjectDetailComponent.sidebarVisible = false;
          this.appReportProjectDetailComponent.ngOnInit();

          sessionStorage.removeItem('otc_actual_position');
          sessionStorage.removeItem('otc_actual_assignment');
          sessionStorage.removeItem('otc_actual_contract_client');
          sessionStorage.removeItem('otc_actual_contract_offer');
          sessionStorage.removeItem('otc_actual_contract_resource');
          sessionStorage.removeItem('otc_actual_preassignment');

        }else{

          this.messageService.add({ severity: 'success', summary: 'Information', detail: 'Pre-Assignment abgeschlossen.' });

          this.appLeadCrudComponent.newPreAssignmentDialog = false;
          this.appLeadCrudComponent.ngOnInit();

        }

      }
      if (response.result == 'error')
        this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

      this.save_loading = false;

    });
  }


  abortChanges(){

    this.appLeadCrudComponent.newPreAssignmentDialog = false;
    this.appLeadCrudComponent.ngOnInit();

  }


}

export class Assignment {
    AssignmentID: number;
    PreassignmentID: number;
    PersonID: number;
    PositionID: number;
    PositionTitle: string;
    BusinesspartnerID: number;
    BusinesspartnerName: string;
    BpLocationID: string;
    BpLocationName: string;
    BpLocationStreet: string;
    BpLocationStreetNumber: string;
    BpLocationPLZ: string;
    BpInvoiceInfo: string;
    BpContractInfo: string;
    ZdlName: string;
    ZdlID: number;
    ProjectID: number;
    ProjectName: string;
    ConsultantPersonID: number;
    ConsultantDisplayName: string;
    ConsultantEMail: string;
    ConsultantPersonType: string;
    ConsultantStreetNumber: string;
    ConsultantPostalCode: string;
    ConsultantResidence: string;
    ConsultantMobile: string;
    Active: string;
    Remote: string;
    DailyRateOnSite: string;
    DailyRateRemote: string;
    VKDailyRateOnSite: string;
    VKDailyRateRemote: string;
    CustomerDecision;
    Interview;
    Conversation;
    Condition;
    Profile;
    SentProfile;
    AllianceEMail: string;
    AlliancePortal: string;
    transformed: string;
    created_by: number;
    Rating: number;
    Comment: string;
    created_at: string;
    CheckRK: string;
    CheckRKAmount: number;
    RKrelevant: string;

    PositionStartDate: Date;
    PositionEndDate: Date;
    PositionStatus: string;
    LeadProbability: number;
    LeadID: number;
    LeadTitle: string;
}

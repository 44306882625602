import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { Monthlyfinal } from '../../model/monthlyfinal';
import { User } from '../../model';
import { AuthenticationService } from '../../services';
import { EmployeeService } from '../../services/employee.service';
import moment from 'moment';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs;
  public title;

  //Monthly Final
  public lastMonthly: Monthlyfinal;
  currentUser: User;
  RoleIDs;
  showPopUp = false;
  actualMonth;
  actualYear;
  actualMonthly: Monthlyfinal;
  endOfMonth = moment().clone().endOf('month').date(); //Letzer tag des monats

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private employeeService: EmployeeService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route) => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data['title'];
        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];
        this.title = title;
        this.breadcrumbs = {
          parentBreadcrumb: parent,
          childBreadcrumb: child,
        };
      });
  }

  ngOnInit() {
    //-----MonthlyFinal-----
    this.lastMonthly = new Monthlyfinal();
    this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
    //  if (this.RoleIDs.some(el => (el === "107"))) {
    //    this.AdminTemp = true;
    //  }

    this.currentUser = this.authenticationService.currentUserValue;

    if (JSON.parse(sessionStorage.getItem('lastMonthly')) == null) {
      if (moment().month() == 0) {
        this.employeeService
          .getMonthlyFinal(this.currentUser.PersonID, 12, moment().subtract(1, 'M').year())
          .subscribe((monthly) => {
            this.lastMonthly = monthly;
            sessionStorage.setItem('lastMonthly', JSON.stringify(this.lastMonthly));
          });
      } else {
        this.employeeService
          .getMonthlyFinal(this.currentUser.PersonID, moment().month(), moment().subtract(1, 'M').year())
          .subscribe((monthly) => {
            this.lastMonthly = monthly;
            sessionStorage.setItem('lastMonthly', JSON.stringify(this.lastMonthly));
          });
      }
    } else {
      this.lastMonthly = JSON.parse(sessionStorage.getItem('lastMonthly'));
    }

    if (this.lastMonthly != null && this.lastMonthly.Completed == 'false') {
      this.showPopUp = true;
      if (moment().month() == 0) {
        this.actualMonth = 12;
      } else {
        this.actualMonth = moment().month();
      }
      this.actualYear = moment().subtract(1, 'M').year();
    } else {
      if (moment().month() == 11) {
        this.actualMonth = 12;
      } else {
        this.actualMonth = moment().add(1, 'M').month();
      }
      this.actualYear = moment().year();

      if (JSON.parse(sessionStorage.getItem('actualMonthly')) == null) {
        this.employeeService
          .getMonthlyFinal(this.currentUser.PersonID, this.actualMonth, moment().year())
          .subscribe((actualMonthly) => {
            this.actualMonthly = actualMonthly;

            if (this.actualMonthly != null && this.endOfMonth - 5 <= moment().date()) {
              this.showPopUp = this.actualMonthly.Completed == 'false';
              sessionStorage.setItem('actualMonthly', JSON.stringify(this.actualMonthly));
            }
            if (this.actualMonthly == null && this.endOfMonth - 5 <= moment().date()) {
              this.showPopUp = true;
            }
          });
      } else {
        this.actualMonthly = JSON.parse(sessionStorage.getItem('actualMonthly'));
        //Tage ändern
        this.showPopUp = this.actualMonthly.Completed == 'false' && this.endOfMonth - 5 <= moment().date();
      }
    }
    console.log('Show popup');
  }

  routing() {
    // this.router.navigate(['/dashboard/monatsabschluss/' + this.actualMonth + '/' + this.actualYear]);
    const url = 'https://tst.grandega-access.de/dashboard/monatsabschluss/' + this.actualMonth + '/' + this.actualYear;
    window.location.href = url;
  }

  ngOnDestroy() {}
}

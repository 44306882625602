// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoutButton {
    background-color: white;
    color: rgb(3,130,136);
    margin-bottom: 2vh;
    width: 160px;
    height: 40px;
    border-style: solid;
    border-color: rgb(3,130,136);
    border-width: 2px;
    transition: background-color 0.5s;
    cursor: pointer;
}

.logoutButton:hover {
    background-color: rgb(3,130,136);
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/session-over/session-over.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,4BAA4B;IAC5B,iBAAiB;IAEjB,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,YAAY;AAChB","sourcesContent":[".logoutButton {\n    background-color: white;\n    color: rgb(3,130,136);\n    margin-bottom: 2vh;\n    width: 160px;\n    height: 40px;\n    border-style: solid;\n    border-color: rgb(3,130,136);\n    border-width: 2px;\n    -webkit-transition: background-color 0.5s;\n    transition: background-color 0.5s;\n    cursor: pointer;\n}\n\n.logoutButton:hover {\n    background-color: rgb(3,130,136);\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-user {
  color: rgb(3, 130, 136);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 3px;
}

.layout-topbar-text-custom {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,yBAAA;EACA,gBAAA;EACA,qBAAA;EACA,kBAAA;AACJ;;AACA;EAEQ,iBAAA;EAEA,aAAA;EAEA,mBAAA;EAEA,yBAAA;EACA,YAAA;EACA,qBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AACR","sourcesContent":[".sidebar-user {\n    color: rgb(3, 130, 136);\n    text-transform: uppercase;\n    font-weight: 600;\n    letter-spacing: 1.5px;\n    margin-bottom: 3px; }\n\n.layout-topbar-text-custom{\n\n        text-align: right;\n        display: -ms-flexbox;\n        display: flex;\n        -ms-flex-align: center;\n        align-items: center;\n        -ms-flex-pack: end;\n        justify-content: flex-end;\n        flex-grow: 1;\n        list-style-type: none;\n        margin: 0;\n        padding: 0;\n        height: 100%;\n    \n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export class ContactV2 {
  ContactID: number;
  PersonID: number;
  BusinesspartnerID: number;
  BusinesspartnerName: string;
  ContactDisplayname: string;
  ContactForename: string;
  ContactSurName: string;
  AcademicTitle: string;
  ContactPosition: string; 
  ContactSkillLevel: string;
  ContactTitle: string;
  Language: string;
  ContactPhone: string;
  ContactMobile: string;
  ContactEMail: string;

  Comment: string;
  created_by: number;

  EmployeeID: number;
  EmployeeDisplayName: string;
  Attitude: string;
  Reference: string;
}

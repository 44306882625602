import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'browserslist';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { PSPElemente } from 'src/app/shared/model';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { ProjectV2 } from 'src/app/shared/model/xrm/V2/projectV2';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';

@Component({
  selector: 'app-report-project-detail',
  templateUrl: './report-project-detail.component.html',
  styleUrls: ['./report-project-detail.component.css']
})
export class ReportProjectDetailComponent implements OnInit {


  project: ProjectV2 = new ProjectV2();
  project_volume;
  expiring_pspelements: PSPElemente[] = [];
  project_remaining_budget;
  pspelements: PSPElemente[] = [];

  selected_pspelement!: PSPElemente;


  data_loading = true;


  sidebarVisible;
  active_sidebar_data;

  todayPlus30Days;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private xrmServiceV2: XRMServiceV2,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {

    this.route.params.subscribe(params => {

      console.log(params['id']);

      this.project.ProjectID = +params['id'];

      console.log(this.project.ProjectID);

    })

    this.todayPlus30Days = new Date();
        this.todayPlus30Days.setDate(this.todayPlus30Days.getDate() + 30);

  }

  ngOnInit() {

    this.loadData(this.project.ProjectID);

  }

  async loadData(ProjectID) {

    const response = await firstValueFrom(this.xrmServiceV2.getDataForProjectReport(ProjectID));

    console.log(response);

    this.project = response.project;
    this.project_volume = response.project_volume;
    this.expiring_pspelements = response.expiring_pspelements;
    this.project_remaining_budget = response.project_remaining_budget;
    this.pspelements = response.project_pspelements;


    this.data_loading = false;

  }

  parseValidtoDate(validto): Date {
    return new Date(validto);
  }

  showDetailSidebar(view) {

    this.active_sidebar_data = view;

    this.sidebarVisible = true;

  }

  //--------------------------------------extend psp element----------------------------------------------

  activePositionStepIndex = 0;
  public maxPositionStepIndex = 0;
  is_intern;

  actual_lead;
  actual_contact;
  actual_bp;

  created_preassignment;

  old_psp_element;


  async extend_pspelement() {

    console.log(this.selected_pspelement);

    this.old_psp_element = this.selected_pspelement;

    this.selected_pspelement.ms_profile_picture = null;

    sessionStorage.setItem('otc_actual_project', JSON.stringify(this.project));

    this.xrmServiceV2.getDataForLeadToOrderStep(this.selected_pspelement.LeadID).subscribe((data) => {

      console.log(data);

      this.actual_lead = data.actual_lead;
      sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.actual_lead));

      this.actual_contact = data.actual_contact;
      sessionStorage.setItem('otc_actual_businesspartner_contact', JSON.stringify(this.actual_contact));

      this.actual_bp = data.actual_bp;
      sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.actual_bp));

    });

    this.selected_pspelement.Prolongation = 'true';

    this.xrmServiceV2.createPreassignment(this.selected_pspelement).subscribe((response) => {
      if (response.result == 'success') {

        this.created_preassignment = response.newlyCreatedObject;

        console.log(this.created_preassignment);

        this.created_preassignment
          ? sessionStorage.setItem('otc_actual_preassignment', JSON.stringify(this.created_preassignment))
          : sessionStorage.removeItem('otc_actual_preassignment');

      }
      if (response.result == 'error') {
        this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });
      }
    });

    const x = await firstValueFrom(this.xrmServiceV2.getDataForEditPreAssignment(this.selected_pspelement.PreassignmentID));

    x.assignment.AssignmentID = null;
    x.position.PositionID = null;

    if (x.contract_client) {
    x.contract_client.ContractClientID = null;
    sessionStorage.setItem('otc_actual_contract_client', JSON.stringify(x.contract_client));
    }

    if (x.contract_resource) {
      x.contract_resource.ContractResourceID = null;
      sessionStorage.setItem('otc_actual_contract_resource', JSON.stringify(x.contract_resource));
    }

    if (x.contract_offer) {
      x.contract_offer.ContractOfferID = null;
      sessionStorage.setItem('otc_actual_contract_offer', JSON.stringify(x.contract_offer));
    }

    sessionStorage.setItem('otc_actual_assignment', JSON.stringify(x.assignment));
    sessionStorage.setItem('otc_actual_position', JSON.stringify(x.position));

    const storedAssignment = x.assignment;

    if (storedAssignment && storedAssignment.ConsultantPersonType == 'extern') {

      this.is_intern = false;

    } else {

      this.is_intern = true;

    }

    this.maxPositionStepIndex = 0;
    this.activePositionStepIndex = 0;

    this.showDetailSidebar('extend_pspelement');

  }

  onHideSidebar(event: Event) {

    if (this.active_sidebar_data == 'extend_pspelement') {

      this.confirmationService.confirm({
        message: 'Verlängerung nicht abgeschlossen, möchtest du den Vorgang abbrechen?',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        icon: 'pi pi-question',
        accept: () => {

          this.xrmServiceV2.deletePreAssignment(this.created_preassignment.PreassignmentID).subscribe(result => {
            if (result.result === 'success') {
              this.sidebarVisible = false;
              this.messageService.add({ severity: 'info', summary: 'Information', detail: 'Verlängerung wurde abgebrochen', life: 3000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Fehler', detail: result.result, life: 3000 });
            }
          });

          this.old_psp_element.Takeover = 'false';

          this.xrmServiceV2.editPosition(this.old_psp_element).subscribe(result => {
            if (result.result === 'success') {
            } else {
              this.messageService.add({ severity: 'error', summary: 'Fehler', detail: result.result, life: 3000 });
            }
          });

          sessionStorage.removeItem('otc_actual_position');
          sessionStorage.removeItem('otc_actual_assignment');
          sessionStorage.removeItem('otc_actual_contract_client');
          sessionStorage.removeItem('otc_actual_contract_offer');
          sessionStorage.removeItem('otc_actual_contract_resource');
          sessionStorage.removeItem('otc_actual_preassignment');

        },
        reject: () => {
          this.sidebarVisible = true;
        }
      });
    }
  }


}

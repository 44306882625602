import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-final-approval',
  templateUrl: './final-approval.component.html',
  styleUrls: ['./final-approval.component.css']
})
export class FinalApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileCard-realName .realname {
  color: #949598 !important;
}

.offcanvas-bookmark.page-wrapper .page-body-wrapper .page-body .bookmark ul li a {
  display: inline-block;
}

.bookmark ul li a {
  color: #313131;
}

i.fa.fa-star-o.f-18.mt-1.starred {
  color: orange;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/breadcrumb/breadcrumb.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":[".ProfileCard-realName .realname {\n    color: #949598 !important;\n}\n\n.offcanvas-bookmark.page-wrapper .page-body-wrapper .page-body .bookmark ul li a {\n    display: inline-block;\n}\n\n.bookmark ul li a {\n    color: #313131 ;\n}\n\ni.fa.fa-star-o.f-18.mt-1.starred {\n    color: orange;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export class PositionV2 {

  PositionID: number;
  PositionTitle: String;
  PreassignmentID: number;

  //PositionStatus: String;
  //EpID: number;
  //EpDisplayName: String;
  
  //HardFacts: String;
  PositionStartDate: Date;
  PositionEndDate: Date;
  VolumeFTE: String;
  Amount: String;
  BusinesspartnerID: number;
  BusinesspartnerName: String;
  BpAbbreviation: String;

  ProjectOwnerID: number;
  ProjectOwnerForename: String;
  ProjectOwnerSurName: String;

  ZdlID: number;
  ZdlName: String;

  ContactID: number;
  ContactForename: String;
  ContactSurName: String;

  PositionLocation: String;
  PositionDescription: String;
  PositionSkills: String;  
  ContractType: String;
  HourlyRate: String;
  //CommentSalary: String;
  PercentageRemote: String;
  Takeover: String;

  SellingPrice: String;
  WorkingHoursDay: number;
  //AnnualSalary: String;
  
  //Unlimited: String;
  LeadID: number;
  ProjectID: number;
  ProjectName: String;
  ProjectLanguage: String;
  ProjectDescription: String;
  ProjectStatus: String;
  //PermanentEmployee: String;
  BpInvoiceInfo: String;
  BpContractInfo: String;
  LeadComment: String;
  Probability: number;
  created_by: number;
  updated_by: number;

  DeliveryResponsible: number;

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from '../model';
import { Employee } from '../model/employee';
import { EmployeeProfile } from '../model/employeeprofile';
import { EmployeeRoles } from '../model/employeeroles';
import { License } from '../model/license';

@Injectable({ providedIn: 'root' })
export class GraphService {
  role: string;

  constructor(private http: HttpClient) {}

  getAvailiableGranshareWorkspaces(start_date, end_date, placetype) {
    return this.http.post<any>(`${environment.apiUrl}/getAvailiableWorkspacesInGivenTime`, {
      start_date: start_date,
      end_date: end_date,
      placetype: placetype,
    });
  }

  bookGranshareWorkspace(start_date, end_date, workspaceArray) {
    return this.http.post<any>(`${environment.apiUrl}/bookGranshareWorkspace`, {
      start_date: start_date,
      end_date: end_date,
      workspaceArray: workspaceArray,
    });
  }

  getMybookedWorkspaces() {
    return this.http.get<any>(`${environment.apiUrl}/getMyBookedGranshareWorkspaces`);
  }

  cancelBooking(granshare_workspace_booking_id) {
    return this.http.put<any>(
      `${environment.apiUrl}/cancelGranshareWorkspaceBooking/` + granshare_workspace_booking_id,
      {}
    );
  }

  getAllEmployeeLocations(startDate, endDate) {
    return this.http.get<any>(`${environment.apiUrl}/getAllEmployeeLocations/` + startDate + '/' + endDate);
  }

  getTomorrowOfficeLocations() {
    return this.http.get<any>(`${environment.apiUrl}/getTomorrowOfficeLocations`);
  }

  setUserLocationForMultipleDates(dates: any[]) {
    return this.http.post<any>(`${environment.apiUrl}/msGraph/setUserLocationForMultipleDates`, {
      dates: dates,
    });
  }

  getMyNextEvents() {
    return this.http.get<any>(`${environment.apiUrl}/msGraph/getMyNextEvents`);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UploadService {
    constructor(private http: HttpClient) { }


    uploadExpenseReceipt(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/expensereceipt`, data, {
            headers: headers
        });
    }

    uploadPerformanceRecord(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/uploadPRecord`, data, {
            headers: headers
        });
    }

    uploadAssetHandoverProtocol(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/uploadhandoverprotocol`, data, {
            headers: headers
        });
    }

    uploadContractDoc(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/contractdatafiles`, data, {
            headers: headers
        });
    }

    getMondayVideoHistory(){
        return this.http.get<any>(`${environment.apiUrl}/mondayVideoHistory`);
    }

    uploadMondayVideo(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/uploadMondayVideo`, data, {
            headers: headers
        });
    }

    deleteMondayVideo(AllgDocumentID: number){
        return this.http.delete<any>(`${environment.apiUrl}/mondayVideo/`+AllgDocumentID);
    }

}

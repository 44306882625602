export class Address {
      LocationID: number;
      BusinesspartnerID: number;
      SameInvoiceAddress: boolean;
      Country: string;
      LocationStreetNumber: string;
      LocationPLZ : number;
      LocationName: string;
      LocationMailBox: string;
      LocationType: string
      State: string;  
      IsInvoiceAddress: boolean;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DashboardServiceV2 {
  constructor(private http: HttpClient) { }

  //V2
  getDataForDashboardStrategy() {
    return this.http.get<any>(`${environment.apiUrl}/dashboard/strategy`);
  }

  getDataForDashboardMyWorkload(Year) {
    return this.http.get<any>(`${environment.apiUrl}/dashboard/my-workload/` + Year);
  }

  getWholeMonthlyReportData(Month,Year) {
    return this.http.get<any>(`${environment.apiUrl}/dashboard/monthly-report/`+Month+"/"+Year);
  }

  saveMonthlyReportStep(data) {
    return this.http.put<any>(`${environment.apiUrl}/dashboard/monthly-report/edit`, data);
  }

  createPerformanceRecordEntry(data) {
    return this.http.post<any>(`${environment.apiUrl}/dashboard/monthly-report/performance-record/create`, data);
  }

  createEmployeeStatisfaction(data) {
    return this.http.post<any>(`${environment.apiUrl}/dashboard/monthly-report/employee-satisfaction/create`, data);
  }

  editEmployeeStatisfaction(data) {
    return this.http.put<any>(`${environment.apiUrl}/dashboard/monthly-report/employee-satisfaction/edit`, data);
  }

  getProfileData() {
    return this.http.get<any>(`${environment.apiUrl}/dashboard/my-profile/profile-data`);
  }

  requestProfile(selected_profiles_add) {
    return this.http.post<any>(`${environment.apiUrl}/dashboard/my-profile/request-profile`, { data: selected_profiles_add});

  }

}
import { Component, OnInit } from '@angular/core';
import { Employee } from 'src/app/shared/model/employee';
import { Assignment } from 'src/app/shared/model/xrm/assignment';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { LeadCrudComponent } from '../lead-crud/lead-crud.component';
import { MessageService } from 'primeng/api';
import { PositionV2 } from 'src/app/shared/model/xrm/V2/positionV2';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { element } from 'protractor';
import { Consultant } from 'src/app/shared/model/xrm/consultant';
import { ProcessStepperPositionComponent } from '../process-stepper-position/process-stepper-position.component';
import { ReportProjectDetailComponent } from 'src/app/components/xrm/reports/report-project/report-project-detail/report-project-detail.component';
import { ReportMissingdataComponent } from 'src/app/components/xrm/reports/report-missingdata/report-missingdata.component';

@Component({
  selector: 'app-assignment-crud',
  templateUrl: './assignment-crud.component.html',
  styleUrls: ['./assignment-crud.component.css']
})
export class AssignmentCrudComponent implements OnInit {

  // selectedStatus: any[] = [];

  // categories: any[] = [
  //   { name: 'Gespräch', key: 'Conversation' },
  //   { name: 'Kondition', key: 'Condition' },
  //   { name: 'Beraterprofil bereit', key: 'Profile' },
  //   { name: 'Profil versendet', key: 'SentProfile' },
  //   { name: 'Interview', key: 'Interview' },
  //   { name: 'Kundenentscheidung', key: 'CustomerDecision' }
  // ];


  data_loading = true;
  all_consultants: Consultant[];
  all_employees: Employee[];
  DeliveryResponsible: Employee;
  all_assignments: Assignment[];
  potencial_overlap: Assignment[];

  potencial_overlap_dialog: boolean = false;

  save_loading = false;

  actual_assignment: Assignment = new Assignment();

  actual_position: PositionV2;

  private subscription?: Subscription;

  alliance_mail_selected;
  alliance_mail_show;

  stored_preassignment;

  old_CustomerDecision;


  constructor(
    private appLeadCrudComponent: LeadCrudComponent,
    private xrmServiceV2: XRMServiceV2,
    private messageService: MessageService,
    private sharedService: SharedService,
    private appProcessStepperPositionComponent: ProcessStepperPositionComponent,
    private appReportProjectDetailComponent: ReportProjectDetailComponent,
    private appReportMissingdataComponent: ReportMissingdataComponent
  ) {
    this.subscription = this.sharedService.save$.subscribe(() => this.save_assignment(false));
  }

  ngOnInit() {

    switch (this.appProcessStepperPositionComponent.state) {
      case 'lead':
        this.appLeadCrudComponent.activePositionStepIndex = 2;

        break;
      case 'extension':
        this.appReportProjectDetailComponent.activePositionStepIndex = 2;

        break;

      case 'edit':
        this.appReportMissingdataComponent.activePositionStepIndex = 2;
        this.appReportProjectDetailComponent.activePositionStepIndex = 2;

        break;
    }


    // if (this.appProcessStepperPositionComponent.extension == true) {

    //   this.appReportProjectDetailComponent.activePositionStepIndex = 2;

    // }
    // else {

    //   this.appLeadCrudComponent.activePositionStepIndex = 2;

    // }

    const apiCalls: { [key: string]: Observable<any> } = {
      all_consultants: this.xrmServiceV2.getConsultants(),
      all_assignments: this.xrmServiceV2.getAssignments(),
      all_employees: this.xrmServiceV2.getEmployees()
    };

     // Make all API calls in parallel
     forkJoin(apiCalls).subscribe({
      next: (results: any) => {
        this.all_consultants = results.all_consultants;
        this.all_assignments = results.all_assignments;
        this.all_employees = results.all_employees.filter(element => element.Active == 'ja');

        this.loadStoredData();
        this.data_loading = false;
      },
      error: (error) => {
        console.error('Error fetching data:', error);
        this.data_loading = false;
        // Handle error (e.g., show error message)
      }
    });


    // this.xrmServiceV2.getDataForAssignmentStep().subscribe((data) => {

    //   this.all_consultants = data.all_consultants;
    //   this.all_assignments = data.all_assignments;
    //   this.all_employees = data.all_employees.filter(element => element.Active == 'ja');

    //   this.loadStoredData();
    // });

  }

  loadStoredData() {

    this.stored_preassignment = JSON.parse(sessionStorage.getItem('otc_actual_preassignment'));
    this.actual_assignment.PreassignmentID = this.stored_preassignment.PreassignmentID;

    console.log(this.actual_assignment);

    //Set Assignment
    let storedAssignment = JSON.parse(sessionStorage.getItem('otc_actual_assignment'));

    console.log(storedAssignment);

    this.actual_position = JSON.parse(sessionStorage.getItem('otc_actual_position'));

    // if (storedAssignment) {

    //   this.actual_assignment
    //   ? sessionStorage.setItem('otc_actual_assignment', JSON.stringify(this.actual_assignment))
    //   : sessionStorage.removeItem('otc_actual_assignment');

    // } else {
    //   this.actual_assignment = new Assignment();
    // }

    if (storedAssignment) {
      this.actual_assignment = storedAssignment;

      console.log(this.actual_assignment);

      // this.DeliveryResponsible = this.all_employees.find(
      //   (element) => element.PersonID === this.actual_assignment.DeliveryResponsible
      // );

      this.actual_assignment.Conversation = this.actual_assignment.Conversation === 'true';
      this.actual_assignment.Condition = this.actual_assignment.Condition === 'true';
      this.actual_assignment.Profile = this.actual_assignment.Profile === 'true';
      this.actual_assignment.SentProfile = this.actual_assignment.SentProfile === 'true';
      this.actual_assignment.Interview = this.actual_assignment.Interview === 'true';

      this.old_CustomerDecision = this.actual_assignment.CustomerDecision;

      if (this.actual_assignment.CustomerDecision == 'null' || this.actual_assignment.CustomerDecision == 'undefined') {
        this.actual_assignment.CustomerDecision = null;
      }
      else {
        this.actual_assignment.CustomerDecision = this.actual_assignment.CustomerDecision === 'true';
      }

      this.alliance_mail_selected = Boolean(this.actual_assignment.AllianceEMail);

      if (this.actual_assignment.ConsultantPersonType == 'extern')
        this.alliance_mail_show = Boolean(!this.actual_assignment.AllianceEMail);

    } else {
      this.actual_assignment = new Assignment();
      //this.DeliveryResponsible = this.all_employees.find(element => element.PersonID == 41);
    }

    this.actual_assignment.PositionID = this.actual_position.PositionID;

    this.data_loading = false;
  }

  abortChanges() {

    this.appLeadCrudComponent.newPreAssignmentDialog = false;

  }

  check_selected_consultant(event) {

    this.potencial_overlap = this.all_assignments.filter(element => {
      return element.ConsultantPersonID == event.value &&
        ((element.PositionStartDate <= this.actual_assignment.PositionEndDate && element.PositionStartDate >= this.actual_assignment.PositionStartDate) ||
          (element.PositionEndDate >= this.actual_assignment.PositionStartDate && element.PositionEndDate <= this.actual_assignment.PositionEndDate) ||
          (element.PositionStartDate <= this.actual_assignment.PositionStartDate && element.PositionEndDate >= this.actual_assignment.PositionEndDate));
    })

    if (this.potencial_overlap.length > 0)
      this.potencial_overlap_dialog = true;

    const ConsultantPersonType = this.all_consultants.find(element => element.PersonID == event.value).PersonType;

    this.actual_assignment.ConsultantPersonType = ConsultantPersonType;

    if (ConsultantPersonType == 'extern') {
      this.alliance_mail_show = true;
    }
    else {
      this.alliance_mail_show = false;
    }

  }



  save_assignment(next) {

    const ConsultantPersonType = this.all_consultants.find(element => element.PersonID == this.actual_assignment.ConsultantPersonID).PersonType;

    this.actual_assignment.ConsultantPersonType = ConsultantPersonType;

    if (this.actual_assignment.ConsultantPersonType == 'extern') {

      this.appProcessStepperPositionComponent.is_intern = false;

    } else {

      this.appProcessStepperPositionComponent.is_intern = true;

    }


    this.appProcessStepperPositionComponent.refreshItems();    

    this.actual_assignment.PreassignmentID = this.stored_preassignment.PreassignmentID;

    this.actual_assignment.AllianceEMail = this.alliance_mail_selected;

    this.actual_assignment.PersonID = this.actual_assignment.ConsultantPersonID;

    //this.actual_assignment.DeliveryResponsible = this.DeliveryResponsible.PersonID;

    this.actual_assignment.Conversation = String(this.actual_assignment.Conversation);
    this.actual_assignment.Condition = String(this.actual_assignment.Condition);
    this.actual_assignment.Profile = String(this.actual_assignment.Profile);
    this.actual_assignment.SentProfile = String(this.actual_assignment.SentProfile);
    this.actual_assignment.Interview = String(this.actual_assignment.Interview);

    const CustomerDecision = this.actual_assignment.CustomerDecision;
    this.actual_assignment.CustomerDecision = String(this.actual_assignment.CustomerDecision);

    if (this.actual_assignment.AssignmentID != null) {

      this.xrmServiceV2.editAssignment(this.actual_assignment).subscribe((response) => {
        if (response.result == 'success') {
          this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

          this.actual_assignment = response.newlyEditedObject;

          this.actual_assignment
            ? sessionStorage.setItem('otc_actual_assignment', JSON.stringify(this.actual_assignment))
            : sessionStorage.removeItem('otc_actual_assignment');

          console.log(this.actual_assignment);

          this.subscription.unsubscribe();

        }
        if (response.result == 'error') {
          this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });
          this.subscription.unsubscribe();
        }

        this.save_loading = false;

      });
    }
    else {

      this.xrmServiceV2.createAssignment(this.actual_assignment).subscribe((response) => {
        if (response.result == 'success') {
          this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

          this.actual_assignment = response.newlyCreatedObject;

          this.actual_assignment.ConsultantPersonID = this.actual_assignment.PersonID;
          console.log(this.actual_assignment);

          this.actual_assignment
            ? sessionStorage.setItem('otc_actual_assignment', JSON.stringify(this.actual_assignment))
            : sessionStorage.removeItem('otc_actual_assignment');

          this.subscription.unsubscribe();
        }
        if (response.result == 'error') {
          this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });
          this.subscription.unsubscribe();
        }

        this.save_loading = false;
      });

    }

    if (this.actual_assignment.CustomerDecision == 'true' && next) {

      if (this.actual_assignment.ConsultantPersonType == 'extern') {

        switch (this.appProcessStepperPositionComponent.state) {
          case 'lead':
            this.appLeadCrudComponent.activePositionStepIndex = 3;
            this.appLeadCrudComponent.is_intern = false;

            if (this.appLeadCrudComponent.maxPositionStepIndex == 2)
              this.appLeadCrudComponent.maxPositionStepIndex++;
  
            break;
  
          case 'extension':
            this.appReportProjectDetailComponent.activePositionStepIndex = 3;
            this.appReportProjectDetailComponent.is_intern = false;

            if (this.appReportProjectDetailComponent.maxPositionStepIndex == 2)
              this.appReportProjectDetailComponent.maxPositionStepIndex++;
  
            break;
  
          case 'edit':
  
            this.appReportMissingdataComponent.activePositionStepIndex = 3;
            this.appReportMissingdataComponent.is_intern = false;

            if (this.appReportMissingdataComponent.maxPositionStepIndex == 2)
              this.appReportMissingdataComponent.maxPositionStepIndex++;

            this.appReportProjectDetailComponent.activePositionStepIndex = 3;
            this.appReportProjectDetailComponent.is_intern = false;
  
            if (this.appReportProjectDetailComponent.maxPositionStepIndex == 2)
              this.appReportProjectDetailComponent.maxPositionStepIndex++;
  
            break;
        }



      } else {

        switch (this.appProcessStepperPositionComponent.state) {
          case 'lead':
            this.appLeadCrudComponent.activePositionStepIndex = 3;
  
            if (this.appLeadCrudComponent.maxPositionStepIndex == 2)
              this.appLeadCrudComponent.maxPositionStepIndex++;
  
            break;
  
          case 'extension':
            this.appReportProjectDetailComponent.activePositionStepIndex = 3;
  
            if (this.appReportProjectDetailComponent.maxPositionStepIndex == 2)
              this.appReportProjectDetailComponent.maxPositionStepIndex++;
  
            break;
  
          case 'edit':
  
            this.appReportMissingdataComponent.activePositionStepIndex = 3;
  
            if (this.appReportMissingdataComponent.maxPositionStepIndex == 2)
              this.appReportMissingdataComponent.maxPositionStepIndex++;
  
            this.appReportProjectDetailComponent.activePositionStepIndex = 3;
  
            if (this.appReportProjectDetailComponent.maxPositionStepIndex == 2)
              this.appReportProjectDetailComponent.maxPositionStepIndex++;
  
            break;
        }


      }


      

    } else {

      this.actual_assignment.Conversation = this.actual_assignment.Conversation === 'true';
      this.actual_assignment.Condition = this.actual_assignment.Condition === 'true';
      this.actual_assignment.Profile = this.actual_assignment.Profile === 'true';
      this.actual_assignment.SentProfile = this.actual_assignment.SentProfile === 'true';
      this.actual_assignment.Interview = this.actual_assignment.Interview === 'true';

      if (CustomerDecision == undefined) {
        this.actual_assignment.CustomerDecision = null;
      }
      else {
        this.actual_assignment.CustomerDecision = CustomerDecision;
      }

      switch (this.appProcessStepperPositionComponent.state) {
        case 'lead':
          this.appLeadCrudComponent.ngOnInit();
          this.appLeadCrudComponent.newPreAssignmentDialog = false;

          break;

        case 'edit':

          this.appReportMissingdataComponent.ngOnInit();
          this.appReportMissingdataComponent.sidebarVisible = false;

          break;

        case 'extension':
            
            this.appReportProjectDetailComponent.ngOnInit();
            this.appReportProjectDetailComponent.sidebarVisible = false;
  
            break;
      }

    }

    if (this.old_CustomerDecision != this.actual_assignment.CustomerDecision) {

      if (this.actual_assignment.CustomerDecision == false) {

        this.stored_preassignment.Status = 'Absage';

        this.stored_preassignment.LeadManager_ms_profile_picture = null;
        this.stored_preassignment.Delivery_ms_profile_picture = null;
        this.stored_preassignment.ms_profile_picture = null;

        this.xrmServiceV2.editPreAssignment(this.stored_preassignment).subscribe((response) => {
          if (response.result == 'success') {

            this.messageService.add({ severity: 'success', summary: 'Information', detail: 'Pre-Assignment geschlossen.' });

            switch (this.appProcessStepperPositionComponent.state) {
              case 'lead':
                this.appLeadCrudComponent.ngOnInit();
                this.appLeadCrudComponent.newPreAssignmentDialog = false;

                break;

              case 'edit':

                this.appReportMissingdataComponent.ngOnInit();
                this.appReportMissingdataComponent.sidebarVisible = false;

                this.appReportProjectDetailComponent.ngOnInit();
                this.appReportProjectDetailComponent.sidebarVisible = false;

                break;
            }

          }
          if (response.result == 'error')
            this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

          this.save_loading = false;

        });

      }

      if (this.old_CustomerDecision == 'false') {

        if (this.actual_assignment.CustomerDecision == true || this.actual_assignment.CustomerDecision == null) {

          this.stored_preassignment.Status = 'offen';

          this.stored_preassignment.LeadManager_ms_profile_picture = null;
          this.stored_preassignment.Delivery_ms_profile_picture = null;
          this.stored_preassignment.ms_profile_picture = null;

          this.xrmServiceV2.editPreAssignment(this.stored_preassignment).subscribe((response) => {
            if (response.result == 'success') {

              this.messageService.add({ severity: 'success', summary: 'Information', detail: 'Pre-Assignment wieder geöffnet.' });

            }
            if (response.result == 'error')
              this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

            this.save_loading = false;

          });

        }

      }

    }

  }

}

import { Component } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { ContentLayoutComponent } from '../layout/content-layout/content-layout.component';
import { Subscription } from 'rxjs';
import { AuthenticationService, UserService } from '../../services';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UploadService } from '../../services/upload.service';
import { User } from '../../model';

@Component({
    selector: 'app-my-profile',
    templateUrl: './app.my-profile.component.html'
})
export class AppMyProfileComponent {

    subscription: Subscription;


    //My Profile
    hrefPrefix: string = `${environment.apiUrl}`;

    current_user;


    loading: Boolean = false;
    
    //------------------------------DIALOGS------------------------------

    editDialog = false;
    tabIndex = 0;
    
    constructor(
        private authenticationService: AuthenticationService,
        public appMain: ContentLayoutComponent,
    ) {

        this.current_user = this.authenticationService.currentUserValue;
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



    // -----------------------------------------Profile Dialog-----------------------------------------


    dialogClose() {
        this.loading = true;
        this.appMain.myProfileActive = false;
    }


    loadData() {

    }

    editUserDialog() {
        this.loadData();
        this.editDialog = true;
    }

    hideDialog() {
        this.editDialog = false;
    }


}

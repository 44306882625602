// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-dialog-content {
    min-height: 350px;
  }`, "",{"version":3,"sources":["webpack://./src/app/shared/components/order-to-cash/assignment-crud/assignment-crud.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;EACnB","sourcesContent":[":host ::ng-deep .p-dialog-content {\n    min-height: 350px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from '../model';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    // only for admin
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    // only for admin
    getById(PersonID: number) {
        return this.http.get(`${environment.apiUrl}/users/${PersonID}`);
    }

    // only for admin
    getMyProfilePicture() {
        return this.http.get<any>(`${environment.apiUrl}/myProfilePicture`);
    }

    // only for admin
    register(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    update(user: User) {
        return this.http.put<any>(`${environment.apiUrl}/users/${user.PersonID}`, user);
    }
    updateBankInfo(user: User) {
        return this.http.put<any>(`${environment.apiUrl}/users/bankInfo/${user.PersonID}`, user);
    }

    delete(user: User) {
        return this.http.delete<any>(`${environment.apiUrl}/users/${user.PersonID}`);
    }

    changePW(user: User, newPw: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/changePassword/` + user.PersonID, {
            'password': newPw
        });
    }

    //Admin User Berechtigungen

    getRoles() {
        return this.http.get<any>(`${environment.apiUrl}/roles`);
    }

    deleteRoles() {

    }

    getRolesToProfile() {

    }

    deleteRolesToProfile() {

    }

    getProfiles() {
        return this.http.get<any>(`${environment.apiUrl}/profiles`);
    }

    getProfileDetailData(ProfileID: number) {
        return this.http.get<any>(`${environment.apiUrl}/profileDetailData/` + ProfileID);
    }

    deleteProfiles() {

    }

    getProfilestoEmployees() {

    }

    deleteProfiletoEmployee(PtEID) {
        return this.http.delete<any>(`${environment.apiUrl}/auth_user/${PtEID}`);
    }

    deleteProfilesFromUserV2(person_id: number, profiles: Array<any>) {

      return  this.http.put<any>(`${environment.apiUrl}/auth_user/profilesFromUser/${person_id}`, {
            'profiles': profiles,
        })
    }

    getgPiData(){

        return this.http.get<any>(`${environment.apiUrl}/gpIData`);

    }

    //OFFBOARDING
    deleteAllProfileFromEmployee(PersonID){
        return this.http.delete<any>(`${environment.apiUrl}/auth_user/${PersonID}`);
    }

    removeTeamleaderFromEmployee(PersonID){
        return this.http.put<any>(`${environment.apiUrl}/remove/teamleader/${PersonID}`,{});
    }


      //ONBOARDING
    onbaordingTask1(PersonID, employee){
        return this.http.post<any>(`${environment.apiUrl}/msGraph/onboardingTask1/${PersonID}`, {...employee});
    }
    onbaordingTask2(PersonID){
        return this.http.get<any>(`${environment.apiUrl}/msGraph/onboardingTask2/${PersonID}`);
    }
    onbaordingTask3(PersonID){
        return this.http.get<any>(`${environment.apiUrl}/msGraph/onboardingTask3/${PersonID}`);
    }
    
    onbaordingTask4(PersonID){
        return this.http.get<any>(`${environment.apiUrl}/msGraph/onboardingTask4/${PersonID}`);
    }
    onbaordingTask5(PersonID){
        return this.http.get<any>(`${environment.apiUrl}/msGraph/onboardingTask5/${PersonID}`);
    }
    onbaordingTask6(PersonID){
        return this.http.get<any>(`${environment.apiUrl}/msGraph/onboardingTask6/${PersonID}`);
    }


    //Gamification
    checkIfCurrentUserHasAvatar(){
        return this.http.get<any>(`${environment.apiUrl}/user/checkIfAvatarExists`);
    }
}

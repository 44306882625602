export class Contract {
  ContactMobile: string;
  ContactEMail: string;
  TravelExpensesComment: any;
  //AccountingContact: any;
  SubAbbreviationURL(ContractMainID: number, MainAbbreviationURL: any, SubAbbreviationURL: any) {
    throw new Error('Method not implemented.');
  }

  PreassignmentID: number;

  //Main
  ContractMainID: number;
  ContractNumber: string;
  ContractType: string;
  ContractTitle: string;
  CostCenter: string;
  ContractStart: Date;
  ContractEnd: Date;
  ContractOwner: number;
  ContractOwnerName: string;
  ContractSubCategoryID: number;
  created_at: Date
  created_by: number;
  updated_at: Date;
  updated_by: number;
  Status: string;
  PaymentDeadline: Date;
  InvoiceMethod: string;
  InvoiceDeadline: string;

  //Resource
  ContractResourceID: number;
  Helper: number;
  TravelExpenses: string;
  IncidentalExpenses: string;
  Client: number;
  Contractor: number;
  ContactContractor: number;
  Comment: string;
  PspElementID: number;
  PSPName: string;
  PreviousPspElementID: number;
  PreviousPspName: string;

  //Client
  ContractClientID: number;
  accounting_contact: number;
  accounting_contact_forename: string;
  accounting_contact_surname: string;
  accounting_contact_email: string;
  accounting_contact_phone: string;
  accounting_contact_fax: string;
  accounting_contact_mobile: string;
  BpInvoiceInfo:string;
  InvoiceTo: string;

  //Offer
  ContractOfferID: number;
  EngagementPartner: number;
  Creator: number;
  CreatorName: string;
  BusinesspartnerName: string;
  ClientName: string;
  ContactSurName: string;
  ContactForename: string;
  HelperSurname: string;
  HelperForename: string;
  ContactID: number;
  EpID: any;
  EpDisplayName: any;
  AuthorizationID: number;
  MainAbbreviationURL: any;
  SolutionManagerID: number;
  PositionID: number;

  LeadID: number;
  Volume: number;


  
}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Employee } from 'src/app/shared/model/employee';
import { firstValueFrom } from 'rxjs';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Address } from 'src/app/shared/model/xrm/address';
import { name } from 'msal/lib-commonjs/packageMetadata';

@Component({
  selector: 'app-businesspartner-crud',
  templateUrl: './businesspartner-crud.component.html',
  styleUrls: ['./businesspartner-crud.component.css'],
})
export class BusinesspartnerCrudComponent implements OnInit {
  //Checking if we need new data;
  businesspartner: BusinesspartnerV2;

  //Helper
  primaryroles: { name: string }[] = [{ name: 'Kunde' }, { name: 'Lieferant / Partner' }, { name: 'Regulierer' }];
  secondaryroles: { name: string }[] = [{ name: 'Kunde' }, { name: 'Lieferant / Partner' }];
  suggestions_businesspartner: BusinesspartnerV2[];
  all_businesspartners: BusinesspartnerV2[];
  employees: Employee[];
  businesspartner_name: string;
  save_loading = false;

  locations: Address[] = [];

  location_types: { name: string }[] = [{ name: 'Rechnungsadresse' }, { name: 'Lieferadresse' }];

  parent_company: BusinesspartnerV2;

  sectors: { name: string }[] = [
    { name: 'Fertigungsindustrie' },
    { name: 'Finanzindustrie' },
    { name: 'Immobilienwirtschaft' },
    { name: 'Konsumgüterindustrie + Handel' },
    { name: 'Öffentlicher Sektor' },
    { name: 'Weitere Branchen' },
  ];

  bp_arts: { name: string }[] = [{ name: 'Direktkunde' }, { name: 'Partnergeschäft' }, { name: 'Plattformkunde' }];
  newLocationDialog: boolean;
  actual_location: Address = new Address();

  constructor(
    private xrmServiceV2: XRMServiceV2,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private messageService: MessageService,
    private location: Location,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.loadStoredData();
  }

  loadStoredData() {
    let storedBusinessPartner = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner'));

    firstValueFrom(this.xrmServiceV2.getDataForBusinesspartnerStep())
      .then((response) => {
        this.all_businesspartners = response.all_businesspartners_raw;
        this.employees = response.employees;
        this.cdr.detectChanges();

        if (storedBusinessPartner && storedBusinessPartner.ParentCompany != null)
          this.parent_company = this.all_businesspartners.find((element) => element.BusinesspartnerID == storedBusinessPartner.ParentCompany);

      })
      .catch((error) => {
        // Handle error
      });

    if (storedBusinessPartner) {
      storedBusinessPartner.ms_profile_picture = null;
      this.businesspartner = storedBusinessPartner;
      this.businesspartner_name = this.businesspartner.BusinesspartnerName;

      this.xrmServiceV2.getLocationsByBusinesspartnerID(this.businesspartner.BusinesspartnerID).subscribe((response) => {
        if (response.result == 'success') {
          this.locations = response.locations;
        }
        if (response.result == 'error')
          this.messageService.add({
            severity: 'info',
            summary: 'Information',
            detail: response.message,
          });
      });

    } else {
      this.businesspartner = new BusinesspartnerV2();
      this.businesspartner.EngagementPartner = this.employees[0].PersonID;
      this.parent_company = new BusinesspartnerV2();
    }
  }

  // In the component code
  searchForBusinesspartner(search_text: string) {

    const lowerCaseSearchText = search_text.toLowerCase();
    this.suggestions_businesspartner = this.all_businesspartners.filter((element) =>
      element.BusinesspartnerName.toLowerCase().includes(lowerCaseSearchText)
    );

    // console.log(search_text);
    // this.suggestions_businesspartner = this.all_businesspartners.filter((element) =>
    //   element.BusinesspartnerName.includes(search_text)
    // );
  }

  selectBusinesspartner(data) {

    this.messageService.add({
      severity: 'info',
      summary: 'Information',
      detail: 'BP zur Änderung ausgewählt',
    });

    this.businesspartner = data;
    data
      ? sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(data))
      : sessionStorage.removeItem('otc_actual_businesspartner');
  }

  // saveAndSelectBusinesspartner(contact) {

  //   if (!this.locations) {

  //     this.confirmationService.confirm({
  //       message: 'Der Businesspartner hat noch keine Standorte. Ohne einen Standort kann kein Projekt angelegt werden. Möchtest du einen Standort hinzufügen?',
  //       header: 'Confirm',
  //       acceptLabel: 'Ja',
  //       rejectLabel: 'Nein',
  //       icon: 'pi pi-exclamation-triangle',
  //       accept: () => {
  //         this.newLocation();


  //         this.confirmationService.close();
  //       },
  //       reject: () => {

  //         this.save_loading = true;

  //     console.log(this.businesspartner);

  //     if (this.businesspartner.BusinesspartnerID != null) {

  //       let storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

  //       this.xrmServiceV2.editBusinesspartner(this.businesspartner).subscribe((response) => {
  //         if (response.result == 'success') {
  //           this.messageService.add({
  //             severity: 'success',
  //             summary: 'Information',
  //             detail: response.message,
  //           });

  //           this.businesspartner
  //             ? sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.businesspartner))
  //             : sessionStorage.removeItem('otc_actual_businesspartner');

  //           this.saveLocation(this.businesspartner.BusinesspartnerID);

  //           console.log(contact);

  //           if (contact) {

  //             if (storedLead) {

  //               this.router.navigate(['/xrm/edit-lead2order'], {
  //                 queryParams: { stringValue: 'contact', booleanValue: false, edit: storedLead.LeadID }
  //               });

  //             }

  //             else {

  //               this.router.navigate(['/xrm/new-lead2order'], {
  //                 queryParams: { stringValue: 'contact', booleanValue: false, edit: false }
  //               });

  //             }
  //           }
  //           else {
  //             this.location.back()
  //           }

  //           this.save_loading = false;

  //           //this.appXRMDashboard.openEditDialog('contact', false);

  //         }
  //         if (response.result == 'error') {
  //           this.messageService.add({
  //             severity: 'info',
  //             summary: 'Information',
  //             detail: response.message,
  //           });
  //           this.save_loading = false;
  //         }

  //       });
  //     } else {
  //       this.businesspartner.BusinesspartnerName = this.businesspartner_name;
  //       this.xrmServiceV2.createBusinesspartner(this.businesspartner).subscribe((response) => {
  //         if (response.result == 'success') {
  //           this.messageService.add({
  //             severity: 'success',
  //             summary: 'Information',
  //             detail: response.message,
  //           });
  //           this.businesspartner = response.newlyCreatedObject;
  //           this.businesspartner
  //             ? sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.businesspartner))
  //             : sessionStorage.removeItem('otc_actual_businesspartner');

  //           this.saveLocation(this.businesspartner.BusinesspartnerID);
  //           this.save_loading = false;

  //           console.log(contact);
  //           if (contact) {

  //             this.router.navigate(['/xrm/new-lead2order'], {
  //               queryParams: { stringValue: 'contact', booleanValue: false, edit: false }
  //             });

  //           }
  //           else {
  //             this.location.back()
  //           }

  //         }
  //         if (response.result == 'error') {
  //           this.messageService.add({
  //             severity: 'info',
  //             summary: 'Information',
  //             detail: response.message,
  //           });
  //           this.save_loading = false;
  //         }

  //       });
  //     }

  //         this.confirmationService.close();
  //       }
  //     });

  //   } else {

  //     this.save_loading = true;

  //     console.log(this.businesspartner);

  //     if (this.businesspartner.BusinesspartnerID != null) {

  //       let storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

  //       this.xrmServiceV2.editBusinesspartner(this.businesspartner).subscribe((response) => {
  //         if (response.result == 'success') {
  //           this.messageService.add({
  //             severity: 'success',
  //             summary: 'Information',
  //             detail: response.message,
  //           });

  //           this.businesspartner
  //             ? sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.businesspartner))
  //             : sessionStorage.removeItem('otc_actual_businesspartner');

  //           this.saveLocation(this.businesspartner.BusinesspartnerID);

  //           console.log(contact);

  //           if (contact) {

  //             if (storedLead) {

  //               this.router.navigate(['/xrm/edit-lead2order'], {
  //                 queryParams: { stringValue: 'contact', booleanValue: false, edit: storedLead.LeadID }
  //               });

  //             }

  //             else {

  //               this.router.navigate(['/xrm/new-lead2order'], {
  //                 queryParams: { stringValue: 'contact', booleanValue: false, edit: false }
  //               });

  //             }
  //           }
  //           else {
  //             this.location.back()
  //           }

  //           this.save_loading = false;

  //           //this.appXRMDashboard.openEditDialog('contact', false);

  //         }
  //         if (response.result == 'error') {
  //           this.messageService.add({
  //             severity: 'info',
  //             summary: 'Information',
  //             detail: response.message,
  //           });
  //           this.save_loading = false;
  //         }

  //       });
  //     } else {
  //       this.businesspartner.BusinesspartnerName = this.businesspartner_name;
  //       this.xrmServiceV2.createBusinesspartner(this.businesspartner).subscribe((response) => {
  //         if (response.result == 'success') {
  //           this.messageService.add({
  //             severity: 'success',
  //             summary: 'Information',
  //             detail: response.message,
  //           });
  //           this.businesspartner = response.newlyCreatedObject;
  //           this.businesspartner
  //             ? sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.businesspartner))
  //             : sessionStorage.removeItem('otc_actual_businesspartner');

  //           this.saveLocation(this.businesspartner.BusinesspartnerID);
  //           this.save_loading = false;

  //           console.log(contact);
  //           if (contact) {

  //             this.router.navigate(['/xrm/new-lead2order'], {
  //               queryParams: { stringValue: 'contact', booleanValue: false, edit: false }
  //             });

  //           }
  //           else {
  //             this.location.back()
  //           }

  //         }
  //         if (response.result == 'error') {
  //           this.messageService.add({
  //             severity: 'info',
  //             summary: 'Information',
  //             detail: response.message,
  //           });
  //           this.save_loading = false;
  //         }

  //       });
  //     }


  //   }


  // }

  async saveAndSelectBusinesspartner(contact) {

    this.businesspartner.BusinesspartnerName = this.businesspartner_name;

    if (this.parent_company)
      this.businesspartner.ParentCompany = this.parent_company.BusinesspartnerID;

    console.log(this.locations);
    if (this.locations.length === 0) {
      const userConfirmed = await this.confirmLocation();
      if (!userConfirmed) {
        this.save_loading = true;
        const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

        if (this.businesspartner.BusinesspartnerID != null) {
          await this.editBusinesspartner();
        } else {
          await this.createBusinesspartner();
        }

        this.handleNavigation(contact, storedLead);
        this.save_loading = false;
      }
    } else {
      this.save_loading = true;
      const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

      if (this.businesspartner.BusinesspartnerID != null) {
        await this.editBusinesspartner();
      } else {
        await this.createBusinesspartner();
      }

      this.handleNavigation(contact, storedLead);
      this.save_loading = false;
    }
  }

  async confirmLocation() {
    return new Promise((resolve) => {
      this.confirmationService.confirm({
        message: 'Der Businesspartner hat noch keine Standorte. Ohne einen Standort kann kein Projekt angelegt werden. Möchtest du einen Standort hinzufügen?',
        header: 'Confirm',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.newLocation();
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }

  async editBusinesspartner() {
    try {
      const response = await this.xrmServiceV2.editBusinesspartner(this.businesspartner).toPromise();
      if (response.result === 'success') {
        this.handleSuccessResponse(response);
      } else {
        this.handleErrorResponse(response);
      }
    } catch (error) {
      // Handle errors here
    }
  }

  async createBusinesspartner() {
    try {
      const response = await this.xrmServiceV2.createBusinesspartner(this.businesspartner).toPromise();
      if (response.result === 'success') {
        this.handleSuccessResponse(response);
      } else {
        this.handleErrorResponse(response);
      }
    } catch (error) {
      // Handle errors here
    }
  }


  //new BusinesspartnerID fehlt
  handleSuccessResponse(response) {
    this.messageService.add({
      severity: 'success',
      summary: 'Information',
      detail: response.message,
    });

    this.businesspartner.BusinesspartnerID = response.newlyCreatedObject.BusinesspartnerID;

    if (this.businesspartner)
      sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.businesspartner));

    this.saveLocation(response.newlyCreatedObject.BusinesspartnerID);
  }

  handleErrorResponse(response) {
    this.messageService.add({
      severity: 'info',
      summary: 'Information',
      detail: response.message,
    });
  }

  handleNavigation(contact, storedLead) {
    if (contact) {
      const queryParams: { stringValue: string; booleanValue: boolean; edit?: number } = { stringValue: 'contact', booleanValue: false };

      sessionStorage.removeItem('otc_actual_businesspartner_contact');

      if (storedLead) {
        queryParams.edit = storedLead.LeadID;
        this.router.navigate(['/xrm/edit-lead2order'], { queryParams });
      } else {
        this.router.navigate(['/xrm/new-lead2order'], { queryParams });
      }
    } else {
      this.location.back();
    }
  }


  newLocation() {

    this.newLocationDialog = true;

  }

  editLocation(location) {

    this.actual_location = { ...location };
    this.newLocationDialog = true;

  }

  saveTempLocation() {

    this.locations.push(this.actual_location);
    this.messageService.add({
      severity: 'success',
      summary: 'Information',
      detail: 'Adresse hinzugefügt, bitte Businesspartner speichern.',
    });
    this.newLocationDialog = false;

  }

  saveLocation(BusinesspartnerID) {

    if (this.locations) {

      for (let location of this.locations) {

        if (location.LocationID == null) {

          location.BusinesspartnerID = BusinesspartnerID;

          this.xrmServiceV2.createLocation(location).subscribe((response) => {
            if (response.result == 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Information',
                detail: response.message,
              });
            }
            if (response.result == 'error')
              this.messageService.add({
                severity: 'info',
                summary: 'Information',
                detail: response.message,
              });
          });

        } else {

          location.BusinesspartnerID = BusinesspartnerID;

          this.xrmServiceV2.editLocation(location).subscribe((response) => {
            if (response.result == 'success') {
              this.messageService.add({
                severity: 'success',
                summary: 'Information',
                detail: response.message,
              });

            }
            if (response.result == 'error')
              this.messageService.add({
                severity: 'info',
                summary: 'Information',
                detail: response.message,
              });
          });

        }
      }

    }


  }

  abortChanges() {
    //sessionStorage.clear();

    sessionStorage.removeItem('otc_actual_businesspartner_contact');
    sessionStorage.removeItem('otc_actual_businesspartner');
    sessionStorage.removeItem('otc_actual_lead');
    sessionStorage.removeItem('otc_actual_project');
    sessionStorage.removeItem('otc_actual_position');
    sessionStorage.removeItem('otc_actual_assignment');
    sessionStorage.removeItem('otc_actual_contract_client');
    sessionStorage.removeItem('otc_actual_contract_offer');
    sessionStorage.removeItem('otc_actual_contract_resource');
    sessionStorage.removeItem('otc_actual_preassignment');

    console.log(sessionStorage);

    this.location.back();
  }
}

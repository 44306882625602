export class ProjectV2 {
  ProjectID: number;
  LeadID: number;
  ProjectName: string;
  ProjectStartDate: Date;
  ProjectEndDate: Date;
  Duration: string;
  ProjectLocation: string;
  ProjectStatus: string;
  EpID: number;
  EpDisplayName: string;
  EpMobileGrandega: string;
  EpEMail: string;
  BusinesspartnerID: number;
  BusinesspartnerName: string;
  VolumeCurrency: string;
  VolumePt: string;
  ContactID: number;
  ContactForename: string;
  ContactSurName: string;
  ContactDisplayName;
  ProjectDescription: string;
  Probability: string;

  // ShowIC: string;
  // ImprovementCoach: number;
  // ImprovementCoachName: any;

  //For SolutionManager
  ImplementationDescription: string;
}


import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContentLayoutComponent } from '../layout/content-layout/content-layout.component';
import { AppComponent } from 'src/app/app.component';
import { MenuItem } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService, UserService } from '../../services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  items: MenuItem[];

  profileImg: SafeResourceUrl;
  currentUser;
  currentURL: string;
  constructor(public app: AppComponent, public userService: UserService, public appMain: ContentLayoutComponent, private http: HttpClient, private sanitizer: DomSanitizer, private authenticationService: AuthenticationService,) {
    this.currentUser = this.authenticationService.currentUserValue;
    // console.log(JSON.stringify(this.currentUser))
  }


  ngOnInit() {
    sessionStorage.removeItem('returnUrl');

    // Get the complete URL of the current page
    this.currentURL = window.location.href;
    console.log(this.currentURL);

    if (this.currentURL != "https://dev.grandega-access.de/v2/#/performance-evaluation/detail") {
      this.getProfileImg();
    }
  }

  logout() {
    this.authenticationService.logout();
  }

  onNavigate() {
    window.open("https://www.dev.grandega-access.de");
  }

  getProfileImg() {
    this.userService.getMyProfilePicture().subscribe((response: any) => {
      this.profileImg = response.photo;
    });
    
    
    
    // .subscribe((data) =>{ 
    //  try{
    //   const TYPED_ARRAY = new Uint8Array(data);
    //   // converts the typed array to string of characters
    //   const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);

    //   //converts string of characters to base64String
    //   let base64String = btoa(STRING_CHAR);

    //   //sanitize the url that is passed as a value to image src attrtibute
    //   this.profileImg = this.sanitizer.bypassSecurityTrustUrl(
    //     'data:image/*;base64, ' + base64String
    //   );
    //  } catch(error){
    //   this.profileImg = '../assets/images/1.jpg';
    //  }
    // })
    
    // this.http
    //   .get('https://graph.microsoft.com/v1.0/me/photos/120x120/$value', {
    //     headers: { 'Content-Type': 'image/*' },
    //     responseType: 'arraybuffer',
    //   })
    //   .toPromise()
    //   .then(
    //     (data) => {
    //       const TYPED_ARRAY = new Uint8Array(data);
    //       // converts the typed array to string of characters
    //       const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);

    //       //converts string of characters to base64String
    //       let base64String = btoa(STRING_CHAR);

    //       //sanitize the url that is passed as a value to image src attrtibute
    //       this.profileImg = this.sanitizer.bypassSecurityTrustUrl(
    //         'data:image/*;base64, ' + base64String
    //       );
    //     },
    //     (err) => {
    //       this.profileImg = '../assets/images/1.jpg';
    //     }
    //   );
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
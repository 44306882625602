import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavService } from 'src/app/shared/services/nav.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Employee } from 'src/app/shared/model/employee';
import { PSPElemente, User } from 'src/app/shared/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { Contract } from 'src/app/shared/model/businessdivision/contract';
import { BusinessDivisionService } from 'src/app/shared/services/businessdivision.service';
import { Businesspartner } from 'src/app/shared/model/xrm/businesspartner';
import moment from 'moment';
import business from 'moment-business-days';
import { Contact } from 'src/app/shared/model/xrm/contact';
import { UploadService } from 'src/app/shared/services/upload.service';
import { Observable, firstValueFrom, forkJoin, lastValueFrom, map, tap } from 'rxjs';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { ContactV2 } from 'src/app/shared/model/xrm/V2/contactV2';
import { LeadCrudComponent } from '../lead-crud/lead-crud.component';
import { ProcessStepperPositionComponent } from '../process-stepper-position/process-stepper-position.component';
import { ReportProjectDetailComponent } from 'src/app/components/xrm/reports/report-project/report-project-detail/report-project-detail.component';
import { ReportMissingdataComponent } from 'src/app/components/xrm/reports/report-missingdata/report-missingdata.component';


@Component({
  selector: 'app-contract-offer-crud',
  templateUrl: './contract-offer-crud.component.html',
  styleUrls: ['./contract-offer-crud.component.css']
})
export class ContractOfferCrudComponent implements OnInit, OnDestroy {

  items: MenuItem[];
  contract: Contract;
  contractDialogNew;
  contractNumberGenerationDialog;

  contracts;
  contractNumberIsCreated = false;
  new = true;
  submittedError = false;
  employeePlaceholder = "Keiner ausgewählt";
  public searchEmployeeResult: boolean = false;
  public searchEmployeeResultEmpty: boolean = false;
  public employeeText: string;
  employees;
  empIsIn = true;
  costCenters: Array<{ Name: string }> = [{ Name: "Corporate Services" }, { Name: "Spotlight Management + Training" },
  { Name: "Customer Relationship Management" }, { Name: "Management (MP)" }, { Name: "Beraterpool" },
  { Name: "Externe Mitarbeiter" }, { Name: "Vertrieb" }, { Name: "Backoffice" }]
  tabIndex = 0;
  currentUser: User;
  //Placeholder
  ContractEnd: Date;
  ContractStart: Date;
  ContractOwner: User;
  Creator: User;
  CostCenter;
  PreviousPspElement: PSPElemente;
  ActualPspElement: PSPElemente;
  SelectedBusinesspartner: BusinesspartnerV2;
  SelectedContact: ContactV2;
  SelectedClient: BusinesspartnerV2;
  EngagementPartner;
  Helper: ContactV2;
  eps
  pspElemente
  pspElementeBefore
  businesspartners
  contacts
  categorys;
  tempItems; //Array<{ Name: string }> = [{ Name: "In Benutzung" }, { Name: "Rückgabe" }, { Name: "Bestand" }]
  tempItem: MenuItem;
  mainCategorys;
  categoryItems;
  possiblePSPElements;
  public searchItems;
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text = "";
  contractTemp
  RoleIDs;
  diff;
  popUpPSP = false;
  allPSPs;
  documents;
  selectedDocuments;
  loading2 = false;
  hrefPrefix: string = `${environment.apiUrl}`;
  tempEvent;
  invoiceMethods: Array<{ Name: string }> = [{ Name: "Gutschriftsverfahren via Buchhaltung" }, { Name: "Gutschriftsverfahren mit LN via Berater" },
  { Name: "Gutschriftsverfahren ohne LN via Berater" }, { Name: "Rechnung via E-Mail" }, { Name: "Rechnung via Postversand" }]
  InvoiceMethod;
  // PaymentDeadline: Date;
  helperContacts;
  businessPartnerContacts;
  newDocDialog = false;
  externEmployees

  //vorhandenes Angebot
  state_offer_value = true;
  choose_offer_active = false;
  selected_offer;
  stateOfferOptions: any[] = [{ label: 'Ja', value: true }, { label: 'Nein', value: false }];

  //testing
  navitems;


  //new
  all_contacts;
  storedBusinessPartner;
  data_loading = true;
  offers;
  save_loading = false;

  no_stored_contract = false;

  constructor(public navServices: NavService,
    private http: HttpClient,
    private router: Router,
    private businessDivisionService: BusinessDivisionService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private cdref: ChangeDetectorRef,
    private uploadService: UploadService,
    private confirmationService: ConfirmationService,
    private xrmServiceV2: XRMServiceV2,
    private appLeadCrudComponent: LeadCrudComponent,
    private appProcessStepperPositionComponent: ProcessStepperPositionComponent,
    private appReportProjectDetailComponent: ReportProjectDetailComponent,
    private appReportMissingdataComponent: ReportMissingdataComponent

  ) {

    let storedContract = JSON.parse(sessionStorage.getItem('otc_actual_contract_offer'));

    if (storedContract) {
      this.state_offer_value = false;
    }

    //Init
    this.loadData();
    this.contract = new Contract();
    this.currentUser = this.authenticationService.currentUserValue;

  }

  ngOnDestroy(): void {

    this.state_offer_value = true;


  }

  ngOnInit() {

    this.openNew('Angebot', 3);

    console.log(this.appProcessStepperPositionComponent.state);

    switch (this.appProcessStepperPositionComponent.state) {
      case 'lead':
        this.appLeadCrudComponent.activePositionStepIndex = 0;

        break;
      case 'extension':
        this.appReportProjectDetailComponent.activePositionStepIndex = 0;

        break;

      case 'edit':
        this.appReportMissingdataComponent.activePositionStepIndex = 0;
        this.appReportProjectDetailComponent.activePositionStepIndex = 0;

        break;
    }

    // if (this.appProcessStepperPositionComponent.extension == true) {

    //   this.appReportProjectDetailComponent.activePositionStepIndex = 0;

    // }
    // else {

    //   this.appLeadCrudComponent.activePositionStepIndex = 0;

    // }

  }

  selectHelperContacts() {
    if (this.SelectedClient.BusinesspartnerID == 2) {
      this.contacts = this.employees;
    } else {
      this.contacts = this.all_contacts.filter(element => element.BusinesspartnerID = this.SelectedClient.BusinesspartnerID).sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0));
    }
  }

  show_offer_list() {

    this.state_offer_value = true;

  }

  new_offer() {

    this.state_offer_value = false;
    this.contract = new Contract();

  }

  select_offer(edit) {

    if (edit) {

      this.contract = this.selected_offer;

    }
    else {

      this.contract = this.selected_offer;
      this.save_contract_offer();

    }

    this.state_offer_value = false;

  }

  no_offer() {

    switch (this.appProcessStepperPositionComponent.state) {
      case 'lead':
        this.appLeadCrudComponent.activePositionStepIndex = 1;

        if (this.appLeadCrudComponent.maxPositionStepIndex <= 0)
          this.appLeadCrudComponent.maxPositionStepIndex++;

        break;
      case 'extension':
        this.appReportProjectDetailComponent.activePositionStepIndex = 1;

        if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
          this.appReportProjectDetailComponent.maxPositionStepIndex++;

        break;

      case 'edit':
        this.appReportMissingdataComponent.activePositionStepIndex = 1;

        if (this.appReportMissingdataComponent.maxPositionStepIndex <= 0)
          this.appReportMissingdataComponent.maxPositionStepIndex++;


        this.appReportProjectDetailComponent.activePositionStepIndex = 1;

        if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
          this.appReportProjectDetailComponent.maxPositionStepIndex++;

        break;
    }

    // if (this.appProcessStepperPositionComponent.extension == true) {

    //   this.appReportProjectDetailComponent.activePositionStepIndex = 1;

    //   if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
    //     this.appReportProjectDetailComponent.maxPositionStepIndex++;

    // }
    // else {

    //   if (this.appProcessStepperPositionComponent.edit == true) {
    //     this.appReportMissingdataComponent.activePositionStepIndex = 1;

    //     if (this.appReportMissingdataComponent.maxPositionStepIndex <= 0)
    //       this.appReportMissingdataComponent.maxPositionStepIndex++;
    //   } else {

    //     this.appLeadCrudComponent.activePositionStepIndex = 1;

    //     if (this.appLeadCrudComponent.maxPositionStepIndex <= 0)
    //       this.appLeadCrudComponent.maxPositionStepIndex++;

    //   }

    // }

  }

  async loadData() {
    try {
      // Parse sessionStorage items once
      const storedItems = {
        projectDraft: JSON.parse(sessionStorage.getItem('otc_actual_project')),
        businessPartner: JSON.parse(sessionStorage.getItem('otc_actual_businesspartner')),
        businessPartnerContact: JSON.parse(sessionStorage.getItem('otc_actual_businesspartner_contact')),
        preAssignment: JSON.parse(sessionStorage.getItem('otc_actual_preassignment')),
        contract: JSON.parse(sessionStorage.getItem('otc_actual_contract_offer')),
        lead: JSON.parse(sessionStorage.getItem('otc_actual_lead')),
        roleIDs: new Set(JSON.parse(sessionStorage.getItem('roleID')))
      };

      const apiCalls: { [key: string]: Observable<any> } = {
        employees: this.xrmServiceV2.getEmployees(),
        contacts: this.xrmServiceV2.getContacts(),
        bps: this.xrmServiceV2.getBusinessPartners(),
        eps: this.xrmServiceV2.getEPS(),
        psp: this.xrmServiceV2.getPSPElements(),
        contracts: this.xrmServiceV2.getContracts(),
        offers: this.xrmServiceV2.getDataForContractOfferStep(storedItems.projectDraft.LeadID)
      };

      // If there's a stored lead, add preassignments and presales to the API calls
      if (storedItems.contract) {
        apiCalls['documents'] = this.xrmServiceV2.getDocsForContractStep(storedItems.contract.ContractMainID);
      }

      // Make all API calls in parallel
      forkJoin(apiCalls).subscribe({
        next: async (results: any) => {

          this.employees = results.employees;
          this.all_contacts = results.contacts;
          this.businesspartners = results.bps;

          if (storedItems.contract) {
            this.documents = results.documents;
          }

          this.eps = results.eps;
          this.pspElemente = results.psp;
          this.pspElementeBefore = results.psp;
          this.allPSPs = results.psp;
          this.contracts = results.contracts;

          // Assign results
          this.offers = results.offers;
          this.state_offer_value = this.offers.length === 0;

          // Initialize properties
          this.initializeProperties();

          // Set data from stored items
          this.setDataFromStoredItems(storedItems);

          // Load additional data if contract exists
          if (storedItems.contract) {
            await this.loadExistingContractData(storedItems.contract);
          } else {
            this.handleNoStoredContract(storedItems.lead);
          }

          this.data_loading = false;
        },
        error: (error) => {
          console.error('Error fetching data:', error);
          this.data_loading = false;
          // Handle error (e.g., show error message)
        }
      });

    } catch (error) {
      console.error('Error in loadData:', error);
    }
  }

  private initializeProperties() {
    this.tabIndex = 0;
    this.ContractOwner = new User();
    this.CostCenter = {};
    this.Creator = new User();
    this.PreviousPspElement = new PSPElemente();
    this.ActualPspElement = new PSPElemente();
    this.SelectedBusinesspartner = new BusinesspartnerV2();
    this.SelectedContact = new ContactV2();
    this.SelectedClient = new BusinesspartnerV2();
    this.EngagementPartner = new Employee();
    this.Helper = new ContactV2();
    this.contract = new Contract();
    this.documents = [];
    this.InvoiceMethod = { Name: "Übermittlungsmethode auswählen" };
  }

  private setDataFromStoredItems(storedItems) {
    this.SelectedClient = this.businesspartners.find(element => element.BusinesspartnerID == storedItems.businessPartnerContact.BusinesspartnerID);
    this.generateContractNumber();

    this.contacts = this.all_contacts
      .filter(element => element.BusinesspartnerID === this.SelectedClient.BusinesspartnerID)
      .sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));

    this.Helper = this.contacts.find(element => element.ContactID == storedItems.businessPartnerContact.ContactID);
    this.SelectedBusinesspartner = this.businesspartners.find(bp => bp.BusinesspartnerID == "1339");
  }

  private async loadExistingContractData(storedContract) {
    this.state_offer_value = false;
    this.contract = storedContract;
    this.ContractStart = new Date(this.contract.ContractStart);
    this.ContractEnd = new Date(this.contract.ContractEnd);
    this.ContractOwner = this.employees.find(element => element.PersonID == this.contract.ContractOwner);
    this.contractNumberIsCreated = this.contract.ContractNumber != null;
  }

  private handleNoStoredContract(storedLead) {
    this.no_stored_contract = true;
    this.contract = new Contract();
    this.ContractOwner = this.employees.find(element => element.PersonID == storedLead.LeadManagerID);
  }


  // async loadData() {
  //   try {

  //     const storedProjectDraft = JSON.parse(sessionStorage.getItem('otc_actual_project'));
  //     console.log(storedProjectDraft);

  //     const y = await firstValueFrom(this.xrmServiceV2.getDataForContractOfferStep(storedProjectDraft.LeadID));

  //     this.offers = y.offers;

  //     if (this.offers.length == 0)
  //       this.state_offer_value = false;

  //     const x = await lastValueFrom(this.xrmServiceV2.getDataForContractsStep());

  //     this.documents = x.documents;
  //     this.employees = x.employees;
  //     this.eps = x.eps;
  //     this.pspElemente = x.psp;
  //     this.pspElementeBefore = x.psp;
  //     this.allPSPs = x.psp;
  //     this.businesspartners = x.bp;
  //     this.externEmployees = x.externEmployees;
  //     this.all_contacts = x.contacts;
  //     this.contracts = x.contracts;

  //     this.tabIndex = 0;
  //     this.ContractOwner = new User();
  //     this.CostCenter = {};
  //     this.Creator = new User();
  //     this.PreviousPspElement = new PSPElemente();
  //     this.ActualPspElement = new PSPElemente();
  //     this.SelectedBusinesspartner = new BusinesspartnerV2();
  //     this.SelectedContact = new ContactV2();
  //     this.SelectedClient = new BusinesspartnerV2();
  //     this.EngagementPartner = new Employee();
  //     this.Helper = new ContactV2();
  //     this.contract = new Contract();
  //     this.documents = [];

  //     this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
  //     this.InvoiceMethod = { Name: "Übermittlungsmethode auswählen" };

  //     // this.contracts = this.businessDivisionService.getMainContracts().pipe(
  //     //   map(contracts => contracts.filter(contract => this.RoleIDs.some(el => el === contract.AuthorizationID)))
  //     // );

  //     // console.log(this.contracts);

  //     this.storedBusinessPartner = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner'));
  //     const storedBusinessPartnerContact = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner_contact'));

  //     this.SelectedClient = this.businesspartners.find(element => element.BusinesspartnerID == storedBusinessPartnerContact.BusinesspartnerID);
  //     this.generateContractNumber();

  //     this.contacts = this.all_contacts.filter(element => element.BusinesspartnerID = this.SelectedClient.BusinesspartnerID).sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0));

  //     this.Helper = this.contacts.find(element => element.ContactID == storedBusinessPartnerContact.ContactID);

  //     this.SelectedBusinesspartner = this.businesspartners.find(bp => bp.BusinesspartnerID == "1339");
  //     //this.checkEmployee();
  //     this.loadStoredData();

  //     //this.data_loading = false;

  //   } catch (error) {
  //     console.error('Error in loadData:', error);
  //   }
  // }

  // async loadStoredData() {

  //   const storedPreAssignment = JSON.parse(sessionStorage.getItem('otc_actual_preassignment'));
  //   this.contract.PreassignmentID = storedPreAssignment.PreassignmentID;

  //   //Set Assignment
  //   let storedContract = JSON.parse(sessionStorage.getItem('otc_actual_contract_offer'));


  //   if (storedContract) {

  //     this.state_offer_value = false;
  //     this.contract = storedContract;

  //     this.ContractStart = new Date(this.contract.ContractStart);
  //     this.ContractEnd = new Date(this.contract.ContractEnd);

  //     const x = await firstValueFrom(this.xrmServiceV2.getDocsForContractStep(this.contract.ContractMainID));

  //     this.documents = x.documents;

  //     this.ContractOwner = this.employees.find(element => element.PersonID == this.contract.ContractOwner);

  //     if (this.contract.ContractNumber != null)
  //       this.contractNumberIsCreated = true;


  //   } else {

  //     this.no_stored_contract = true;

  //     this.contract = new Contract();

  //     const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

  //     this.ContractOwner = this.employees.find(element => element.PersonID == storedLead.LeadManagerID);

  //   }

  //   this.data_loading = false;
  // }

  save_contract_offer() {

    this.contract.ContractStart = new Date(this.ContractStart);
    this.contract.ContractEnd = new Date(this.ContractEnd);

    this.save_loading = true;

    //funktion von unten mit einbauen

    const storedProjectDraft = JSON.parse(sessionStorage.getItem('otc_actual_project'));

    this.contract.LeadID = storedProjectDraft.LeadID;

    this.contract
      ? sessionStorage.setItem('otc_actual_contract_offer', JSON.stringify(this.contract))
      : sessionStorage.removeItem('otc_actual_contract_offer');

    if (this.contract.ContractOfferID != null) {

      this.xrmServiceV2.editOfferContract(this.contract).subscribe((response) => {
        if (response.result == 'success') {

          this.myUploader();

          this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

          switch (this.appProcessStepperPositionComponent.state) {
            case 'lead':
              this.appLeadCrudComponent.activePositionStepIndex = 1;

              if (this.appLeadCrudComponent.maxPositionStepIndex <= 0)
                this.appLeadCrudComponent.maxPositionStepIndex++;

              break;
            case 'extension':
              this.appReportProjectDetailComponent.activePositionStepIndex = 1;

              if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
                this.appReportProjectDetailComponent.maxPositionStepIndex++;

              break;

            case 'edit':
              this.appReportMissingdataComponent.activePositionStepIndex = 1;

              if (this.appReportMissingdataComponent.maxPositionStepIndex <= 0)
                this.appReportMissingdataComponent.maxPositionStepIndex++;


              this.appReportProjectDetailComponent.activePositionStepIndex = 1;

              if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
                this.appReportProjectDetailComponent.maxPositionStepIndex++;

              break;
          }



          // if (this.appProcessStepperPositionComponent.extension == true) {

          //   this.appReportProjectDetailComponent.activePositionStepIndex = 0;

          //   if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
          //     this.appReportProjectDetailComponent.maxPositionStepIndex++;

          // }
          // else {

          //   this.appLeadCrudComponent.activePositionStepIndex = 1;

          //   if (this.appLeadCrudComponent.maxPositionStepIndex <= 0)
          //     this.appLeadCrudComponent.maxPositionStepIndex++;

          // }



        }
        if (response.result == 'error')
          this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

        this.save_loading = false;

      });
    }
    else {

      this.createContract();

    }

  }

  async createContract() {

    const tempStart = moment(this.ContractStart).format('YYYY-MM-DD');
    const tempEnd = moment(this.ContractEnd).format('YYYY-MM-DD');

    try {
      const contracts = await this.businessDivisionService.getMainContracts().toPromise();

      const todaysContractCount = contracts.filter(contract =>
        moment(contract.created_at).isSame(moment(), 'day')
      ).length + 1;

      const tempContractNumber = await this.generateContractNumber();

      const formattedContractNumber = `${tempContractNumber.substring(0, 13)}${todaysContractCount}${tempContractNumber.substring(tempContractNumber.indexOf(' /'))}`;

      if (!this.CostCenter) {
        this.CostCenter = {};
      }



      if (!this.Helper) {
        this.Helper = new ContactV2();
      }

      if (!this.ContractOwner) {
        this.ContractOwner = new User();
      }

      if (!this.SelectedClient) {
        this.SelectedClient = new BusinesspartnerV2();
      }

      if (!this.SelectedContact) {
        this.SelectedContact = new ContactV2();
      }

      if (!this.SelectedBusinesspartner) {
        this.SelectedBusinesspartner = new BusinesspartnerV2();
      }

      if (!this.PreviousPspElement) {
        this.PreviousPspElement = new PSPElemente();
      }

      const storedPreAssignment = JSON.parse(sessionStorage.getItem('otc_actual_preassignment'))

      this.contract.PreassignmentID = storedPreAssignment.PreassignmentID;
      this.contract.ContractNumber = formattedContractNumber;
      this.contract.ContractStart = new Date(tempStart);
      this.contract.ContractEnd = new Date(tempEnd);
      this.contract.ContractOwner = this.ContractOwner.PersonID;
      this.contract.Helper = this.Helper.PersonID;
      this.contract.Client = this.SelectedClient.BusinesspartnerID;

      this.contract.ContractSubCategoryID = 3;
      this.contract.ContractType = 'Angebote';

      const requestBody = {
        'PreassignmentID': storedPreAssignment.PreassignmentID,
        'ContractNumber': formattedContractNumber,
        'ContractSubCategoryID': this.contract.ContractSubCategoryID,
        'ContractType': this.contract.ContractType,
        'ContractTitle': this.contract.ContractTitle,
        //'CostCenter': this.CostCenter.Name,
        'ContractStart': tempStart,
        'ContractEnd': tempEnd,
        'ContractOwner': this.ContractOwner.PersonID,
        'Helper': this.Helper.PersonID,
        'Volume': this.contract.Volume,
        //'TravelExpenses': this.contract.TravelExpenses,
        //'IncidentalExpenses': this.contract.IncidentalExpenses,
        //'TravelExpensesComment': this.contract.TravelExpensesComment,
        'Client': this.SelectedClient.BusinesspartnerID,
        //'Contractor': this.SelectedBusinesspartner.BusinesspartnerID,
        'Comment': this.contract.Comment,
        'PreviousPspElementID': this.PreviousPspElement.PSPElementeID,
        //'BpInvoiceInfo': this.ActualPspElement.BpInvoiceInfo,
        //'InvoiceMethod': this.InvoiceMethod.Name,
        //'InvoiceTo': this.ActualPspElement.InvoiceTo,
        //'PaymentDeadline': this.contract.PaymentDeadline,
        //'PositionID': this.storedPosition.PositionID,
      };

      console.log('requestBody:', requestBody);

      const result = await this.http.post<any>(`${environment.apiUrl}/v2/create/contract-offer`, requestBody).toPromise();

      if (result.result === 'success') {
        this.contract.ContractMainID = result.ContractMainID;

        if (this.tempEvent != null) {
          this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Bitte warten, Dateien werden hochgeladen' });
          this.myUploader();
        } else {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vertrag wurde erfolgreich erstellt' });
          this.hideDialog();
        }


        switch (this.appProcessStepperPositionComponent.state) {
          case 'lead':
            this.appLeadCrudComponent.activePositionStepIndex = 1;

            if (this.appLeadCrudComponent.maxPositionStepIndex <= 0)
              this.appLeadCrudComponent.maxPositionStepIndex++;

            break;
          case 'extension':
            this.appReportProjectDetailComponent.activePositionStepIndex = 1;

            if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
              this.appReportProjectDetailComponent.maxPositionStepIndex++;

            break;

          case 'edit':
            this.appReportMissingdataComponent.activePositionStepIndex = 1;

            if (this.appReportMissingdataComponent.maxPositionStepIndex <= 0)
              this.appReportMissingdataComponent.maxPositionStepIndex++;


            this.appReportProjectDetailComponent.activePositionStepIndex = 1;

            if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
              this.appReportProjectDetailComponent.maxPositionStepIndex++;

            break;
        }

        // if (this.appProcessStepperPositionComponent.extension == true) {

        //   this.appReportProjectDetailComponent.activePositionStepIndex = 0;

        //   if (this.appReportProjectDetailComponent.maxPositionStepIndex <= 0)
        //     this.appReportProjectDetailComponent.maxPositionStepIndex++;

        // }
        // else {

        //   this.appLeadCrudComponent.activePositionStepIndex = 1;

        //   if (this.appLeadCrudComponent.maxPositionStepIndex <= 0)
        //     this.appLeadCrudComponent.maxPositionStepIndex++;

        // }

        this.save_loading = false;

        this.contract
          ? sessionStorage.setItem('otc_actual_contract_offer', JSON.stringify(this.contract))
          : sessionStorage.removeItem('otc_actual_contract_offer');

      } else {
        this.messageService.add({ severity: 'error', summary: 'Information', detail: result.error });
        this.save_loading = false;
      }
    } catch (error) {
      console.error('Error in createContract:', error);
      this.save_loading = false;
    }
  }

  // generateContractNumber(): Promise<string> {

  //   return new Promise((resolve, reject) => {
  //     if (this.SelectedClient.BpAbbreviation == null) {
  //       this.SelectedClient = new BusinesspartnerV2();
  //     }

  //     this.possiblePSPElements = [];
  //     this.businessDivisionService.getMainContracts().subscribe({
  //       next: (contracts) => {
  //         let todaysContractCount = 1;
  //         contracts.forEach((contract) => {
  //           if (
  //             moment(contract.created_at).format("DD-MM-YYYY") ===
  //             moment(new Date()).format("DD-MM-YYYY")
  //           ) {
  //             ++todaysContractCount;
  //           }
  //         });

  //         let CountProlongation = 0;

  //         if (this.PreviousPspElement && this.PreviousPspElement.PSPElementeID != null) {

  //           this.businessDivisionService
  //             .getProlongationsInProject(
  //               this.PreviousPspElement.PersonID,
  //               this.PreviousPspElement.ProjectID
  //             )
  //             .subscribe({
  //               next: (psp) => {
  //                 psp.forEach((element) => {
  //                   if (
  //                     moment(this.contract.ContractStart).format("YYYY-MM-DD") >=
  //                     moment(element.validto).format("YYYY-MM-DD") &&
  //                     this.PreviousPspElement.PersonID === element.PersonID
  //                   ) {
  //                     ++CountProlongation;
  //                   }
  //                   this.possiblePSPElements.push(element);
  //                 });

  //                 const BPAbbrevation = this.SelectedClient.BpAbbreviation;
  //                 let prefix =
  //                   this.contract.ContractType === "Angebote" ? "A_" : "V_";
  //                 const today = moment(new Date()).format("YYYY-MM-DD");

  //                 this.contractNumberGenerationDialog = false;
  //                 this.contractNumberIsCreated = true;

  //                 // Resolve the promise with the generated contract number

  //                 this.contract.ContractNumber = `${prefix}${today} -${todaysContractCount} / ${BPAbbrevation}. ${CountProlongation}`;

  //                 resolve(
  //                   `${prefix}${today} -${todaysContractCount} / ${BPAbbrevation}. ${CountProlongation}`
  //                 );
  //               },
  //               error: (error) => {
  //                 // Handle any errors that occur during subscription
  //                 reject(error);
  //               },
  //             });


  //         } else {

  //           const BPAbbrevation = this.SelectedClient.BpAbbreviation;
  //           let prefix =
  //             this.contract.ContractType === "Angebote" ? "A_" : "V_";
  //           const today = moment(new Date()).format("DD-MM-YYYY");

  //           this.contractNumberGenerationDialog = false;
  //           this.contractNumberIsCreated = true;

  //           // Resolve the promise with the generated contract number

  //           this.contract.ContractNumber = `${prefix}${today} -${todaysContractCount} / ${BPAbbrevation}. ${CountProlongation}`;

  //           resolve(
  //             `${prefix}${today} -${todaysContractCount} / ${BPAbbrevation}. ${CountProlongation}`
  //           );
  //         }
  //       },
  //       error: (error) => {
  //         // Handle any errors that occur during subscription
  //         reject(error);
  //       },
  //     });
  //   });
  // }

  generateContractNumber(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.SelectedClient.BpAbbreviation == null) {
        this.SelectedClient = new BusinesspartnerV2();
      }

      this.possiblePSPElements = [];
      let todaysContractCount = 1;
      const today = moment(new Date()).format("YYYY-MM-DD");

      this.contracts.forEach((contract) => {
        if (moment(contract.created_at).format("YYYY-MM-DD") === today) {
          ++todaysContractCount;
        }
      });

      let CountProlongation = 0;

      if (this.PreviousPspElement && this.PreviousPspElement.PSPElementeID != null) {
        this.businessDivisionService
          .getProlongationsInProject(
            this.PreviousPspElement.PersonID,
            this.PreviousPspElement.ProjectID
          )
          .subscribe({
            next: (psp) => {
              psp.forEach((element) => {
                if (
                  moment(this.contract.ContractStart).format("YYYY-MM-DD") >=
                  moment(element.validto).format("YYYY-MM-DD") &&
                  this.PreviousPspElement.PersonID === element.PersonID
                ) {
                  ++CountProlongation;
                }
                this.possiblePSPElements.push(element);
              });

              this.generateAndResolveContractNumber(todaysContractCount, CountProlongation, resolve);
            },
            error: (error) => {
              reject(error);
            },
          });
      } else {
        this.generateAndResolveContractNumber(todaysContractCount, CountProlongation, resolve);
      }
    });
  }

  private generateAndResolveContractNumber(todaysContractCount: number, CountProlongation: number, resolve: (value: string | PromiseLike<string>) => void): void {
    const BPAbbrevation = this.SelectedClient.BpAbbreviation;
    let prefix = this.contract.ContractType === "Angebote" ? "A_" : "V_";
    const today = moment(new Date()).format("YYYY-MM-DD");

    this.contractNumberGenerationDialog = false;
    this.contractNumberIsCreated = true;

    const contractNumber = `${prefix}${today}-${todaysContractCount}/${BPAbbrevation}.${CountProlongation}`;
    this.contract.ContractNumber = contractNumber;
    resolve(contractNumber);
  }

  refreshLists() {
    this.businessDivisionService.getContractData().subscribe(x => {
      this.employees = x.employees;
      this.eps = x.eps
      this.pspElemente = x.psp;
      this.pspElementeBefore = x.psp;
      this.allPSPs = x.psp;
      this.businesspartners = x.bp;
      this.contacts = x.contacts;
      this.externEmployees = x.externEmployees;
      this.contacts.sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0))

      //this.checkEmployee();

      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Listen aktualisiert' });
    })
  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  openNew(contracttype, ContractSubCategoryID) {
    this.tabIndex = 0;
    this.ContractOwner = new User();
    this.CostCenter = {};
    this.Creator = new User();
    this.PreviousPspElement = new PSPElemente();
    this.ActualPspElement = new PSPElemente();
    this.SelectedBusinesspartner = new BusinesspartnerV2();
    this.SelectedContact = new ContactV2();
    this.SelectedClient = new BusinesspartnerV2();
    this.EngagementPartner = new Employee();
    //this.Helper = new ContactV2();
    this.contract = new Contract();

    this.contract.ContractType = contracttype;
    this.contractDialogNew = true;
    this.contract.ContractSubCategoryID = ContractSubCategoryID;
    this.InvoiceMethod = {};
    this.InvoiceMethod.Name = "Übermittlungsmethode auswählen"
    this.documents = [];
  }


  showGenerateNumber() {
    this.contractNumberGenerationDialog = true;
  }

  showPopUpPSP() {
    this.popUpPSP = true;
  }

  hidePopUpPSP() {
    this.popUpPSP = false;
  }

  hideDialog() {
    this.tabIndex = 0;
    this.contractDialogNew = false;
    this.contractNumberGenerationDialog = false;
  }

  openNext() {
    this.tabIndex = this.tabIndex + 1;
  }

  // selectHelperContacts() {
  //   if (this.Helper.BusinesspartnerID == 2) {
  //     this.helperContacts = this.employees;
  //   } else {
  //     this.helperContacts = this.contacts.filter(contact => contact.BusinesspartnerID == this.Helper.BusinesspartnerID)
  //   }
  // }

  // selectBusinesspartnerContacts() {
  //   if (this.SelectedBusinesspartner.BusinesspartnerID == 2) {
  //     this.businessPartnerContacts = this.employees;
  //   } else {
  //     this.businessPartnerContacts = this.contacts.filter(contact => contact.BusinesspartnerID == this.SelectedBusinesspartner.BusinesspartnerID)
  //   }
  // }

  filterPSPElements() {
    if (this.ContractStart != null) {
      this.pspElemente = [];
      this.allPSPs.map(psp => {
        if (moment(this.ContractStart).format('YYYY-MM-DD') < moment(psp.validto).format('YYYY-MM-DD'))
          this.pspElemente.push(psp);
      })
    }
  }

  calculateDiff(pspElement) {
    this.diff = (business(pspElement.validfrom, 'YYYY-MM-DD').businessDiff(business(pspElement.validto, 'YYYY-MM-DD'))) + 1;
  }

  openPrev() {

    this.tabIndex = this.tabIndex - 1;
    // this.index = (this.index === 0) ? 2 : this.index - 1;
  }

  onBlurMethod(value) {
    var regExp = new RegExp('^[^<>]*$')
    var bool = regExp.test(value);
    if (!bool) {
      alert("Leider ist die Verwendung der Symbole \"<\" und \">\" derzeit nicht möglich.")
    }
  }

  createContractOLD(formvalid) {
    formvalid = true //Dummy
    if (formvalid) {
      var tempStart = moment(this.ContractStart).format('YYYY-MM-DD');
      var tempEnd = moment(this.ContractEnd).format('YYYY-MM-DD');
      // var tempPaymentDeadline = moment(this.PaymentDeadline).format('YYYY-MM-DD');
      //Check actual Contracts this day
      this.businessDivisionService.getMainContracts().subscribe(contracts => {
        let todaysContractCount = 1;
        contracts.map(contract => {
          if (moment(contract.created_at).format("DD-MM-YYYY") == moment(new Date()).format("DD-MM-YYYY")) {
            ++todaysContractCount
          }
        })
        let tempContractNumber = this.contract.ContractNumber
        tempContractNumber = this.contract.ContractNumber.substring(0, 13) + todaysContractCount + this.contract.ContractNumber.substring(this.contract.ContractNumber.indexOf(' /'));
        if (this.CostCenter == null) {
          this.CostCenter = {};
        }

        if (this.Helper == null) {
          this.Helper = new ContactV2();
        }

        if (this.ContractOwner == null) {
          this.ContractOwner = new User();
        }

        if (this.SelectedClient == null) {
          this.SelectedClient = new BusinesspartnerV2();
        }

        if (this.SelectedContact == null) {
          this.SelectedContact = new ContactV2();
        }

        if (this.SelectedBusinesspartner == null) {
          this.SelectedBusinesspartner = new BusinesspartnerV2();
        }

        if (this.ActualPspElement == null) {
          this.ActualPspElement = new PSPElemente();
        }
        if (this.PreviousPspElement == null) {
          this.PreviousPspElement = new PSPElemente();
        }

        this.http.post<any>(`${environment.apiUrl}/create/contract`, {

          'PreassignmentID': this.contract.PreassignmentID,

          'ContractNumber': this.contract.ContractNumber,
          'ContractSubCategoryID': this.contract.ContractSubCategoryID,
          'ContractType': this.contract.ContractType,
          'ContractTitle': this.contract.ContractTitle,
          'CostCenter': this.CostCenter.Name,
          'ContractStart': tempStart,
          'ContractEnd': tempEnd,
          'ContractOwner': this.ContractOwner.PersonID,
          'created_by': this.currentUser.PersonID,
          'updated_by': this.currentUser.PersonID,

          //Resource
          'Helper': this.Helper.PersonID,
          'TravelExpenses': this.contract.TravelExpenses,
          // 'TravelExpenses': this.diff, 
          'IncidentalExpenses': this.contract.IncidentalExpenses,
          'TravelExpensesComment': this.contract.TravelExpensesComment,
          'Client': this.SelectedClient.BusinesspartnerID,
          'Contractor': this.SelectedBusinesspartner.BusinesspartnerID,
          'ContactContractor': this.SelectedContact.PersonID,
          'Comment': this.contract.Comment,
          'PspElementID': this.ActualPspElement.PSPElementeID,
          'PreviousPspElementID': this.PreviousPspElement.PSPElementeID,

          //Client
          'ContractClientID': this.contract.ContractClientID,

          //Offer
          'ContractOfferID': this.contract.ContractOfferID,
          'EngagementPartner': this.EngagementPartner.PersonID,
          'Creator': this.Creator.PersonID,

          //Payment

          'BpInvoiceInfo': this.ActualPspElement.BpInvoiceInfo,
          'InvoiceMethod': this.InvoiceMethod.Name,
          'InvoiceTo': this.ActualPspElement.InvoiceTo,
          // 'PaymentDeadline': tempPaymentDeadline,
          'PaymentDeadline': this.contract.PaymentDeadline,


        })
          .subscribe(result => {
            if (result.result === 'success') {
              this.contract.ContractMainID = result.ContractMainID;

              if (this.tempEvent != null) {

                this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Bitte warten, Dateien werden hochgeladen' });
                this.myUploader();
              } else {

                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vertrag wurde erfolgreich erstellt' });
                this.hideDialog();
                this.redirectTo();
              }
            } else {
              this.messageService.add({ severity: 'error', summary: 'Information', detail: result.error });
            }
          });
      });
    } else {

      this.submittedError = true;

    }


  }

  onChangeContract(event) {
    this.router.navigate(['/businessdivision/contracts/' + event.MainAbbreviationURL + '/detail/' + event.SubAbbreviationURL + "/" + event.ContractMainID
    ])
    this.text = "";
  }

  redirectTo() {
    let routing = this.router.url
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([routing]));
  }

  checkBPAbbrev() {
    if (this.SelectedClient.BpAbbreviation == null) {
      this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Businesspartner hat kein Kürzel, bitte pflegen.' });
    }
  }

  generateContractNumberOLD() {
    if (this.ActualPspElement == null) {
      this.ActualPspElement = new PSPElemente();
    }

    if (this.SelectedClient.BpAbbreviation == null) {
      this.SelectedClient = new BusinesspartnerV2();
    }



    if (this.SelectedClient.BpAbbreviation != null && this.ActualPspElement.PSPName != null) {
      this.calculateDiff(this.ActualPspElement)

      if (this.ActualPspElement.InvoiceMethod != null)
        this.InvoiceMethod.Name = this.ActualPspElement.InvoiceMethod

      this.possiblePSPElements = []
      this.businessDivisionService.getMainContracts().subscribe(contracts => {
        let todaysContractCount = 1;
        contracts.map(contract => {
          if (moment(contract.created_at).format("DD-MM-YYYY") == moment(new Date()).format("DD-MM-YYYY")) {
            ++todaysContractCount
          }
        })

        let CountProlongation = 0

        this.businessDivisionService.getProlongationsInProject(this.ActualPspElement.PersonID, this.ActualPspElement.ProjectID).subscribe(psp => {
          psp.map(element => {
            if (moment(this.ActualPspElement.validfrom).format("YYYY-MM-DD") >= moment(element.validto).format("YYYY-MM-DD") && this.ActualPspElement.PersonID == element.PersonID) {
              ++CountProlongation
            }
            this.possiblePSPElements.push(element);
          })

          var BPAbbrevation = this.SelectedClient.BpAbbreviation;

          if (this.contract.ContractType == 'Angebote') {
            var prefix = 'A_';

          } else {
            var prefix = 'V_'
          }

          var today = moment(new Date()).format("DD-MM-YYYY");

          this.contract.ContractNumber = prefix + today + " -" + todaysContractCount + ' / ' + BPAbbrevation + ". " + CountProlongation;

          this.contractNumberGenerationDialog = false;

          this.contractNumberIsCreated = true
        })

      });

    } else {

      this.contractNumberIsCreated = false;
    }
  }

  cancel() {
    this.contract = new Contract();
    this.contractDialogNew = false;
  }

  changeHelper(contact) {
    this.Helper = contact;
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) {
      this.contracts = this.contractTemp;
      return;
    }

    let items = [];
    term = term.toLowerCase();
    this.searchItems.filter(contractItems => {

      if (contractItems.ContractNumber.toLowerCase().includes(term)) {
        items.push(contractItems);

      }
      this.checkSearchResultEmpty(items)
      this.contracts = items
    });

  }

  checkSearchResultEmpty(items) {
    if (!items.length) {
      this.searchResultEmpty = true;
    }
    else {
      //this.trainings=this.items;
      this.searchResultEmpty = false;
    }
  }

  addFix() {
    this.searchResult = true;
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
  }


  // myUploaderTemp(event) {
  //   for (let file of event.files) {
  //     let document = file;

  //     document.DocName = file.name;
  //     document.created_at = moment(new Date());

  //     this.documents.push(document)
  //   }
  //   this.tempEvent = event;
  //   this.hideDocDialog();

  // }

  myUploaderTemp(event) {

    for (let file of event.files) {
      let document = file;

      document.DocName = file.name;
      document.created_at = moment(new Date());

      this.documents.push(document)
    }
    this.tempEvent = event;
    this.hideDocDialog();

  }

  // myUploader() {

  //   for (let file of this.documents) {

  //     const formData = new FormData();
  //     formData.append("image", file, file.name);
  //     formData.append("ContractMainID", String(this.contract.ContractMainID));

  //     this.uploadService.uploadContractDoc(formData).subscribe(result => {
  //       if (result.result === 'success') {
  //         this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vertrag wurde erfolgreich erstellt' });
  //         this.hideDialog();
  //         //this.redirectTo();
  //       } else {
  //         this.messageService.add({ severity: 'error', summary: 'Information', detail: result.error });
  //       }
  //     });
  //   }
  // }

  myUploader() {

    for (let file of this.documents) {

      if (file.ContractDocID == null) {

        const formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("ContractMainID", String(this.contract.ContractMainID));


        this.uploadService.uploadContractDoc(formData).subscribe(result => {
          if (result.result === 'success') {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Dokument wurde erfolgreich hochgeladen' });
            this.hideDialog();
          } else {
            this.messageService.add({ severity: 'error', summary: 'Information', detail: result.error });
          }
        });

      }
    }
  }


  deleteSelectedDocuments() {
    this.documents = this.documents.filter(val => !this.selectedDocuments.includes(val));
  }

  hideDocDialog() {
    this.newDocDialog = false;
  }

  showDocDialog() {
    this.newDocDialog = true;
  }

  checkEmployee() {
    if (this.SelectedBusinesspartner.BusinesspartnerID != null && this.ActualPspElement.PersonID != null) {
      let isIn = this.externEmployees.filter(emp => emp.PersonID == this.ActualPspElement.PersonID && emp.Employer == this.SelectedBusinesspartner.BusinesspartnerID)[0]
      this.empIsIn = isIn != null;
    }
  }
}


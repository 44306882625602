import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { XrmLeadToOrderComponent } from 'src/app/components/xrm/xrm-lead-to-order/xrm-lead-to-order.component';
import { Employee } from 'src/app/shared/model/employee';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { ContactV2 } from 'src/app/shared/model/xrm/V2/contactV2';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-bp-contact-crud',
  templateUrl: './bp-contact-crud.component.html',
  styleUrls: ['./bp-contact-crud.component.css'],
})
export class BpContactCrudComponent implements OnInit {
  //Checking if we need new data;
  // @Input() is_new_data: Boolean;

  actual_contact: ContactV2;

  //Models
  businesspartner: BusinesspartnerV2 = new BusinesspartnerV2();

  // Helper
  all_contacts = [];
  filtered_contacts = [];
  contactDetailCard = true;
  activeIndex = 0;
  selected_contact;
  loading = true;
  suggestions_businesspartner: BusinesspartnerV2[];
  all_businesspartners: BusinesspartnerV2[];
  all_employees: Employee[];
  submitted = false;
  save_loading = false;

  BusinesspartnerName: String;
  roles: Array<{ Name: string }> = [
    { Name: 'Projektleitung' },
    { Name: 'Entscheider' },
    { Name: 'Einkäufer' },
    { Name: 'Geschäftsführung' },
    { Name: 'Mitarbeiter' },
    { Name: 'ext. Mitarbeiter / Interim' },
    { Name: 'Buchhaltung' },
  ];

  salutations: Array<{ Name: string }> = [{ Name: 'Herr' }, { Name: 'Frau' }, { Name: 'Keine Angabe' }];
  titles: Array<{ Name: string }> = [{ Name: 'Dr.' }, { Name: 'Prof.' }, { Name: 'Mag.' }, { Name: 'Dipl.-Ing.' }];
  languages: Array<{ Name: string }> = [
    { Name: 'deutsch' },
    { Name: 'englisch' },
    { Name: 'sonstige' },
    // { Name: 'chinesisch' },
    // { Name: 'spanisch' },
    // { Name: 'hindi/urdu' },
    // { Name: 'arabisch' },
    // { Name: 'französisch' },
    // { Name: 'italienisch' },
  ];
  attitudes: Array<{ Name: string }> = [
    { Name: 'Unbekannt' },
    { Name: 'Fan' },
    { Name: 'Zufrieden' },
    { Name: 'Neutral' },
    { Name: 'Kritisch' },
    { Name: 'Gegner' },
  ];

  yesNo: Array<{ Name: string }> = [{ Name: 'Nein' }, { Name: 'Ja' }];
  contact_reference;

  constructor(
    private router: Router,
    private xrmServiceV2: XRMServiceV2,
    private messageService: MessageService,
    private appXrmLeadToOrderComponent: XrmLeadToOrderComponent,
    private location: Location
  ) { }

  ngOnInit() {
    this.loading = true;
    this.xrmServiceV2.getDataForBusinesspartnerContactStep().subscribe((data) => {

      this.all_contacts = data.all_contacts;
      this.filtered_contacts = this.all_contacts;
      this.all_businesspartners = data.all_businesspartners;
      this.all_employees = data.all_employees;

      this.loadStoredData();
    });
  }

  loadStoredData() {
    let storedBusinessPartnerContact = sessionStorage.getItem('otc_actual_businesspartner_contact');
    console.log(JSON.parse(storedBusinessPartnerContact));

    if (storedBusinessPartnerContact) {
      this.actual_contact = JSON.parse(storedBusinessPartnerContact);
      
      this.selectBusinesspartner(
        this.all_businesspartners.find((element) => element.BusinesspartnerID == this.actual_contact.BusinesspartnerID)
      );
      this.contactDetailCard = true;
    } else {
      let storedBusinessPartner = sessionStorage.getItem('otc_actual_businesspartner');

      this.actual_contact = new ContactV2();

      if (storedBusinessPartner) {
        this.selectBusinesspartner(JSON.parse(storedBusinessPartner));
      } else {
        this.businesspartner = new BusinesspartnerV2();
      }
      //HINWEIS: Problem, dass wenn über die Navigation +Kontakt gewählt wurde, nicht die Detailansicht kommt, sondern die Liste, was nicht unbedingt nutzerfreundlich ist
      //somit wird fürs erste immer die Detailkarte angezeigt
      this.contactDetailCard = true;
    }

    console.log(this.contactDetailCard);
    this.loading = false;
  }

  // In the component code
  searchForBusinesspartner(search_text) {
    console.log(search_text);
    const lowerCaseSearchText = search_text.toLowerCase();
    this.suggestions_businesspartner = this.all_businesspartners.filter((element) =>
      element.BusinesspartnerName.toLowerCase().includes(lowerCaseSearchText)
    );
  }

  selectBusinesspartner(data) {
    console.log(JSON.stringify(data));
    if (data) {
      this.businesspartner = data;

      this.actual_contact.ContactPhone = this.businesspartner.Phone1;

      this.BusinesspartnerName = this.businesspartner.BusinesspartnerName;
      this.businesspartner
        ? sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.businesspartner))
        : sessionStorage.removeItem('otc_actual_businesspartner');

      this.filtered_contacts = this.all_contacts.filter(
        (element) => element.BusinesspartnerID == this.businesspartner.BusinesspartnerID
      );
    }
  }

  openContactDetailCard(contact: ContactV2) {
    if (contact) {
      this.actual_contact = contact;
      this.selectBusinesspartner(
        this.all_businesspartners.find((element) => element.BusinesspartnerID == this.actual_contact.BusinesspartnerID)
      );
    } else {
      sessionStorage.removeItem('otc_actual_businesspartner_contact');
      this.loadStoredData();
      this.actual_contact = new ContactV2();
    }
    this.contactDetailCard = true;
  }

  closeContactDetailCard() {
    this.contactDetailCard = false;

    if (!this.businesspartner.BusinesspartnerName || !this.BusinesspartnerName)
      this.filtered_contacts = this.all_contacts;

  }
  abortChanges() {
    if (this.actual_contact && this.actual_contact.ContactID) this.selected_contact = this.actual_contact;
    this.actual_contact = new ContactV2();
    this.closeContactDetailCard();
  }

  // saveAndSelectContact() {
  //   this.closeContactDetailCard();
  //   this.appXRMDashboard.openEditDialog('bp', false);
  // }

  saveAndSelectContact(next) {
    this.save_loading = true;
    // let storedBusinessPartner = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner'));

    let storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

    console.log(this.actual_contact);

    this.actual_contact
      ? sessionStorage.setItem('otc_actual_businesspartner_contact', JSON.stringify(this.actual_contact))
      : sessionStorage.removeItem('otc_actual_businesspartner_contact');

    this.actual_contact.BusinesspartnerID = this.businesspartner.BusinesspartnerID;

    if (this.actual_contact.ContactID != null) {
      this.xrmServiceV2.editBusinesspartnerContact(this.actual_contact).subscribe((response) => {
        if (response.result == 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Information',
            detail: response.message,
          });

          this.selected_contact = this.actual_contact;
          //this.closeContactDetailCard();

          // this.router.navigate(['/xrm/new-lead2order'], {
          //   queryParams: { stringValue: 'lead', booleanValue: false }
          // });

          console.log(storedLead);

          this.appXrmLeadToOrderComponent.activeStepIndex = 2;
          this.appXrmLeadToOrderComponent.maxStepIndex = 2;

          console.log(next);

          if (next) {

            if (storedLead) {

              console.log("in route to edit lead")

              this.router.navigate(['/xrm/edit-lead2order'], {
                queryParams: { stringValue: 'lead', booleanValue: false, edit: storedLead.LeadID }
              });

            }
            else {

              console.log("in route to new lead");

              this.router.navigate(['/xrm/new-lead2order'], {
                queryParams: { stringValue: 'lead', booleanValue: true, edit: false },
                queryParamsHandling: 'merge'
              });

            }

          } else {
            this.location.back();
          }

        }
        if (response.result == 'error') {

          this.messageService.add({
            severity: 'info',
            summary: 'Information',
            detail: response.message,
          });

        }
      });
    } else {
      this.xrmServiceV2.createBusinesspartnerContact(this.actual_contact).subscribe((response) => {
        if (response.result == 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Information',
            detail: response.message,
          });

          this.actual_contact = response.newlyCreatedObject;
          this.selected_contact = this.actual_contact;
          
          this.actual_contact
            ? sessionStorage.setItem('otc_actual_businesspartner_contact', JSON.stringify(this.actual_contact))
            : sessionStorage.removeItem('otc_actual_businesspartner_contact');

          this.closeContactDetailCard();

          this.router.navigate(['/xrm/new-lead2order'], {
            queryParams: { stringValue: 'lead', booleanValue: true, edit: false },
            queryParamsHandling: 'merge'
          });

        }
        if (response.result == 'error')
          this.messageService.add({
            severity: 'info',
            summary: 'Information',
            detail: response.message,
          });
      });
    }
    this.save_loading = false;

  }

  goStepBack() {
    this.router.navigate(['/xrm/new-lead2order'], {
      queryParams: { stringValue: 'bp', booleanValue: false }
    });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { BroadcastService, MsalService } from '@azure/msal-angular';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  // Declare component properties
  env = environment.version;  // environment version
  loginForm: FormGroup;  // login form group
  loading = false;  // flag indicating if the form is currently submitting
  submitted = false;  // flag indicating if the form has been submitted
  email: string;  // user email
  isCandidate = false;  // flag indicating if the user is a candidate

  private returnUrl: string;  // the URL to return to after login
  public userInfo: any = null;  // user information from Azure AD
  public isIframe: boolean;  // flag indicating if the app is running in an iframe

  private subscription: Subscription = new Subscription();  // subscription to dispose on component destruction

  // Constructor injection
  constructor(
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private loginService: LoginService
  ) {
    // Redirect to dashboard if the user is already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/dashboard/default']);
    }
  }

  // Component initialization
  ngOnInit(): void {
    // Subscribe to MSAL events
    this.subscription = this.broadcastService.subscribe('msal:acquireTokenFailure', () => { });

    // Get the return URL from the query params or set a default
    const returnUrlQueryParam = this.route.snapshot.queryParams['returnUrl'];
    if (returnUrlQueryParam) {
      this.returnUrl = returnUrlQueryParam;
    } else {
      const urlFragment = window.location.hash;
      const returnUrl = urlFragment.substring(urlFragment.indexOf('/') + 1);
      this.returnUrl = returnUrl ? returnUrl : 'dashboard/default';
    }
    sessionStorage.setItem('returnUrl', JSON.stringify(this.returnUrl));

    // Check if the user is already authenticated and redirect accordingly
    this.checkoutAccount();

    // Handle MSAL redirect callback
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error:', authError.errorMessage);
        return;
      }
    });


    if (environment.name === 'prodv2') {
      // Subscribe to MSAL login success event
      this.broadcastService.subscribe('msal:loginSuccess', () => {
        // Attempt to acquire an access token silently
        this.authService.acquireTokenSilent({
          scopes: ['User.Read'] // Add other scopes as needed
        }).then(accessToken => {
          // Successfully acquired access token, now call your backend
          this.authenticationService.azure_login_new(accessToken).subscribe(
            () => this.router.navigate([this.returnUrl]),
            (error) => {
              console.error('Error during login:', error);
              this.loading = false;
            }
          );
        }).catch(error => {
          console.error('Token acquisition error:', error);
          this.loading = false;
        });
      });
    }
    else{

      // Subscribe to MSAL login success event
    this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
      this.authenticationService.azureLoginSuccess = true;
      this.authenticationService.azure_login(payload).subscribe(
        () => this.router.navigate([this.returnUrl]),
        (error) => {
          // console.log(error);
          this.loading = false;
        }
      );
    });


    }


    

    // Create the login form
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  // Component destruction
  ngOnDestroy(): void {
    // Unsubscribe from MSAL events
    this.broadcastService.getMSALSubject().next(1);
    this.subscription.unsubscribe();
  }

  // Set isCandidate flag to true
  candidate(): void {
    this.isCandidate = true;
  }

  // Initiate Azure AD login flow
  azure_login(): void {
    this.authService.loginRedirect({
      scopes: ['user.readwrite', 'user.readbasic.all', 'profile', 'Calendars.ReadWrite']
    });
    this.authenticationService.azureLoginSuccess = true;
  }

  // Initiate Azure AD logout
  azure_logout(): void {
    this.loginService.setUserLoggedIn(false);
    this.authService.logout();
  }

  // Check if the user is already authenticated and redirect accordingly
  checkoutAccount(): void {
    // Get current time in seconds
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    // Check if the app is running in an iframe and get the user info from Azure AD
    this.isIframe = window !== window.parent && !window.opener;
    this.userInfo = this.authService.getAccount();

    // If the user is authenticated and the token hasn't expired, redirect to login page
    if (this.userInfo && this.userInfo.idToken.exp > currentTimeInSeconds) {
      this.router.navigate(['/auth/ms-login']);
    }
  }
}
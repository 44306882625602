import { User } from "./user";

export class Employee extends User{
    //id: number;
      PersonID: number;
      DisplayName: string;
      CompanyID: number;
      Active: string;
      EMail: string;
      StreetNumber: string;
      PostalCode: number;
      Residence: string;

      MailingPostalCode: number;
      MailingResidence: string;
      MailingStreetNumber: string;
      MailingStreet: string;
      ShowBirthday: string;
      SameMailingAddress: string;
      

      QualificationLevel: string;
      Teamleader: number;
      Mentor: string;
      MentorPlusName: string;
      MobileGrandega: string;
      Role: string;
      Abbreviation: string;
      Forename: string;
      Surname: string;
      PersonType: string;
      ms_oid: number;
}

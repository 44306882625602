import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

    transform(number: number, args?: any): any {
        if (isNaN(number)) return null;
        if (number === null) return null;
        if (number === 0) return null;
        const abs = Math.abs(number);
        const isNegative = number < 0;
        let formattedNumber = '';
    
        const powers = [
            { key: ' Mio', value: Math.pow(10, 6) },
            { key: ' Tsd', value: 1000 }
        ];
    
        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;
    
            if (reduced >= 1) {
                // Format the number with two decimal places and concatenate with the unit
                formattedNumber = reduced.toFixed(2) + powers[i].key;
                break;
            }
        }
    
        // Check if the number was formatted with a unit; if not, format it as a regular number
        if (!formattedNumber) {
            formattedNumber = abs.toFixed(2);
        }
    
        return (isNegative ? '-' : '') + formattedNumber;
    }
}
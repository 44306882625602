import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Contract } from 'src/app/shared/model/businessdivision/contract';
import { Employee } from 'src/app/shared/model/employee';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { ContactV2 } from 'src/app/shared/model/xrm/V2/contactV2';
import { LeadV2 } from 'src/app/shared/model/xrm/V2/leadV2';
import { PositionV2 } from 'src/app/shared/model/xrm/V2/positionV2';
import { ProjectV2 } from 'src/app/shared/model/xrm/V2/projectV2';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { Location } from '@angular/common';
import { SharedService } from 'src/app/shared/services/shared.service';
import { take, firstValueFrom, lastValueFrom, Subscription, forkJoin, Observable } from 'rxjs';
import { Assignment } from 'src/app/shared/model/xrm/assignment';
import { XrmLeadToOrderComponent } from 'src/app/components/xrm/xrm-lead-to-order/xrm-lead-to-order.component';
import { element } from 'protractor';
import { AuthenticationService } from 'src/app/shared/services';


@Component({
  selector: 'app-lead-crud',
  templateUrl: './lead-crud.component.html',
  styleUrls: ['./lead-crud.component.css'],
})


export class LeadCrudComponent implements OnInit {


  activePositionStepIndex = 0;
  public maxPositionStepIndex = 0;
  is_intern;

  newPreAssignmentDialog;

  newPreAssignmentConfirmation;

  actual_lead: LeadV2;
  actual_project: ProjectV2;

  //Models
  businesspartner: BusinesspartnerV2 = new BusinesspartnerV2();
  contact: ContactV2 = new ContactV2();

  // Helper
  all_positions: PositionV2[];
  all_bps: BusinesspartnerV2[];
  all_leads: LeadV2[];
  suggestions_leads: LeadV2[];
  all_contacts: ContactV2[];
  all_contacts_raw: ContactV2[];
  all_assignments;
  all_preassignments = [];
  filtered_leads = [];
  leadDetailCard = false;
  activeIndex = 0;
  selected_lead: LeadV2;
  loading = true;
  table_loading = true;
  all_employees: Employee[];
  submitted = false;
  save_loading = false;

  status: Array<{ Name: string }> = [{ Name: 'Aktuell' }, { Name: 'Wurde zum Angebot' }, { Name: 'Verloren' }];

  probabilities: Array<{ Name: string, Value: number }> = [{ Name: 'keine Angabe', Value: 1 }, { Name: 'unwahrscheinlich (Idee)', Value: 10 }, { Name: 'gering (konkr. Anfrage)', Value: 20 }, { Name: '50 / 50 (Angebot)', Value: 50 }, { Name: 'Besetzung wahrscheinlich', Value: 60 }, { Name: 'Sollte sicher sein', Value: 70 }, { Name: 'gewonnen (Vertrag)', Value: 100 }, { Name: 'VERLOREN', Value: 0 }];

  stateOfferOptions: any[] = [{ label: 'Ja', value: true }, { label: 'Nein', value: false }];

  state_offer_value = this.stateOfferOptions[1];

  //Passing Data
  LeadName: String;

  //Dialog for Offer
  open_offer_dialog = false;
  actual_contract_offer = new Contract();

  no_bp_location = false;
  all_locations;

  current_user;

  infoLeadProbability: boolean;

  selectedItems1: any[] = [];
  selectedItems2: any[] = [];

  checkboxItems1: any[] = [
    { name: 'Ist unser Kontakt der primäre Ansprechpartner (Entscheider) für das Thema?', key: 'primary_contact' },
    { name: 'Zeigt der Ansprechpartner deutliche Eigeninteresse für den Erfahrungsaustausch mit grandega (z.B. kurzf. Terminvereinbarungen sind möglich)', key: 'interest' },
    { name: 'Wird unserer (grandega) Argumentation gefolgt, werden unsere Aussagen zumindest teilweise bekräftigt.', key: 'argument_acceptance' },
    { name: 'Ist unser Ansprechpartner Treiber des Themas im Unternehmen und bereit, ggf. weitere Funktionen einzubinden, um das Thema voranzutreiben?', key: 'topic_driver' },
    { name: 'Können wir die Aufgabenstellung einschätzen (Anforderungen, Erfahrungen grandega etc.)?', key: 'task_assessment' },
    { name: 'Ist das Budget bekannt und steht es absehbar zur Verfügung?', key: 'budget_available' },
    { name: 'Sind wir (formal) in den vorläufigen Bewerberkreis (Long-list) mit einbezogen?', key: 'longlist_inclusion' }
  ];

  checkboxItems2: any[] = [
    { name: 'Kennen wir den Entscheider, die Entscheidungswege und seine Kaufmotive?', key: 'decision_maker_knowledge' },
    { name: 'Stehen wir mit dem Entscheider in regelmäßigem Kontakt (Besuch/Telefonat im Wochenturnus)?', key: 'regular_contact' },
    { name: 'Können erste Aktivitäten – bei einem Neukunden – vor dem Hauptprojekt als Akquisitionsziel abgerechnet werden (Workshops, Einsatzuntersuchungen, Vorstudien etc.)?', key: 'initial_activities' },
    { name: 'Ist bereits ein erstes Orientierungsangebot eingereicht und finden dafür erste Verhandlungen/Nachbesserungen statt?', key: 'initial_offer' },
    { name: 'Kennen wir den Wettbewerb und seine Angebote (zumind. partiell)?', key: 'competition_knowledge' },
    { name: 'Sind alle Angebote des Wettbewerbs eingereicht?', key: 'all_offers_submitted' },
    { name: 'Ist das ggf. vorhandene Bewerberfeld auf wenige (2-4) Mitanbieter reduziert (short-list)?', key: 'shortlist' },
    { name: 'Wurden wir um ein endgültiges Angebot gebeten?', key: 'final_offer_request' }
  ];

  selectedItems3: any[] = [];

  checkboxItems3: any[] = [
    { name: 'Ist die Vorentscheidung für uns gefallen und kennen wir die Gründe?', key: 'pre_decision' },
    { name: 'Ist der Kontakt zu dem Entscheidungsträger jederzeit möglich?', key: 'decision_maker_access' },
    { name: 'Sind die Verhandlungen über Konditionen, Zeiten, Ressourcen im Gange und verlaufen positiv?', key: 'positive_negotiations' },
    { name: 'Sind abschließende Vertragsverhandlungen fest terminniert?', key: 'scheduled_final_negotiations' },
    { name: 'Kann mit dem Auftrag/Vertrag in einem kurzfristigen/definierten Zeitraum gerechnet werden?', key: 'expected_contract' }
  ];

  selectedItems4: any[] = [];

  checkboxItems4: any[] = [
    { name: 'Ist der Wettbewerb ausgeschlossen?', key: 'competition_excluded' },
    { name: 'Liegt eine mündliche – besser schriftliche – Absichtserklärung (LoI) vor?', key: 'letter_of_intent' },
    { name: 'Sind sämtliche Verhandlungen abgeschlossen und wird der endgültige Vertrag erstellt?', key: 'final_contract_preparation' }
  ];

  selectedItems5: any[] = [];

  checkboxItems5: any[] = [
    { name: 'Liegt der Auftrag, die Bestellung gemäß Angebot/Vertrag vor?', key: 'order_received' },
    { name: 'Sind die Verträge beiderseitig unterzeichnet?', key: 'contracts_signed' },
    { name: 'Wird der Auftrag abgearbeitet und fakturiert?', key: 'order_processing' }
  ];

  empty;

  constructor(
    private router: Router,
    private xrmServiceV2: XRMServiceV2,
    private messageService: MessageService,
    private location: Location,
    private confirmationService: ConfirmationService,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {

    this.current_user = this.authenticationService.currentUserValue;

    this.loading = true;
    this.table_loading = true;

    const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

    const apiCalls: { [key: string]: Observable<any> } = {
      employees: this.xrmServiceV2.getEmployees(),
      leads: this.xrmServiceV2.getLeads(),
      contacts: this.xrmServiceV2.getContacts(),
      locations: this.xrmServiceV2.getLocations(),
      bps: this.xrmServiceV2.getBusinessPartners(),
    };

    // If there's a stored lead, add preassignments and presales to the API calls
    if (storedLead) {
      apiCalls['preassignments'] = this.xrmServiceV2.getPreassignments(storedLead.LeadID);
      apiCalls['presales'] = this.xrmServiceV2.getPresales(storedLead.LeadID);
    }

    // Make all API calls in parallel
    forkJoin(apiCalls).subscribe({
      next: (results: any) => {
        this.all_employees = results.employees;
        this.all_leads = results.leads;
        this.all_contacts = results.contacts;
        this.all_contacts_raw = results.contacts;
        this.all_locations = results.locations;
        this.all_bps = results.bps;

        if (storedLead) {
          this.all_preassignments = results.preassignments;
          this.presales = results.presales;
        }

        this.loadStoredData();
        this.loading = false;
        this.table_loading = false;
      },
      error: (error) => {
        console.error('Error fetching data:', error);
        this.loading = false;
        this.table_loading = false;
        // Handle error (e.g., show error message)
      }
    });

    // //TODO Welche Tabelle nehmen wir als Lead?
    // this.xrmServiceV2.getDataForLeadStep().subscribe((data) => {
    //   this.all_employees = data.all_employees;
    //   this.all_leads = data.all_leads;
    //   this.all_contacts = data.all_contacts;
    //   this.all_contacts_raw = data.all_contacts;
    //   //this.all_assignments = data.all_assignments;
    //   this.all_locations = data.all_locations;
    //   this.all_bps = data.all_bps;

    //   let storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));
    //   if (storedLead) {
    //     this.all_preassignments = data.all_preassignments.filter(element => element.LeadID == storedLead.LeadID);
    //     this.presales = data.all_presales.filter(element => element.LeadID == storedLead.LeadID);

    //   }

    //   this.loadStoredData();

    //   //this.all_bps = data.all_bps;
    // });
  }

  showInfoDialog() {
    this.infoLeadProbability = true;
  }

  async saveLead(): Promise<void> {

    this.actual_lead.BusinesspartnerID = this.businesspartner.BusinesspartnerID;

    //TODO Confirmation Dialog?
    if (this.actual_lead.Probability == 0) {

      for (const preAssignment of this.all_preassignments) {

        preAssignment.Status = 'Absage';

        this.xrmServiceV2.editPreAssignment(preAssignment).subscribe((response) => {
          if (response.result === 'success') {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: 'Pre-Assignment geschlossen.' });
            this.location.back();
          } else if (response.result === 'error') {
            this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });
          }
          this.save_loading = false;
        });

        if (preAssignment.AssignmentID != null) {

          const assignment = new Assignment();

          assignment.AssignmentID = preAssignment.AssignmentID;
          assignment.CustomerDecision = 'false';

          this.xrmServiceV2.editAssignment(assignment).subscribe((response) => {
            if (response.result === 'success') {
              this.messageService.add({ severity: 'success', summary: 'Information', detail: 'Assignment abgesagt.' });

            } else if (response.result === 'error') {
              this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });
            }
            this.save_loading = false;
          });

        }
        this.location.back();

      }

    }

    this.actual_lead.Title = this.LeadName;


    const isNewLead = this.actual_lead.LeadID == null;

    try {
      if (isNewLead) {
        this.actual_lead.ProjectStatus = 'draft';
        this.actual_lead.ProjectName = this.actual_lead.Title;

        const response = await firstValueFrom(this.xrmServiceV2.createLead(this.actual_lead));

        if (response.result === 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Information',
            detail: response.message,
          });

          this.actual_project = response.newlyCreatedObjectProject;
          this.actual_lead = response.newlyCreatedObjectLead;

          sessionStorage.setItem('otc_actual_project', JSON.stringify(this.actual_project));
          sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.actual_lead));

        } else {
          this.messageService.add({
            severity: 'info',
            summary: 'Information',
            detail: response.message,
          });
        }
      } else {

        const response = await lastValueFrom(this.xrmServiceV2.editLead(this.actual_lead));

        if (response.result === 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Information',
            detail: response.message,
          });

          sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.actual_lead));

        } else {
          this.messageService.add({
            severity: 'info',
            summary: 'Information',
            detail: response.message,
          });
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while saving the lead.',
      });
    } finally {
      this.save_loading = false;
    }
  }

  async editPreAssignment(pre) {

    console.log(pre.PreassignmentID);

    const x = await firstValueFrom(this.xrmServiceV2.getDataForEditPreAssignment(pre.PreassignmentID));

    sessionStorage.setItem('otc_actual_assignment', JSON.stringify(x.assignment));

    const storedAssignment = x.assignment;

    sessionStorage.setItem('otc_actual_position', JSON.stringify(x.position));
    sessionStorage.setItem('otc_actual_contract_client', JSON.stringify(x.contract_client));
    sessionStorage.setItem('otc_actual_contract_resource', JSON.stringify(x.contract_resource));
    sessionStorage.setItem('otc_actual_contract_offer', JSON.stringify(x.contract_offer));
    sessionStorage.setItem('otc_actual_preassignment', JSON.stringify(pre));

    if (storedAssignment && storedAssignment.ConsultantPersonType == 'extern') {

      this.is_intern = false;

    } else {

      this.is_intern = true;

    }


    // Find the first non-null property from the right in the rowData object
    const firstNonNullProperty = this.findFirstNonNullPropertyFromKey(pre, 'ContractResourceID');

    switch (firstNonNullProperty) {

      case 'ContractOfferID':
        this.maxPositionStepIndex = 1;
        this.activePositionStepIndex = 1;
        break;

      case 'PositionID':
        this.maxPositionStepIndex = 2;
        this.activePositionStepIndex = 2;
        break;


      case 'AssignmentID':
        this.maxPositionStepIndex = 2;
        this.activePositionStepIndex = 2;
        break;

      case 'ContractClientID':
        this.maxPositionStepIndex = 3;
        this.activePositionStepIndex = 3;
        break;

      case 'ContractResourceID':
        this.maxPositionStepIndex = 5;
        this.activePositionStepIndex = 5;
        break;

      case null:
        this.maxPositionStepIndex = 5;
        this.activePositionStepIndex = 5;
        break;

    }

    if (pre.Status == 'abgeschlossen') {
      this.maxPositionStepIndex = 5;
      this.activePositionStepIndex = 5;
    }



    this.newPreAssignmentDialog = true;


    this.xrmServiceV2.getDataForProjectStep().subscribe((data) => {

      sessionStorage.setItem('otc_actual_project', JSON.stringify(data.all_projects.find((element) => element.LeadID == this.actual_lead.LeadID)));

    });

  }

  // Function to find the first non-null property from a specific key to the right
  findFirstNonNullPropertyFromKey(obj: any, startingKey: string): string | null {

    // Properties to keep in the sliced object
    const propertiesToKeep = ['PreassignmentID', 'ContractOfferID', 'PositionID', 'AssignmentID', 'ContractClientID', 'ContractResourceID'];

    // Create a new object with only the specified properties
    const slicedRowData = {};
    for (const key of propertiesToKeep) {
      slicedRowData[key] = obj[key];
    }

    const keys = Object.keys(slicedRowData).reverse();
    const startIndex = keys.indexOf(startingKey);

    if (startIndex !== -1) {
      // const keysToCheck = keys.slice(startIndex).reverse(); // Start from the specified key and reverse the order
      for (const key of keys) {
        if (obj[key] !== null) {
          return key; // Return the first non-null property to the right of the starting key
        }
      }
    }

    return null; // Return null if the starting key is not found or all properties are null
  }



  newPreAssignment() {

    this.actual_lead.Prolongation = 'false';

    let storedLead = sessionStorage.getItem('otc_actual_lead');
    if (!storedLead) {

      this.newPreAssignmentConfirmation = true;

    } else {

      this.xrmServiceV2.createPreassignment(this.actual_lead).subscribe((response) => {
        if (response.result == 'success') {
          //this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

          const created_preassignment = response.newlyCreatedObject;

          created_preassignment
            ? sessionStorage.setItem('otc_actual_preassignment', JSON.stringify(created_preassignment))
            : sessionStorage.removeItem('otc_actual_preassignment');

          sessionStorage.removeItem('otc_actual_position');
          sessionStorage.removeItem('otc_actual_assignment');
          sessionStorage.removeItem('otc_actual_contract_client');
          sessionStorage.removeItem('otc_actual_contract_offer');
          sessionStorage.removeItem('otc_actual_contract_resource');

          this.newPreAssignmentDialog = true;
          this.activePositionStepIndex = 0;
          this.maxPositionStepIndex = 0;

        }
        if (response.result == 'error') {
          this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message, key: 'lead_toast' });
        }
      });
    }
  }

  async confirmation_preassign(choice: boolean) {

    if (choice) {

      await this.saveLead();  // Wait for saveLead() to complete before proceeding

      sessionStorage.removeItem('otc_actual_position');

      this.newPreAssignmentDialog = true;
      this.activePositionStepIndex = 0;
      this.maxPositionStepIndex = 0;
    }

    this.newPreAssignmentConfirmation = false;
  }

  show_offer_details() {

    if (this.state_offer_value) {

      this.actual_contract_offer = new Contract();
      this.activeIndex = 1;
      this.newPreAssignmentConfirmation = false;

    }

  }

  selectBusinesspartnerContact(data) {
    this.contact = data;

    this.contact = this.all_contacts_raw.find(
      (element) => element.ContactID == data.ContactID
    );

    this.contact
      ? sessionStorage.setItem('otc_actual_businesspartner_contact', JSON.stringify(this.contact))
      : sessionStorage.removeItem('otc_actual_businesspartner_contact');


    this.businesspartner = this.all_bps.find(element => element.BusinesspartnerID == this.contact.BusinesspartnerID);

    if (this.all_locations.find(element => element.BusinesspartnerID == this.businesspartner.BusinesspartnerID) == null) {
      this.no_bp_location = true;
    }
    else {
      this.no_bp_location = false;
    }

    this.all_contacts = this.all_contacts_raw.filter(
      (element) => element.BusinesspartnerID == this.businesspartner.BusinesspartnerID
    );

    this.businesspartner
      ? sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.businesspartner))
      : sessionStorage.removeItem('otc_actual_businesspartner');

  }


  selectBusinesspartner() {

    this.businesspartner
      ? sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.businesspartner))
      : sessionStorage.removeItem('otc_actual_businesspartner');

    console.log(this.all_contacts_raw);

    if (this.all_locations.find(element => element.BusinesspartnerID == this.businesspartner.BusinesspartnerID) == null) {
      this.no_bp_location = true;
    }
    else {
      this.no_bp_location = false;
    }

    this.all_contacts = this.all_contacts_raw.filter(
      (element) => element.BusinesspartnerID == this.businesspartner.BusinesspartnerID
    );

    if (this.all_contacts.length > 0) {

      this.contact = this.all_contacts[0];

      this.contact
        ? sessionStorage.setItem('otc_actual_businesspartner_contact', JSON.stringify(this.contact))
        : sessionStorage.removeItem('otc_actual_businesspartner_contact');

    }

    if (this.all_locations.find(element => element.BusinesspartnerID == this.businesspartner.BusinesspartnerID) == null) {
      this.no_bp_location = true;
    }
    else {
      this.no_bp_location = false;
    }

  }

  // navigateToBP(){

  //   this.appXrmLeadToOrderComponent.activeStepIndex = 0;

  // }


  checkPreAssignmentStatus(): boolean {

    if (this.all_preassignments && this.all_preassignments.length > 0) {

      const hasAbgeschlossen = this.all_preassignments.some(element => element.Status === "abgeschlossen");
      const allValidStatus = this.all_preassignments.every(element => element.Status === "Absage" || element.Status === "abgeschlossen" || element.Status === "0");

      return !(hasAbgeschlossen && allValidStatus);

    } else {
      return true;
    }
  }

  won_lost(value) {

    console.log(value)

    if (value) {
      this.actual_lead.Probability = 100;

    }
    else {
      this.actual_lead.Probability = 0
    }

    console.log(this.actual_lead.Probability)

  }

  //-------------------------------------------------------------------------------------------------------------------

  loadStoredData() {

    let storedBusinessPartner = sessionStorage.getItem('otc_actual_businesspartner');

    console.log(storedBusinessPartner);

    if (storedBusinessPartner) {
      this.businesspartner = this.all_bps.find(element => element.BusinesspartnerID == JSON.parse(storedBusinessPartner).BusinesspartnerID);

      if (this.all_locations.find(element => element.BusinesspartnerID == this.businesspartner.BusinesspartnerID) == null) {
        this.no_bp_location = true;
      }
      else {
        this.no_bp_location = false;
      }

      this.all_contacts = this.all_contacts_raw.filter(
        (element) => element.BusinesspartnerID == this.businesspartner.BusinesspartnerID
      );

      if (this.all_contacts.length > 0) {

        this.contact = this.all_contacts[0];

        this.contact
          ? sessionStorage.setItem('otc_actual_businesspartner_contact', JSON.stringify(this.contact))
          : sessionStorage.removeItem('otc_actual_businesspartner_contact');

      }


    } else {
      this.businesspartner = new BusinesspartnerV2();
    }

    //Set Contact
    let storedBusinessPartnerContact = sessionStorage.getItem('otc_actual_businesspartner_contact');


    if (storedBusinessPartnerContact) {
      //TODO Entscheiden: Wenn BP-Contact ausgewählt, andere contacts filtern oder BP falls nötig ändern
      //Filter Contacts
      this.contact = JSON.parse(storedBusinessPartnerContact);

      // this.all_contacts = this.all_contacts.filter(
      //   (element) => element.BusinesspartnerID == this.contact.BusinesspartnerID
      // );

      // if (this.all_locations.find(element => element.BusinesspartnerID == this.contact.BusinesspartnerID) == null) {
      //   this.no_bp_location = true;
      // }
      // else {
      //   this.no_bp_location = false;
      // }

    } else {
      this.contact = new ContactV2();
    }

    //Set Lead
    let storedLead = sessionStorage.getItem('otc_actual_lead');

    if (storedLead) {
      this.leadDetailCard = false;
      this.selectLead(JSON.parse(storedLead));
    } else {
      this.actual_lead = new LeadV2();
      this.actual_lead.LeadManagerID = this.current_user.PersonID;
      this.actual_lead.Probability = this.probabilities[0].Value;
      this.leadDetailCard = true;
    }
    this.loading = false;
    this.table_loading = false;
  }

  // In the component code
  searchForLead(search_text: string) {
    this.suggestions_leads = this.all_leads.filter((element) => element.Title.includes(search_text));
  }

  selectLead(data) {

    this.actual_lead = data;

    this.actual_lead.ContactID = this.contact.ContactID;

    this.actual_lead.Probability = Number(this.actual_lead.Probability);

    this.LeadName = this.actual_lead.Title;
    this.actual_lead
      ? sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.actual_lead))
      : sessionStorage.removeItem('otc_actual_lead');

  }

  openLeadDetailCard(lead: LeadV2) {
    if (lead) {
      this.actual_lead = lead;
    } else {
      this.actual_lead = new LeadV2();
    }
    this.leadDetailCard = true;
  }

  closeLeadDetailCard() {
    this.leadDetailCard = false;
  }

  abortChanges() {
    this.location.back();
    this.actual_lead = new LeadV2();
    this.closeLeadDetailCard();
  }

  saveAndSelectLead(add_offer: Boolean) {
    this.save_loading = true;

    if (!add_offer) {
      if (this.actual_lead.LeadID != null) {
        this.xrmServiceV2.editLead(this.actual_lead).subscribe((response) => {
          if (response.result == 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Information',
              detail: response.message,
            });

            this.selected_lead
              ? sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.selected_lead))
              : sessionStorage.removeItem('otc_actual_lead');

            this.selected_lead = this.actual_lead;
            this.closeLeadDetailCard();
          }
          if (response.result == 'error')
            this.messageService.add({
              severity: 'info',
              summary: 'Information',
              detail: response.message,
            });
          this.save_loading = false;
        });
      } else {
        this.xrmServiceV2.createLead(this.actual_lead).subscribe((response) => {
          if (response.result == 'success') {

            console.log(response);

            this.actual_lead = response.newlyCreatedObject;
            this.selected_lead = this.actual_lead;

            this.selected_lead
              ? sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.selected_lead))
              : sessionStorage.removeItem('otc_actual_lead');

            this.router.navigate(['/xrm/edit-lead2order'], {
              queryParams: { stringValue: 'lead', booleanValue: false, edit: this.actual_lead.LeadID }
            });

            this.messageService.add({
              severity: 'success',
              summary: 'Information',
              detail: response.message,
            });

            this.closeLeadDetailCard();
          }
          if (response.result == 'error')
            this.messageService.add({
              severity: 'info',
              summary: 'Information',
              detail: response.message,
            });

          this.save_loading = false;
        });
      }
    } else {
      this.selected_lead
        ? sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.selected_lead))
        : sessionStorage.removeItem('otc_actual_lead');

      this.openOfferDialog();
    }
  }

  goStepBack() {
    //this.appXRMDashboard.openEditDialog('contact', false);

    this.router.navigate(['/xrm/new-lead2order'], {
      queryParams: { stringValue: 'contact', booleanValue: false }
    });

  }

  //Diolag for Offer
  openOfferDialog() {
    this.displayOfferDialog = true;
    this.actual_contract_offer = new Contract();
    // this.contractsNavigationComponent.openNewFromStepper('Angebote', 3, this.actual_lead);
  }

  displayOfferDialog: boolean = false;
  dialogHeader: string = 'Example Dialog';
  dialogContent: string = 'This is a shared dialog';
  contracttype: string = 'Angebote';
  contractSubCategoryID: number = 3;
  contract_is_created = false;

  contractIsCreated(event: boolean) {
    this.contract_is_created = event;
  }

  closeDialog(event: boolean) {
    this.displayOfferDialog = event;

  }

  onHideSidebar() {

    this.confirmationService.confirm({
      message: 'Möchtest du evtl. durchgeführte Änderungen speichern?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      icon: 'pi pi-question',
      accept: () => {
        this.table_loading = true;
        this.saveAndDestroy();

        const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

        this.xrmServiceV2.getPreassignments(storedLead.LeadID).subscribe((data) => {
          this.all_preassignments = data;
          this.table_loading = false;
        })

        // this.xrmServiceV2.getDataForLeadStep().subscribe((data) => {

        //   this.all_preassignments = data.all_preassignments.filter(element => element.LeadID == this.actual_lead.LeadID);
        //   this.table_loading = false;
        // });
      },
      reject: () => {
        this.table_loading = true;
        if (this.checkSessionStorageItemsEmpty()) {

          const pre = JSON.parse(sessionStorage.getItem('otc_actual_preassignment'));

          this.xrmServiceV2.deletePreAssignment(pre.PreassignmentID).subscribe(result => {
            if (result.result === 'success') {
              const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

              this.xrmServiceV2.getPreassignments(storedLead.LeadID).subscribe((data) => {
                this.all_preassignments = data;
                this.table_loading = false;
              })

            } else {
              this.messageService.add({ severity: 'error', summary: 'Fehler', detail: result.result, life: 3000, key: 'lead_toast' });
            }
            this.table_loading = false;
          });

        } else {

          const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

          this.xrmServiceV2.getPreassignments(storedLead.LeadID).subscribe((data) => {
            this.all_preassignments = data;
            this.table_loading = false;
          })

        }
      }
    });
  }

  checkSessionStorageItemsEmpty(): boolean {
    const itemsToCheck = [
      'otc_actual_position',
      'otc_actual_assignment',
      'otc_actual_contract_client',
      'otc_actual_contract_offer',
      'otc_actual_contract_resource'
    ];

    return itemsToCheck.every(item => {
      const storedValue = sessionStorage.getItem(item);
      return storedValue === null || storedValue === '';
    });
  }

  saveAndDestroy() {
    // Subscribe to the save$ observable, take only the first emitted value, then unsubscribe
    this.sharedService.save$.pipe(take(1)).subscribe(() => {
      // Perform the save operation
      // ... your save logic here

      // The subscription will automatically be completed after this block

      this.ngOnInit();

      const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

      this.xrmServiceV2.getPreassignments(storedLead.LeadID).subscribe((data) => {
        this.all_preassignments = data;
        this.table_loading = false;
      })

    });

    // Trigger the save
    this.sharedService.triggerSave();

  }

  createProject() {

    this.actual_lead.Probability = 100;

    this.save_loading = true;

    this.xrmServiceV2.createProject(this.actual_lead).subscribe((response) => {

      if (response.result == 'success') {

        this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message, });
        this.save_loading = false;
        this.router.navigate(['/xrm/reports/bp-detail/' + this.actual_lead.BusinesspartnerID]);

      }
      if (response.result == 'error') {
        this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message, });
        this.save_loading = false;
      }
    });

  }


  deletePreAssignmentConfirmation(event: Event, pre) {

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      message: 'Möchtest du das Pre-Assingment löschen?',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirm-delete',
      accept: () => {
        this.table_loading = true;
        this.xrmServiceV2.deletePreAssignment(pre.PreassignmentID).subscribe(result => {
          if (result.result === 'success') {
            this.messageService.add({ severity: 'success', summary: 'Bestätigt', detail: 'Pre-Assignment gelöscht', life: 3000, key: 'lead_toast' });

            const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

            this.xrmServiceV2.getPreassignments(storedLead.LeadID).subscribe((data) => {
              this.all_preassignments = data;
              this.table_loading = false;
            })

          } else {
            this.messageService.add({ severity: 'error', summary: 'Fehler', detail: result.result, life: 3000 });
            this.table_loading = false;
          }

        });
      },
      reject: () => {

      }
    });

  }

  clonedPreSales: {} = {};
  presales;
  preSalesPersonDialog;
  actual_presale = {};

  //-----------------------------Presales----------------------------------------------

  newPresalesPerson() {

    this.preSalesPersonDialog = true;

  }

  deletePresalesPerson(presale) {

    this.table_loading = true;

    this.xrmServiceV2.deletePreSales(presale.PresalesID).subscribe((response) => {
      if (response.result === 'success') {
        this.messageService.add({ severity: 'success', summary: 'Bestätigt', detail: 'Pre-Sale gelöscht', life: 3000 });

        const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

        this.xrmServiceV2.getPresales(storedLead.LeadID).subscribe((data) => {
          this.presales = data;
          this.table_loading = false;
        })

      } else if (response.result === 'error') {
        this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });
        this.table_loading = false;
      }

    });

  }

  onRowEditInit(presale) {
    this.clonedPreSales[presale.PresalesID as string] = { ...presale };
  }

  onRowEditSave(presale) {

    this.table_loading = true;

    if (presale.PresalesID == null) {

      presale.LeadID = this.actual_lead.LeadID;

      this.xrmServiceV2.createPreSales(presale).subscribe((response) => {
        if (response.result === 'success') {
          this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

          const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

          this.xrmServiceV2.getPresales(storedLead.LeadID).subscribe((data) => {
            this.presales = data;
            this.table_loading = false;
          })


        } else if (response.result === 'error') {
          this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });
          this.table_loading = false;
        }

      });
    }
    else {

      this.xrmServiceV2.editPreSales(presale).subscribe((response) => {
        if (response.result === 'success') {
          this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

          const storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

          this.xrmServiceV2.getPresales(storedLead.LeadID).subscribe((data) => {
            this.presales = data;
            this.table_loading = false;
          })

        } else if (response.result === 'error') {
          this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });
          this.table_loading = false;
        }
      });
    }
    this.preSalesPersonDialog = false;
    //this.messageService.add({ severity: 'success', summary: 'Bestätigt', detail: 'Pre-Sale editiert', life: 3000, key: 'lead_toast' });
  }

  onRowEditCancel(presale, index: number) {
    this.presales[index] = this.clonedPreSales[presale.PresalesID as string];
    delete this.clonedPreSales[presale.PresalesID as string];
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Businesspartner } from '../model/xrm/businesspartner';
import { Contact } from '../model/xrm/contact';
import { BusinesspartnerV2 } from '../model/xrm/V2/businesspartnerV2';
import { ContactV2 } from '../model/xrm/V2/contactV2';
import { LeadV2 } from '../model/xrm/V2/leadV2';
import { ProjectV2 } from '../model/xrm/V2/projectV2';
import { PositionV2 } from '../model/xrm/V2/positionV2';
import { Assignment } from '../model/xrm/assignment';
import { Contract } from '../model/businessdivision/contract';
import { XrmDashboardComponent } from 'src/app/components/xrm/xrm-dashboard/xrm-dashboard.component';
import { XRMActionV2 } from '../model/xrm/V2/actionV2';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class XRMServiceV2 {
  constructor(private http: HttpClient) { }


  //lead optimizing

  getEmployees(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/employees`);
  }

  getLeads(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/leads`);
  }

  getContacts(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/contacts`);
  }

  getLocations(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/locations`);
  }

  getBusinessPartners(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/bps`);
  }

  getPreassignments(leadId: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/preassignments/${leadId}`);
  }

  getPresales(leadId: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/presales/${leadId}`);
  }

  //position optimizing

  getPositions(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/positions`);
  }

  getRecruiters(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/recruiters`);
  }

  getSpotlights(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/spotlights`);
  }

  //assignment optimizing

  getConsultants(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/consultants`);
  }

  getAssignments(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/assignments`);
  }

  //contract optimizing

  getEPS(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/eps`);
  }

  getPSPElements(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/psp-elements`);
  }

  getContracts(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/xrm/contracts`);
  }

  getDataForContractOfferStep(LeadID) {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/contract-offer/` + LeadID);
  }

  getDocsForContractStep(ContractMainID) {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/contract-client/docs/` + ContractMainID);
  }

  //OLD

  getDataForContractsStep() {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/contracts`);
  }
  

  //V2
  getDataForBusinesspartnerStep() {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/businesspartner`);
  }

  getDataForBusinesspartnerByID(BusinesspartnerID) {
    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/businesspartner/` + BusinesspartnerID);
  }

  getDataForBusinesspartnerStepV2(params?: any) {
    //return this.http.get<any>(`${environment.apiUrl}/otc/stepper/businesspartner`, { params: params }).toPromise();

    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/businesspartner`, { params: params });
    
  }

  getSalesRecruiters() {
    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/sales-recruiters`);
  }

  getLocationsByBusinesspartnerID(BusinesspartnerID) {
    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/locations/` + BusinesspartnerID);
  }

  getDataForBusinesspartnerReport(BusinesspartnerID) {
    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/report/businesspartner/` + BusinesspartnerID);
  }

  getDataForBusinesspartnerContactStep() {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/businesspartnerContact`);
  }

  getDataForLeadToOrderStep(LeadID) {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/lead-to-order/` + LeadID);
  }

  getDataForProjectListStep() {
    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/report/project-list`);
  }

  getDataForConsultantListStep() {
    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/report/consultant-list`);
  }
  
  getDataForLeadStep() {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/lead`);
  }

  getDataForAssignmentStep() {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/assignment`);
  }

  getDataForProjectStep() {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/project`);
  }

  getDataForPositionStep() {
    return this.http.get<any>(`${environment.apiUrl}/otc/stepper/position`);
  }
  
  getDataForEditPreAssignment(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/v2/otc/stepper/preassignment-edit/`+ id);
  }


  createBusinesspartner(businesspartner: BusinesspartnerV2) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/businesspartner`, businesspartner);
  }

  createLocation(location) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/location`, location);
  }

  createBusinesspartnerContact(businesspartnerContact: ContactV2) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/businesspartnerContact`, businesspartnerContact);
  }

  createConsultant(consultant) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/consultant`, consultant);
  }

  editConsultant(consultant) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/consultant`, consultant);
  }

  createLead(lead: LeadV2) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/lead`, lead);
  }

  createProject(lead: LeadV2) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/project`, lead);
  }

  createPosition(position: PositionV2) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/position`, position);
  }

  createAssignment(assignment: Assignment) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/assignment`, assignment);
  }

  createClientContract(contract: Contract) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/client-contract`, contract);
  }

  createAction(action: XRMActionV2) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/action`, action);
  }

  deleteAction(ActionID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/v2/delete/action/` + ActionID);
  }

  createPreSales(presales){
    return this.http.post<any>(`${environment.apiUrl}/v2/create/presales`, presales);
  }

  editPreSales(presales){ 
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/presales`, presales);
  }

  deletePreSales(PresalesID: number) {  
    return this.http.delete<any>(`${environment.apiUrl}/v2/delete/presales/` + PresalesID);
  }

  editBusinesspartner(businesspartner: BusinesspartnerV2) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/businesspartner`, businesspartner);
  }

  editLocation(location) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/location`, location);
  }

  editBusinesspartnerContact(businesspartnerContact: ContactV2) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/businesspartnerContact`, businesspartnerContact);
  }

  editLead(lead) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/lead`, lead);
  }

  editProject(project: ProjectV2) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/project`, project);
  }

  editPosition(position: PositionV2) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/position`, position);
  }

  editAssignment(assignment: Assignment) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/assignment`, assignment);
  }

  editAction(action: XRMActionV2) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/action`, action);
  }


  editClientContract(contract: Contract) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/contract-client`, contract);
  }

  editResourceContract(contract: Contract) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/contract-resource`, contract);
  }


  editOfferContract(contract: Contract) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/contract-offer`, contract);
  }


  deletePreAssignment(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/v2/delete/preassignment/` + id);
  }

  editPreAssignment(pre) {
    return this.http.put<any>(`${environment.apiUrl}/v2/edit/preassignment`, pre);
  }

  createPreassignment(lead) {
    return this.http.post<any>(`${environment.apiUrl}/v2/create/preassignment`, lead);
  }



  //reports

  getDataForSalesPersonReport(PersonID) {
    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/report/sales/` + PersonID);
  }

  getDataForProjectReport(ProjectID) {
    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/report/project-detail/` + ProjectID);
  }

  getDataForMissingDataReport() {

    return this.http.get<any>(`${environment.apiUrl}/v2/xrm/report/missing-data`);
  }
  
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { User, EmployeeRoles } from "../model";

import { MsalService } from "@azure/msal-angular";
import { LoginService } from "./login.service";
import { Router } from "@angular/router";
import { Employee } from "../model/employee";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject = new BehaviorSubject<User>(this.getStorageItem('currentUser'));
  public currentUser = this.currentUserSubject.asObservable();

  private currentEmployeeSubject = new BehaviorSubject<Employee>(this.getStorageItem('actualEmployee'));
  public currentEmployee = this.currentEmployeeSubject.asObservable();

  private currentRoleSubject = new BehaviorSubject<EmployeeRoles>(this.getStorageItem('currentRoles'));
  public currentRoles = this.currentRoleSubject.asObservable();

  public azureLoginSuccess = false;
  public profile;

  constructor(
    private loginService: LoginService,
    private http: HttpClient, 
    private authService: MsalService,
    private router: Router) 
    {}

  private getStorageItem(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentEmployeeValue(): Employee {
    return this.currentEmployeeSubject.value;
  }

  public get currentRolesValue(): EmployeeRoles {
    return this.currentRoleSubject.value;
  }

  logout() {
    if (this.currentUserValue?.PersonType) {
      if(this.currentUserValue.PersonType === 'intern' || this.currentUserValue.PersonType === 'admin') {
        this.azureLoginSuccess = false;
        sessionStorage.clear();
        this.authService.logout();
      }
    }
    sessionStorage.clear();
    this.currentUserSubject.next(null);
    this.http.get<any>(`${environment.apiUrl}/logout`).subscribe();
    this.router.navigate(['auth/login']);
  }


  azure_login_new(accessToken) {
    return this.http
      .post<any>(`${environment.apiUrl}/loginJWT`, { token: accessToken })
      .pipe(
        map(x => {
          if (x.user) {
            this.loginService.setUserLoggedIn(true);
            sessionStorage.setItem('currentUser', JSON.stringify(x.user));
            sessionStorage.setItem('actualEmployee', JSON.stringify(x.user));
            this.currentUserSubject.next(x.user);
  
            const roleIDs = x.roles.map(x => x.RoleID);
            sessionStorage.setItem('roleID', JSON.stringify(roleIDs));
          }
          return x.user;
        })
      );
  }


  azure_login(payload) {
    const token = payload["idToken"]["rawIdToken"];
    return this.http
      .post<any>(`${environment.apiUrl}/loginJWT`, { token })
      .pipe(
        map(x => {
          if (x.user) {
            this.loginService.setUserLoggedIn(true);
            sessionStorage.setItem('currentUser', JSON.stringify(x.user));
            sessionStorage.setItem('actualEmployee',JSON.stringify(x.user));
            this.currentUserSubject.next(x.user);

            const roleIDs = x.roles.map(x => x.RoleID);
            sessionStorage.setItem('roleID', JSON.stringify(roleIDs));
          }
          return x.user;
        })
      );
  }

  getAzureProfile() {
    return this.http.get<any>('https://graph.microsoft.com/v1.0/me').subscribe();
  }
}

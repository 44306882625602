import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { PositionV2 } from 'src/app/shared/model/xrm/V2/positionV2';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { LeadCrudComponent } from '../lead-crud/lead-crud.component';
import { ProjectV2 } from 'src/app/shared/model/xrm/V2/projectV2';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, Subject, forkJoin, takeUntil } from 'rxjs';
import { Employee } from 'src/app/shared/model/employee';
import { ProcessStepperPositionComponent } from '../process-stepper-position/process-stepper-position.component';
import { ReportProjectDetailComponent } from 'src/app/components/xrm/reports/report-project/report-project-detail/report-project-detail.component';
import { ReportMissingdataComponent } from 'src/app/components/xrm/reports/report-missingdata/report-missingdata.component';

@Component({
  selector: 'app-position-crud',
  templateUrl: './position-crud.component.html',
  styleUrls: ['./position-crud.component.css']
})
export class PositionCrudComponent implements OnInit {


  contract_types: Array<{ name: string }> = [{ name: 'ANÜ' }, { name: 'Dienstvertrag' }, { name: 'Werkvertrag' }, { name: 'Personalvermittlung' }];

  remote_selection: Array<{ name: string }> = [{ name: "0%" }, { name: "20%" }, { name: "40%" }, { name: "60%" }, { name: "80%" }, { name: "100%" }];

  hours_day: Array<{ name: string }> = [{ name: "8" }, { name: "8,5" }, { name: "9" }];

  volume_ftes: Array<{ name: string }> = [{ name: "1,0" }, { name: "0,9" }, { name: "0,8" }, { name: "0,75" }, { name: "0,7" }, { name: "0,6" }, { name: "0,5" }, { name: "0,4" }, { name: "0,3" }, { name: "0,25" }, { name: "0,2" }, { name: "0,1" }]

  actual_position: PositionV2 = new PositionV2();

  actual_project: ProjectV2;

  all_businesspartners: BusinesspartnerV2[];
  suggestions_businesspartner: BusinesspartnerV2[];
  selected_businesspartner: BusinesspartnerV2 = new BusinesspartnerV2();

  BusinesspartnerName: String;

  all_positions: PositionV2[];
  selected_position;
  suggestions_positions: PositionV2[];
  PositionTitle: String;
  PositionStartDate: Date;
  PositionEndDate: Date;

  position_takeover = false;
  takeover_selected;

  save_loading = false;

  data_loading = true;

  stored_preassignment;

  recruiters;
  spotlights;

  DeliveryResponsible: Employee;
  extension;

  private unsubscribeAll = new Subject<void>();

  constructor(
    private appLeadCrudComponent: LeadCrudComponent,
    private appProcessStepperPositionComponent: ProcessStepperPositionComponent,
    private appReportProjectDetailComponent: ReportProjectDetailComponent,
    private appReportMissingdataComponent: ReportMissingdataComponent,
    private xrmServiceV2: XRMServiceV2,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sharedService: SharedService
  ) {
    this.sharedService.save$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => this.save_position(true));

  }

  ngOnInit() {

    switch (this.appProcessStepperPositionComponent.state) {
      case 'lead':
        this.appLeadCrudComponent.activePositionStepIndex = 1;

        break;
      case 'extension':
        this.appReportProjectDetailComponent.activePositionStepIndex = 1;

        break;

      case 'edit':
        this.appReportMissingdataComponent.activePositionStepIndex = 1;
        this.appReportProjectDetailComponent.activePositionStepIndex = 1;

        break;
    }


    // if (this.appProcessStepperPositionComponent.extension == true) {

    //   this.appReportProjectDetailComponent.activePositionStepIndex = 1;
    //   this.extension = true;

    // }
    // else {

    //   this.appLeadCrudComponent.activePositionStepIndex = 1;
    //   this.extension = false;
    // }

    const apiCalls: { [key: string]: Observable<any> } = {
      all_positions: this.xrmServiceV2.getPositions(),
      all_businesspartners: this.xrmServiceV2.getBusinessPartners(),
      recruiters: this.xrmServiceV2.getRecruiters(),
      spotlights: this.xrmServiceV2.getSpotlights(),
    };

     // Make all API calls in parallel
     forkJoin(apiCalls).subscribe({
      next: (results: any) => {
        this.all_positions = results.all_positions;
        this.all_businesspartners = results.all_businesspartners;
        this.recruiters = results.recruiters;
        this.spotlights = results.spotlights;

        this.loadStoredData();
        this.data_loading = false;
      },
      error: (error) => {
        console.error('Error fetching data:', error);
        this.data_loading = false;
        // Handle error (e.g., show error message)
      }
    });


    // this.xrmServiceV2.getDataForPositionStep().subscribe((data) => {

    //   this.all_positions = data.all_positions;
    //   this.all_businesspartners = data.all_businesspartners;
    //   this.recruiters = data.recruiters;
    //   this.spotlights = data.spotlights;

    //   this.loadStoredData();

    // });

  }

  // ngOnDestroy() {

  //   this.confirmationService.confirm({
  //     message: 'Möchtest du mögliche Änderungen speichern?',
  //     header: 'Confirmation',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.save_position();
  //     },
  //     reject: () => {
  //       sessionStorage.removeItem('otc_actual_position');
  //     }
  //   });



  // }

  loadStoredData() {

    this.stored_preassignment = JSON.parse(sessionStorage.getItem('otc_actual_preassignment'));
    this.actual_position.PreassignmentID = this.stored_preassignment.PreassignmentID;


    let storedProject = JSON.parse(sessionStorage.getItem('otc_actual_project'));
    if (storedProject) {

      this.actual_project = storedProject;

    }

    let storedContract = JSON.parse(sessionStorage.getItem('otc_actual_contract_offer'));
    if (storedContract) {

      this.PositionStartDate = new Date(storedContract.ContractStart);
      this.PositionEndDate = new Date(storedContract.ContractEnd);

    }

    //Set Position
    let storedPosition = sessionStorage.getItem('otc_actual_position');

    let storedBusinessPartnerContact = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner_contact'));

    if (storedBusinessPartnerContact) {
      this.all_positions = this.all_positions.filter(element => element.BusinesspartnerID == storedBusinessPartnerContact.BusinesspartnerID);
    }

    if (storedPosition) {

      this.actual_position = JSON.parse(storedPosition);
      this.PositionTitle = this.actual_position.PositionTitle;

      this.actual_position.ProjectID = this.actual_project.ProjectID;
      this.actual_position.LeadID = this.actual_project.LeadID;

      this.takeover_selected = Boolean(this.actual_position.Takeover);

      if (this.actual_position.ZdlID)
        this.selected_businesspartner = this.all_businesspartners.find(element => element.BusinesspartnerID == this.actual_position.ZdlID);


      if (this.appProcessStepperPositionComponent.extension == true) {

        console.log("extension true");
        this.PositionStartDate = new Date(this.actual_position.PositionEndDate);
        this.PositionEndDate = new Date();

      } else {

        this.PositionStartDate = new Date(this.actual_position.PositionStartDate);
        this.PositionEndDate = new Date(this.actual_position.PositionEndDate);

      }

      this.DeliveryResponsible = this.recruiters.find(
        (element) => element.PersonID === this.actual_position.DeliveryResponsible
      );

      console.log(this.DeliveryResponsible);


    } else {
      this.actual_position = new PositionV2();
      this.actual_position.ProjectID = this.actual_project.ProjectID;
      this.DeliveryResponsible = this.recruiters.find(element => element.PersonID == 41);
      this.actual_position.ContractType = this.contract_types[1].name;
    }

    this.data_loading = false;
  }

  takeover_position() {

    this.actual_position = this.selected_position;
    this.PositionTitle = this.actual_position.PositionTitle;
    this.actual_position.Takeover = 'true';

    this.actual_position.PositionID = null;
    this.actual_position.ProjectID = this.actual_project.ProjectID;
    this.actual_position.LeadID = this.actual_project.LeadID;

    this.position_takeover = true;

  }

  save_position(next_step) {

    if (next_step) {

      this.actual_position.PreassignmentID = this.stored_preassignment.PreassignmentID;
      this.actual_position.PositionStartDate = new Date(Date.UTC(
        this.PositionStartDate.getFullYear(),
        this.PositionStartDate.getMonth(),
        this.PositionStartDate.getDate()
      ));
      this.actual_position.PositionEndDate = new Date(Date.UTC(
        this.PositionEndDate.getFullYear(),
        this.PositionEndDate.getMonth(),
        this.PositionEndDate.getDate()
      ));
      this.actual_position.DeliveryResponsible = this.DeliveryResponsible.PersonID;

      //this.actual_position.PositionTitle = this.PositionTitle;
      this.actual_position.ZdlID = this.selected_businesspartner.BusinesspartnerID;



      if (this.actual_position.PositionID != null) {

        this.xrmServiceV2.editPosition(this.actual_position).subscribe((response) => {
          if (response.result == 'success') {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

            //this.selected_position = this.actual_position;

            this.actual_position
              ? sessionStorage.setItem('otc_actual_position', JSON.stringify(this.actual_position))
              : sessionStorage.removeItem('otc_actual_position');


            switch (this.appProcessStepperPositionComponent.state) {
              case 'lead':
                this.appLeadCrudComponent.activePositionStepIndex = 2;

                if (this.appLeadCrudComponent.maxPositionStepIndex == 1)
                  this.appLeadCrudComponent.maxPositionStepIndex++;

                break;
              case 'extension':
                this.appReportProjectDetailComponent.activePositionStepIndex = 2;

                if (this.appReportProjectDetailComponent.maxPositionStepIndex == 1)
                  this.appReportProjectDetailComponent.maxPositionStepIndex++;

                break;

              case 'edit':

                this.appReportMissingdataComponent.activePositionStepIndex = 2;
                if (this.appReportMissingdataComponent.maxPositionStepIndex == 1)
                  this.appReportMissingdataComponent.maxPositionStepIndex++;

                this.appReportProjectDetailComponent.activePositionStepIndex = 2;
                if (this.appReportProjectDetailComponent.maxPositionStepIndex == 1)
                  this.appReportProjectDetailComponent.maxPositionStepIndex++;

                break;
            }

          }
          if (response.result == 'error')
            this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

          this.save_loading = false;
          this.unsubscribeAll.next();
          this.unsubscribeAll.complete();
        });
      } else {

        console.log("create position")

        this.xrmServiceV2.createPosition(this.actual_position).subscribe((response) => {
          if (response.result == 'success') {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

            this.actual_position = response.newlyCreatedObject;
            //this.selected_position = this.actual_position;

            this.actual_position
              ? sessionStorage.setItem('otc_actual_position', JSON.stringify(this.actual_position))
              : sessionStorage.removeItem('otc_actual_position');

            // if (this.appProcessStepperPositionComponent.extension == true) {

            //   this.appReportProjectDetailComponent.activePositionStepIndex = 2;

            //   if (this.appReportProjectDetailComponent.maxPositionStepIndex == 1)
            //     this.appReportProjectDetailComponent.maxPositionStepIndex++;

            // }
            // else {

            //   this.appLeadCrudComponent.activePositionStepIndex = 2;

            //   if (this.appLeadCrudComponent.maxPositionStepIndex == 1)
            //     this.appLeadCrudComponent.maxPositionStepIndex++;

            // }

            switch (this.appProcessStepperPositionComponent.state) {
              case 'lead':
                this.appLeadCrudComponent.activePositionStepIndex = 2;

                if (this.appLeadCrudComponent.maxPositionStepIndex == 1)
                  this.appLeadCrudComponent.maxPositionStepIndex++;

                break;
              case 'extension':
                this.appReportProjectDetailComponent.activePositionStepIndex = 2;

                if (this.appReportProjectDetailComponent.maxPositionStepIndex == 1)
                  this.appReportProjectDetailComponent.maxPositionStepIndex++;

                break;

              case 'edit':

                this.appReportMissingdataComponent.activePositionStepIndex = 2;
                if (this.appReportMissingdataComponent.maxPositionStepIndex == 1)
                  this.appReportMissingdataComponent.maxPositionStepIndex++;

                this.appReportProjectDetailComponent.activePositionStepIndex = 2;
                if (this.appReportProjectDetailComponent.maxPositionStepIndex == 1)
                  this.appReportProjectDetailComponent.maxPositionStepIndex++;

                break;
            }

          }
          if (response.result == 'error')
            this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

          this.save_loading = false;
          this.unsubscribeAll.next();
          this.unsubscribeAll.complete();
        });

      }


    } else {

      this.actual_position.PreassignmentID = this.stored_preassignment.PreassignmentID;
      this.actual_position.PositionStartDate = new Date(this.PositionStartDate);
      this.actual_position.PositionEndDate = new Date(this.PositionEndDate);
      this.actual_position.DeliveryResponsible = this.DeliveryResponsible.PersonID;

      //this.actual_position.PositionTitle = this.PositionTitle;
      this.actual_position.ZdlID = this.selected_businesspartner.BusinesspartnerID;

      if (this.actual_position.PositionID != null) {

        this.xrmServiceV2.editPosition(this.actual_position).subscribe((response) => {
          if (response.result == 'success') {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

            //this.selected_position = this.actual_position;

            this.actual_position
              ? sessionStorage.setItem('otc_actual_position', JSON.stringify(this.actual_position))
              : sessionStorage.removeItem('otc_actual_position');

            switch (this.appProcessStepperPositionComponent.state) {
              case 'lead':
                this.appLeadCrudComponent.newPreAssignmentDialog = false;

                break;
              case 'edit':

                console.log("edit");

                this.appReportProjectDetailComponent.sidebarVisible = false;
                this.appReportMissingdataComponent.sidebarVisible = false;

                break;
            }

          }
          if (response.result == 'error')
            this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

          this.save_loading = false;
          this.unsubscribeAll.next();
          this.unsubscribeAll.complete();
        });
      } else {

        console.log("create position")

        this.xrmServiceV2.createPosition(this.actual_position).subscribe((response) => {
          if (response.result == 'success') {
            this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

            this.actual_position = response.newlyCreatedObject;
            //this.selected_position = this.actual_position;

            this.actual_position
              ? sessionStorage.setItem('otc_actual_position', JSON.stringify(this.actual_position))
              : sessionStorage.removeItem('otc_actual_position');


            switch (this.appProcessStepperPositionComponent.state) {
              case 'lead':
                this.appLeadCrudComponent.newPreAssignmentDialog = false;

                break;
              case 'edit':
                this.appReportProjectDetailComponent.sidebarVisible = false;
                this.appReportMissingdataComponent.sidebarVisible = false;

                break;
            }

            // if (this.appProcessStepperPositionComponent.extension == true) {

            //   this.appReportProjectDetailComponent.activePositionStepIndex = 2;

            //   if (this.appReportProjectDetailComponent.maxPositionStepIndex == 1)
            //     this.appReportProjectDetailComponent.maxPositionStepIndex++;

            // }
            // else {

            //   this.appLeadCrudComponent.activePositionStepIndex = 2;

            //   if (this.appLeadCrudComponent.maxPositionStepIndex == 1)
            //     this.appLeadCrudComponent.maxPositionStepIndex++;

            // }

          }
          if (response.result == 'error')
            this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

          this.save_loading = false;
          this.unsubscribeAll.next();
          this.unsubscribeAll.complete();
        });

      }


    }


  }

  searchForBusinesspartner(search_text: string) {
    this.suggestions_businesspartner = this.all_businesspartners.filter((element) =>
      element.BusinesspartnerName.includes(search_text)
    );
  }

  selectBusinesspartner(data) {
    if (data) {
      this.selected_businesspartner = data;
      this.BusinesspartnerName = this.selected_businesspartner.BusinesspartnerName;
    }
  }


  searchForPosition(search_text: string) {
    this.suggestions_positions = this.all_positions.filter((element) => element.PositionTitle.includes(search_text));
  }


  //OLD used for autocomplete
  selectPosition(data) {

    this.actual_position = data;

    this.PositionTitle = this.actual_position.PositionTitle;
    this.actual_position
      ? sessionStorage.setItem('otc_actual_position', JSON.stringify(this.actual_position))
      : sessionStorage.removeItem('otc_actual_position');
  }

  abortChanges() {

    this.appLeadCrudComponent.newPreAssignmentDialog = false;
    //this.appLeadCrudComponent.ngOnInit();

  }

  next_step() {

    if (this.appProcessStepperPositionComponent.extension == true) {

      this.appReportProjectDetailComponent.activePositionStepIndex = 1;

    }
    else {

      this.appLeadCrudComponent.activePositionStepIndex = 1;

    }
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';

@Component({
  selector: 'app-report-missingdata',
  templateUrl: './report-missingdata.component.html',
  styleUrls: ['./report-missingdata.component.css']
})
export class ReportMissingdataComponent implements OnInit {

  filtered_preassignments;
  filtered_finance_preassignments;
  filtered_project_preassignments;

  data_loading = false;

  selected_data;
  dataOptions: any[] = [{ label: 'Finance', value: 'finance' },{ label: 'Project', value: 'project' }];

  constructor(
    private xrmServiceV2: XRMServiceV2,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() {

    this.loadMissingData();
    this.selected_data = 'finance';
  }

  async loadMissingData() {

    this.data_loading = true;

    const result = await firstValueFrom(this.xrmServiceV2.getDataForMissingDataReport());

    if (result.result === 'success') {

      this.filtered_preassignments = result.filtered_finance_preassignments;
      this.filtered_finance_preassignments = result.filtered_finance_preassignments;
      this.filtered_project_preassignments = result.filtered_project_preassignments;

      console.log(this.filtered_preassignments);
      console.log(result.filtered_finance_preassignments);
      console.log(this.filtered_project_preassignments);

      this.data_loading = false;

    } else {

      this.messageService.add({ severity: 'error', summary: 'Information', detail: result.error });
      this.data_loading = false;

    }

  }

  switchData(value) {

    console.log(value);
    console.log(this.filtered_finance_preassignments);
    console.log(this.filtered_project_preassignments);
  
    if (value === 'finance') {
      this.filtered_preassignments = this.filtered_finance_preassignments;
    } else {
      this.filtered_preassignments = this.filtered_project_preassignments;
    }

    console.log(this.filtered_preassignments);

  }

  is_intern;
  maxPositionStepIndex;
  activePositionStepIndex;
  sidebarVisible = false;

  actual_lead;
  actual_contact;
  actual_bp;
  actual_project;

  async onMissingDataSelect(preassignment) {

    console.log(preassignment);

    sessionStorage.setItem('otc_actual_preassignment', JSON.stringify(preassignment));

    this.xrmServiceV2.getDataForLeadToOrderStep(preassignment.LeadID).subscribe((data) => {


      this.actual_lead = data.actual_lead;
      sessionStorage.setItem('otc_actual_lead', JSON.stringify(this.actual_lead));

      this.actual_contact = data.actual_contact;
      sessionStorage.setItem('otc_actual_businesspartner_contact', JSON.stringify(this.actual_contact));

      this.actual_bp = data.actual_bp;
      sessionStorage.setItem('otc_actual_businesspartner', JSON.stringify(this.actual_bp));

      this.actual_project = data.actual_project;
      sessionStorage.setItem('otc_actual_project', JSON.stringify(this.actual_project));

    });

    const x = await firstValueFrom(this.xrmServiceV2.getDataForEditPreAssignment(preassignment.PreassignmentID));

    console.log(x);

    if (x.contract_client) {
      sessionStorage.setItem('otc_actual_contract_client', JSON.stringify(x.contract_client));
    }

    if (x.contract_resource) {
      sessionStorage.setItem('otc_actual_contract_resource', JSON.stringify(x.contract_resource));
    }

    if (x.contract_offer) {
      sessionStorage.setItem('otc_actual_contract_offer', JSON.stringify(x.contract_offer));
    }

    if (x.assignment) {
      sessionStorage.setItem('otc_actual_assignment', JSON.stringify(x.assignment));
    }

    if (x.position) {
      sessionStorage.setItem('otc_actual_position', JSON.stringify(x.position));
    }

    const storedAssignment = x.assignment;

    if (storedAssignment && storedAssignment.ConsultantPersonType == 'extern') {

      console.log(storedAssignment.ConsultantPersonType);

      this.is_intern = false;

    } else {

      this.is_intern = true;

    }

    switch (preassignment.element) {

      case 'Angebot':
        this.activePositionStepIndex = 0;
        break;

      case 'Position':
        this.activePositionStepIndex = 1;
        break;

      case 'Assignment':
        this.activePositionStepIndex = 2;
        break;

      case 'Kundenvertrag':
        this.activePositionStepIndex = 3;
        break;

      case 'Ressourceneinkaufsvertrag':
        this.activePositionStepIndex = 4;
        break;

      default:
        break;
    }

    this.maxPositionStepIndex = 5;

    this.sidebarVisible = true;

  }

  onHideSidebar(event: Event) {

    console.log('hide sidebar');

    sessionStorage.removeItem('otc_actual_position');
    sessionStorage.removeItem('otc_actual_assignment');
    sessionStorage.removeItem('otc_actual_contract_client');
    sessionStorage.removeItem('otc_actual_contract_offer');
    sessionStorage.removeItem('otc_actual_contract_resource');
    sessionStorage.removeItem('otc_actual_preassignment');
    sessionStorage.removeItem('otc_actual_lead');
    sessionStorage.removeItem('otc_actual_businesspartner');
    sessionStorage.removeItem('otc_actual_businesspartner_contact');
    sessionStorage.removeItem('otc_actual_project');

  }

}

import { Routes } from '@angular/router';
import { AuthGuard } from '../guard';

export const content: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: "Mein Dashboard"
    }
  },
  {
    path: 'businessdivision/sales',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/businessdivision/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Vertrieb"
    }
  },
  {
    path: 'businessdivision/finance',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/businessdivision/finance/finance.module').then(m => m.FinanceModule),
    data: {
      breadcrumb: "Finance"
    }
  },
  {
    path: 'businessdivision/technology',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/businessdivision/technology/technology.module').then(m => m.TechnologyModule),
    data: {
      breadcrumb: "Technology"
    }
  },
  // {
  //   path: 'businessdivision/contracts',
  //   canActivate:[AuthGuard],
  //   loadChildren: () => import('../../components/businessdivision/contract-administration/contractadministration.module').then(m => m.ContractAdministrationModule),
  //   data: {
  //     breadcrumb: "Vertragswesen"
  //   }
  // },
  {
    path: 'school',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/school/school.module').then(m => m.SchoolModule),
    data: {
      breadcrumb: "School"
    }
  },
  {
    path: 'xrm',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/xrm/xrm.module').then(m => m.XrmModule),
    data: {
      breadcrumb: "XRM"
    }
  },
  {
    path: 'teamleitung',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/teamleitung/teamleitung.module').then(m => m.TeamleitungModule),
    data: {
      breadcrumb: "Teamleitung"
    }
  },
  {
    path: 'service-desk',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/service-desk/service-desk.module').then(m => m.ServiceDeskModule),
    data: {
      breadcrumb: "Service-Desk"
    }
  },

  {
    path: 'performance-evaluation',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/performance-evaluation/performance-evaluation.module').then(m => m.PerformanceEvaluationModule),
    data: {
      breadcrumb: "Performance Evaluation"
    }
  },
  {
    path: 'my-client',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/my-client/my-client.module').then(m => m.MyClientModule),
    data: {
      breadcrumb: "MyClient"
    }
  },

]; 
export class LeadV2 {
  LeadID: number;
  Title: String;
  ContactID: number;
  LeadManagerID: number;
  ExpectedVolume: string;
  Probability: number;
  WeightedVolume: string;
  Status: string;
  Comment: string;
  created_by: number;

  //Helper
  BusinesspartnerID: number;
  BusinesspartnerName: string;
  ContactDisplayName: string;
  LeadManagerName: string;
  Prolongation: string;

  ProjectStatus: String;
  ProjectDescription: String;
  ProjectName: String;


}

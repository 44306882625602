import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services';
import { ContentLayoutComponent } from '../layout/content-layout/content-layout.component';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {

    public RoleIDs
    public currentUser;

    public VisibleAll: boolean = true;
    // public VisibleInfoDesk: boolean = false
    // public VisibleRK: boolean = false
    // public VisibleTechAdmin: boolean = false
    // public VisibleAdminExpenses: boolean = false
    // public VisibleGB: boolean = false
    // public VisibleSales: boolean = false
    // public VisibleContracts: boolean = false

    // public VisibleFinance: boolean = false

    // NEW
    //NEW 
    public Visible = {
        all: false,
        teamleader: false,
        admin: false,
        gehalt: false,
        cpsManager: false,
        crm: false,
        myClient: false,
        away: false,
        pr: false,
        school: false,
        store: false,
        infoDesk: false,
        rk: false,
        techAdmin: false,
        gb: false,
        adminPayroll: false,
        adminSchool: false,
        adminUser: false,
        adminStore: false,
        adminExpenses: false,
        adminOKR: false,
        okr: false,
        adminCandidate: false,
        candidate: false,
        mp: false,
        finance: false,
        contracts: false,
        values: false,
        sales: false,
        technology: false,
        whistleBlower: false,
        hrm: false,
        peopleCulture: false,
        mentorPlus: false,
        userWithoutIntegration: false,
        solutionManager: false,
        skillManagement: false,
        skillManagementAdmin: false
    };


    model: any[];

    constructor(public appMain: ContentLayoutComponent, private authenticationService: AuthenticationService) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
        this.getItems();
    }

    // getItems() {

    //     if (this.RoleIDs != null) {
    //         this.RoleIDs.forEach(element => {

    //             switch (element) {

    //                 //User
    //                 case '103':
    //                     this.VisibleAll = true;
    //                     break;

    //                 //InfoDesk
    //                 case '117':
    //                     this.VisibleInfoDesk = true;
    //                     break;

    //                 //AdminExpenses
    //                 case '124':
    //                     this.VisibleAdminExpenses = true;
    //                     break;

    //                 //TechAdmin
    //                 case '107':
    //                     this.VisibleAll = true;
    //                     this.VisibleTechAdmin = true;
    //                     this.VisibleGB = true;
    //                     this.VisibleSales = true;
    //                     this.VisibleFinance = true;
    //                     this.VisibleContracts = true;
    //                     break;

    //                 case '141':
    //                     this.VisibleGB = true;
    //                     this.VisibleSales = true;
    //                     break;

    //                 case '135':
    //                     this.VisibleGB = true;
    //                     this.VisibleFinance = true;
    //                     break;

    //             }
    //         })
    //     }
    // }

    getItems() {
        const visibilityMapping = {
            '107': 'techAdmin',
            '105': 'admin',
            '103': 'all',
            '104': 'teamleader',
            '111': 'gehalt',
            '112': 'cpsManager',
            '115': 'away',
            '108': 'crm',
            '110': 'pr',
            '113': 'school',
            '116': 'store',
            '117': 'infoDesk',
            '114': 'rk',
            '118': 'adminUser',
            '121': 'adminPayroll',
            '124': 'adminExpenses',
            '119': 'adminSchool',
            '120': 'adminStore',
            '123': 'myClient',
            '125': 'okr',
            '126': 'adminOKR',
            '122': 'mp',
            '133': 'mentorPlus',
            '135': 'finance',
            '136': 'contracts',
            '137': 'values',
            '138': 'adminCandidate',
            '141': 'sales',
            '148': 'technology',
            '127': 'candidate',
            '142': 'whistleBlower',
            '143': 'hrm',
            '147': 'userWithoutIntegration',
            '149': 'solutionManager',
            '150': 'skillManagement',
            '151': 'skillManagementAdmin'
        };

        if (this.RoleIDs != null) {
            
            this.RoleIDs.forEach((element) => {
            const visibilityState = visibilityMapping[element];
            if (visibilityState) {
                this.Visible[visibilityState] = true;
            }
            if (element == '107') {
                for (let prop in this.Visible) {
                  this.Visible[prop] = true;
                }
              }
        });
    }

        console.log(JSON.stringify(this.Visible))
        this.setModel();
    }

    ngOnInit() { }

    setModel() {

        this.model = [
            {
                label: 'Dashboard', icon: 'pi pi-home', visible: this.Visible.all,
                items: [
                    { label: 'Übersicht', icon: 'pi pi-home', routerLink: ['/dashboard/default'], visible: this.Visible.all },
                    { label: 'grandega Strategie', icon: 'pi pi-chart-line', routerLink: ['/dashboard/strategy'], visible: this.Visible.all },
                    { label: 'Meine Auslastung', icon: 'pi pi-clock', routerLink: ['/dashboard/my-workload'], visible: this.Visible.all }
                ]
            },




            {
                label: 'Abwesenheiten', icon: 'pi pi-calendar', visible: this.Visible.away,
                items: [
                    { label: 'Übersicht', icon: 'pi pi-list', url: 'https://tst.grandega-access.de/leave/leave-list', visible: this.Visible.away },
                    { label: 'Neue Abwesenheit', icon: 'pi pi-plus-circle', url: 'https://tst.grandega-access.de/leave/new-leave', visible: this.Visible.away }
                ]
            },
            // {
            //     label: 'Übersicht', icon: 'pi pi-flag', url: 'https://tst.grandega-access.de/covid/new', visible: this.Visible.all
            // },
            {
                label: 'Geschäftsbereich V2', icon: 'pi pi-briefcase', visible: this.Visible.contracts || this.Visible.sales || this.Visible.finance || this.Visible.techAdmin,
                items: [
                    // { label: 'Vertragswesen', icon: 'pi pi-file', routerLink: ['/businessdivision/contracts'], visible: this.Visible.contracts },
                    { label: 'Vertrieb', icon: 'pi pi-chart-line', routerLink: ['/businessdivision/sales/dashboard'], visible: this.Visible.sales },
                    { label: 'Finance', icon: 'pi pi-money-bill', routerLink: ['/businessdivision/finance/dashboard'], visible: this.Visible.finance },
                    { label: 'Technologie', icon: 'pi pi-shield', routerLink: ['/businessdivision/technology/dashboard'], visible: this.Visible.techAdmin },
                    { label: 'Vertragswesen', icon: 'pi pi-file', routerLink: ['/businessdivision/contracts/dashboard'], visible: this.Visible.contracts }
                ]
            },
            {
                label: 'Geschäftsbereich Alt', icon: 'pi pi-briefcase', visible: this.Visible.adminCandidate || this.Visible.finance || this.Visible.mp || this.Visible.hrm || this.Visible.techAdmin || this.Visible.contracts || this.Visible.sales,
                items: [
                    { label: 'Finance', icon: 'pi pi-money-bill', url: 'https://tst.grandega-access.de/geschaeftsbereich/finance/dashboard', visible: this.Visible.finance },
                    { label: 'MP', icon: 'pi pi-star', url: 'https://tst.grandega-access.de/geschaeftsbereich/mp/ma-care/mgmt-list', visible: this.Visible.mp },
                    {
                        label: 'People & Culture', icon: 'pi pi-users', visible: this.Visible.adminCandidate || this.Visible.hrm,
                        items: [
                            { label: 'Bewerber-Mgmt', icon: 'pi pi-user-plus', url: 'https://tst.grandega-access.de/geschaeftsbereich/people-culture/candidates/list', visible: this.Visible.adminCandidate },
                            { label: 'HRM', icon: 'pi pi-users', url: 'https://tst.grandega-access.de/geschaeftsbereich/people-culture/hrm/list', visible: this.Visible.hrm },
                        ]
                    },
                    { label: 'Spotlight-Manager', icon: 'pi pi-star-fill', url: 'https://tst.grandega-access.de/geschaeftsbereich/spotlight-manager/dashboard', visible: this.Visible.solutionManager },
                    {
                        label: 'Technologie', icon: 'pi pi-shield', visible: this.Visible.techAdmin,
                        items: [
                            { label: 'Assets', icon: 'pi pi-list', url: 'https://tst.grandega-access.de/geschaeftsbereich/technology/dashboard', visible: this.Visible.techAdmin },
                            { label: 'Benutzerverwaltung', icon: 'pi pi-users', url: 'https://tst.grandega-access.de/admin/usr-mgmt/start', visible: this.Visible.techAdmin },
                            { label: 'MondayVideos', icon: 'pi pi-list', url: 'https://tst.grandega-access.de/geschaeftsbereich/technology/upload/documents/mondayVideo', visible: this.Visible.techAdmin },
                        ]

                    },
                    { label: 'Vertragswesen', icon: 'pi pi-file', url: 'https://tst.grandega-access.de/geschaeftsbereich/contracts/dashboard', visible: this.Visible.contracts },
                    { label: 'Vertrieb', icon: 'pi pi-chart-line', url: 'https://tst.grandega-access.de/geschaeftsbereich/sales/dashboard', visible: this.Visible.sales },
                ]
            },
            { label: 'grandega InfoDesk', icon: 'pi pi-info-circle', url: 'https://grandega.sharepoint.com/sites/InfoDesk', visible: this.Visible.infoDesk },
            { label: 'gWoW', icon: 'pi pi-info-circle', url: 'https://grandega.sharepoint.com/sites/gwow', visible: this.Visible.infoDesk },
            {
                label: 'grandega OKR', icon: 'pi pi-sitemap', visible: this.Visible.okr || this.Visible.adminOKR,
                items: [
                    { label: 'Dashboard', icon: 'pi pi-home', url: 'https://tst.grandega-access.de/okr/work/start', visible: this.Visible.okr },
                    { label: 'OKR Verwaltung', icon: 'pi pi-shield', url: 'https://tst.grandega-access.de/admin/okr/start', visible: this.Visible.adminOKR }
                ]
            },
            {
                label: 'grandega School', icon: 'pi pi-book', visible: this.Visible.school || this.Visible.adminSchool,
                items: [
                    { label: 'Anstehende Schulungen', icon: 'pi pi-list', routerLink: ['/school/list'], visible: this.Visible.school },
                    { label: 'Meine Buchungen', icon: 'pi pi-list', routerLink: ['/school/my-bookings'], visible: this.Visible.school },
                    { label: 'Admin-Bereich', icon: 'pi pi-shield', routerLink: ['/school/admin/list'], visible: this.Visible.adminSchool },
                ]
            },
            { label: 'Gratifikation', icon: 'pi pi-dollar', url: 'https://tst.grandega-access.de/cps/m/report', visible: this.Visible.infoDesk },
            {
                label: 'Mentor+', icon: 'pi pi-user', visible: this.Visible.mentorPlus,
                items: [
                    { label: 'Meine Mentees', icon: 'pi pi-list', url: 'https://tst.grandega-access.de/mentorplus/mentee-list', visible: this.Visible.mentorPlus },
                ]
            },
            { label: 'MyClient', icon: 'pi pi-desktop', url: 'https://tst.grandega-access.de/my-client/my-pr', visible: this.Visible.userWithoutIntegration },
            { label: 'MyClient', icon: 'pi pi-desktop', url: 'https://tst.grandega-access.de/my-client/my-pr', visible: this.Visible.myClient && !this.Visible.userWithoutIntegration },
            { label: 'PM Collaboration', icon: 'pi pi-chart-pie', url: 'https://pmcollaboration.sharepoint.com', visible: this.Visible.infoDesk },
            {
                label: 'Reisekosten', icon: 'pi pi-car', visible: this.Visible.rk || this.Visible.adminExpenses,
                items: [
                    { label: 'Meine Reisen', icon: 'pi pi-compass', url: 'https://tst.grandega-access.de/rk', visible: this.Visible.rk },
                    { label: 'Reisekostenverwaltung', icon: 'pi pi-shield', url: 'https://tst.grandega-access.de/admin/expense-mgmt/expenses-checks/list', visible: this.Visible.adminExpenses }
                ]
            },
            {
                label: 'Skillmanagement', icon: 'pi pi-chart-line', visible: this.Visible.skillManagement || this.Visible.skillManagementAdmin,
                items: [
                    { label: 'Meine Skills', icon: 'pi pi-list', url: 'https://tst.grandega-access.de/skillmanagement/user-detail', visible: this.Visible.skillManagement || this.Visible.skillManagementAdmin},
                    { label: 'Skillsuche', icon: 'pi pi-search', url: 'https://tst.grandega-access.de/skillmanagement/reports/skill-list', visible: this.Visible.skillManagement || this.Visible.skillManagementAdmin},
                    { label: 'Adminbereich', icon: 'pi pi-shield', url: 'https://tst.grandega-access.de/skillmanagement/admin', visible: this.Visible.skillManagementAdmin }
                ]
            },
            {
                label: 'Teamleitung', icon: 'pi pi-users', visible: this.Visible.teamleader,
                items: [
                    { label: 'Mein Team', icon: 'pi pi-list', url: 'https://tst.grandega-access.de/teamleitung/member-list', visible: this.Visible.teamleader },
                ]
            },
            { label: 'Whistleblower-App', icon: 'pi pi-eye', url: 'https://tst.grandega-access.de/whistleblower/mgmt-new', visible: this.Visible.whistleBlower },
            //{ label: 'XRM', icon: 'pi pi-id-card', url: 'https://tst.grandega-access.de/crm/dashboard', visible: this.Visible.crm },
            { label: 'XRM', icon: 'pi pi-id-card', routerLink: ['/xrm/dashboard'], visible: this.Visible.crm },
            
        ];
    }
}

export const environment = {

  production: false,
  apiUrl: "https://prod.grandega-access.de/api",
  url: "https://prod.grandega-access.de/v2",
  client_id:"7aeab85f-5287-4e79-ac69-4c43bf54d8eb",
  version:"Production Version",
  openaiApiKey: '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',
  name: 'prodv2',

};

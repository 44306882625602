import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';

@Component({
  selector: 'app-process-stepper',
  templateUrl: './process-stepper.component.html',
  styleUrls: ['./process-stepper.component.css'],
})
export class ProcessStepperComponent implements OnInit {
  @Input() activeStepIndex: number;
  @Input() maxStepIndex: number;
  // @Input() data_for_passing: Object;
  // @Input() is_new_data: Boolean;

  items: MenuItem[];

  constructor(
    private router: Router,
    private messageService: MessageService
    ) {
    this.items = [
      {
        label: 'BP',
      },
      {
        label: 'Kontakt',
      },
      {
        label: 'Lead',
      },
      // {
      //   label: 'Projekt',
      // },
      // {
      //   label: 'Position',
      // },
      // {
      //   label: 'Assignment',
      // },
      // {
      //   label: 'PSP-Element',
      // },
      // {
      //   label: 'Vertrag Kunde',
      // },
      // {
      //   label: 'Vertrag Lieferant',
      // },
      // {
      //   label: 'Abschluss',
      // },
      // {
      //   label: 'Faktura',
      // },
    ];


  }

  ngOnInit() {
    this.refreshItems();
  }

  test(event) {

    console.log(event);
   
    let storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

    if(this.maxStepIndex == 2 && event != 2 && !storedLead){
    
      this.messageService.add({severity:'info', summary: 'Info', detail: 'Bitte erst Lead speichern.'});
      this.activeStepIndex = 2;

    }

    console.log(storedLead);

    if (storedLead) {

      this.maxStepIndex = 2;
      console.log("in activeIndexChange: " + this.maxStepIndex);

      let stringValue: string;

      switch (this.activeStepIndex) {
        case 0:
          stringValue = 'bp';
          break;
        case 1:
          stringValue = 'contact';
          break;
        case 2:
          stringValue = 'lead';
          break;
        default:
          // Handle other cases if needed
          break;
      }

      this.router.navigate(['/xrm/edit-lead2order'], {
        queryParams: { stringValue, booleanValue: false, edit: storedLead.LeadID }
      });

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maxStepIndex) {
      this.refreshItems();
      console.log('maxStepIndex: ' + this.maxStepIndex);
    }
  }

  private refreshItems() {
    
    //das muss leider so, weil visible nicht funktioniert...
    let storedLead = JSON.parse(sessionStorage.getItem('otc_actual_lead'));

    if (storedLead) {
      this.maxStepIndex = 2;
    }


    switch (this.maxStepIndex) {
      case 0:
        this.items = [
          {
            label: 'BP',
          },
        ];
        break;

      case 1:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
        ];
        break;
      case 2:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
          {
            label: 'Lead',
          },
        ];
        break;
      case 3:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
          {
            label: 'Lead',
          },
          {
            label: 'Projekt',
          },
        ];
        break;
      case 4:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
          {
            label: 'Lead',
          },
          {
            label: 'Projekt',
          },
          {
            label: 'Position',
          },
        ];
        break;
      case 5:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
          {
            label: 'Lead',
          },
          {
            label: 'Projekt',
          },
          {
            label: 'Position',
          },
          {
            label: 'Assignment',
          },
        ];

        break;
      case 6:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
          {
            label: 'Lead',
          },
          {
            label: 'Projekt',
          },
          {
            label: 'Position',
          },
          {
            label: 'Assignment',
          },
          {
            label: 'PSP-Element',
          },
        ];
        break;

      case 7:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
          {
            label: 'Lead',
          },
          {
            label: 'Projekt',
          },
          {
            label: 'Position',
          },
          {
            label: 'Assignment',
          },
          {
            label: 'PSP-Element',
          },
          {
            label: 'Vertrag Kunde',
          },
          {
            label: 'Vertrag Lieferant',
          },
        ];

        break;
      case 8:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
          {
            label: 'Lead',
          },
          {
            label: 'Projekt',
          },
          {
            label: 'Position',
          },
          {
            label: 'Assignment',
          },
          {
            label: 'PSP-Element',
          },
          {
            label: 'Vertrag Kunde',
          },
          {
            label: 'Vertrag Lieferant',
          },
          {
            label: 'Abschluss',
          },
        ];
        break;
      case 9:
        this.items = [
          {
            label: 'BP',
          },
          {
            label: 'Kontakt',
          },
          {
            label: 'Lead',
          },
          {
            label: 'Projekt',
          },
          {
            label: 'Position',
          },
          {
            label: 'Assignment',
          },
          {
            label: 'PSP-Element',
          },
          {
            label: 'Vertrag Kunde',
          },
          {
            label: 'Vertrag Lieferant',
          },
          {
            label: 'Abschluss',
          },
          {
            label: 'Faktura',
          },
        ];
        break;

      default:
        break;
    }
  }

  nextStep() {
    this.maxStepIndex++;
  }

  lastStep() {
    this.maxStepIndex--;
  }

}

export class Monthlyfinal {
  MonthlyfinalID: number;
  PerformanceRecordStep;
  NoProject;
  LeaveVacationStep;
  LeaveSickStep;
  ConsultantProfileStep;
  SchoolStep;
  SkillMgmtStep;
  ExpenseStep;
  ProlongationStep;
  RestrictionStep;
  OKRStep;
  PCOStep;
  EmployeeSatisfactionStep;
  Completed;
  Month: string;
  Year: string;
  created_at: string;
  updated_at: string;
  created_by: number;
  updated_by: number;
}

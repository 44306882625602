import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from "primeng/api";

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
    constructor(private router: Router, private messageService: MessageService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    console.error("Client side error: ", error);
                } else {
                    console.error(`Server side error (status ${error.status}): `, error);
                    this.handleError(error.status);
                }
                return throwError(error);
            })
        );
    }

    private handleError(status: number): void {
        switch (status) {
            case 401: // login
            case 403: // forbidden
                // this.router.navigateByUrl("/auth/login");
                this.messageService.add({ severity: 'error', summary: 'Information', detail: 'Error 403' });
                break;
            case 500: // Server Error
                this.messageService.add({ severity: 'error', summary: 'Information', detail: 'Error 500' });
                break;
            case 404: // Not Found
                this.messageService.add({ severity: 'error', summary: 'Information', detail: 'Error 404' });
                break;
            case 0: // Unknown Error
                this.messageService.add({ severity: 'error', summary: 'Information', detail: 'Bitte einmal neu anmelden.' });
                break;
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../shared/services';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-ms-login',
  templateUrl: './ms-login.component.html',
  styleUrls: ['./ms-login.component.css']
})
export class MsLoginComponent implements OnInit {

  // Declare component properties
  isloggedIn: any;  // flag indicating if the user is logged in
  returnUrl: string;  // the URL to return to after login

  // Constructor injection
  constructor(
    private authService: MsalService,
    private broadcastService: BroadcastService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private loginComponent: LoginComponent
  ) { }

  // Component initialization
  ngOnInit(): void {
    // Get the return URL from session storage or set a default
    if (JSON.parse(sessionStorage.getItem('returnUrl')) != null) {
      this.returnUrl = JSON.parse(sessionStorage.getItem('returnUrl'));
    } else {
      console.log('Return URL not found in session storage');
      this.returnUrl = '/dashboard/default';
    }
    // console.log(`Return URL: ${this.returnUrl}`);

    // Get the user's Azure AD account information
    this.isloggedIn = this.authService.getAccount();

    // Set the request options for acquiring an access token
    const request = {
      scopes: ['user.readwrite', 'user.readbasic.all', 'profile', 'Calendars.ReadWrite'],
      loginHint: this.isloggedIn.preferred_username,
      extraQueryParameters: { domain_hint: 'organizations' }
    };

    // Acquire an access token silently using MSAL
    this.authService.acquireTokenSilent(request)
      .then((response) => {
        const token = response.accessToken;
        // console.log('Access token acquired successfully:', response);
        this.getLaravel(response);
      })
      .catch((error) => {
        console.error('Failed to acquire access token:', error);
      });

    // Handle MSAL redirect callback
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        // console.error('Redirect Error:', authError.errorMessage);
        return;
      }
      // console.log('Redirect Success:', response);
    });
  }

  // Authenticate with Laravel using the acquired access token
  getLaravel(response: any): void {
    this.authenticationService.azureLoginSuccess = true;
    this.authenticationService.azure_login(response)
      .subscribe(
        () => {
          console.log('User logged in successfully');
          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          console.error('Failed to log in:', error);
        }
      );
  }
}
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavService } from 'src/app/shared/services/nav.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Employee } from 'src/app/shared/model/employee';
import { PSPElemente, User } from 'src/app/shared/model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { Contract } from 'src/app/shared/model/businessdivision/contract';
import { BusinessDivisionService } from 'src/app/shared/services/businessdivision.service';
import { Businesspartner } from 'src/app/shared/model/xrm/businesspartner';
import moment from 'moment';
import business from 'moment-business-days';
import { Contact } from 'src/app/shared/model/xrm/contact';
import { UploadService } from 'src/app/shared/services/upload.service';
import { forkJoin, map, tap, firstValueFrom, Observable } from 'rxjs';
import { PositionV2 } from 'src/app/shared/model/xrm/V2/positionV2';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { Assignment } from 'src/app/shared/model/xrm/assignment';
import { LeadCrudComponent } from '../lead-crud/lead-crud.component';
import { element } from 'protractor';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';
import { ContactV2 } from 'src/app/shared/model/xrm/V2/contactV2';
import { ProcessStepperPositionComponent } from '../process-stepper-position/process-stepper-position.component';
import { ReportProjectDetailComponent } from 'src/app/components/xrm/reports/report-project/report-project-detail/report-project-detail.component';
import { ReportMissingdataComponent } from 'src/app/components/xrm/reports/report-missingdata/report-missingdata.component';

@Component({
  selector: 'app-contract-resource-crud',
  templateUrl: './contract-resource-crud.component.html',
  styleUrls: ['./contract-resource-crud.component.css']
})
export class ContractResourceCrudComponent implements OnInit {

  items: MenuItem[];
  contract: Contract;
  contractDialogNew;
  contractNumberGenerationDialog;

  contracts;
  contractNumberIsCreated = false;
  new = true;
  submittedError = false;
  employeePlaceholder = "Keiner ausgewählt";
  public searchEmployeeResult: boolean = false;
  public searchEmployeeResultEmpty: boolean = false;
  public employeeText: string;
  employees;
  empIsIn = true;
  costCenters: Array<{ Name: string }> = [{ Name: "Corporate Services" }, { Name: "Spotlight Management + Training" },
  { Name: "Customer Relationship Management" }, { Name: "Management (MP)" }, { Name: "Beraterpool" },
  { Name: "Externe Mitarbeiter" }, { Name: "Vertrieb" }, { Name: "Backoffice" }]
  tabIndex = 0;
  currentUser: User;
  //Placeholder
  ContractEnd: Date;
  ContractStart: Date;
  ContractOwner: User;
  Creator: User;
  CostCenter;
  PreviousPspElement: PSPElemente;
  ActualPspElement: PSPElemente;
  SelectedBusinesspartner: BusinesspartnerV2;
  SelectedContact: ContactV2;
  SelectedClient: BusinesspartnerV2;
  EngagementPartner;
  Helper: ContactV2;
  eps
  pspElemente
  pspElementeBefore
  businesspartners
  contacts;
  all_contacts;
  categorys;
  tempItems; //Array<{ Name: string }> = [{ Name: "In Benutzung" }, { Name: "Rückgabe" }, { Name: "Bestand" }]
  tempItem: MenuItem;
  mainCategorys;
  categoryItems;
  possiblePSPElements;
  public searchItems;
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text = "";
  contractTemp
  RoleIDs;
  diff;
  popUpPSP = false;
  allPSPs;
  documents;
  selectedDocuments;
  loading2 = false;
  hrefPrefix: string = `${environment.apiUrl}`;
  tempEvent;
  invoiceMethods: Array<{ Name: string }> = [{ Name: "Gutschriftsverfahren via Buchhaltung" }, { Name: "Gutschriftsverfahren mit LN via Berater" },
  { Name: "Gutschriftsverfahren ohne LN via Berater" }, { Name: "Rechnung via E-Mail" }, { Name: "Rechnung via Postversand" }]
  InvoiceMethod;
  // PaymentDeadline: Date;
  helperContacts;
  businessPartnerContacts;
  newDocDialog = false;
  externEmployees


  //testing
  navitems;

  storedPosition: PositionV2;
  storedBusinessPartner: BusinesspartnerV2;
  storedAssignment: Assignment;
  stored_preassignment;

  AccountingContact: ContactV2;

  save_loading = false;

  data_loading = true;

  constructor(public navServices: NavService,
    private http: HttpClient,
    private businessDivisionService: BusinessDivisionService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private uploadService: UploadService,
    private appLeadCrudComponent: LeadCrudComponent,
    private xrmServiceV2: XRMServiceV2,
    private confirmationService: ConfirmationService,
    private businessdivisionService: BusinessDivisionService,
    private appProcessStepperPositionComponent: ProcessStepperPositionComponent,
    private appReportProjectDetailComponent: ReportProjectDetailComponent,
    private appReportMissingdataComponent: ReportMissingdataComponent

  ) {

    //Init
    //this.loadStoredData();
    this.loadData();
    //this.refreshLists();
    this.currentUser = this.authenticationService.currentUserValue;
    //this.createNavigation();


  }

  ngOnInit() {

    switch (this.appProcessStepperPositionComponent.state) {
      case 'lead':
        this.appLeadCrudComponent.activePositionStepIndex = 4;

        break;
      case 'extension':
        this.appReportProjectDetailComponent.activePositionStepIndex = 4;

        break;

      case 'edit':
        this.appReportMissingdataComponent.activePositionStepIndex = 4;
        this.appReportProjectDetailComponent.activePositionStepIndex = 4;

        break;
    }


    // if (this.appProcessStepperPositionComponent.extension == true) {

    //   this.appReportProjectDetailComponent.activePositionStepIndex = 3;

    // }
    // else {

    //   this.appLeadCrudComponent.activePositionStepIndex = 3;

    // }

  }

  async loadStoredData(storedItems) {

    this.stored_preassignment = JSON.parse(sessionStorage.getItem('otc_actual_preassignment'));
    this.contract.PreassignmentID = this.stored_preassignment.PreassignmentID;

    //Set Assignment
    let storedContract = JSON.parse(sessionStorage.getItem('otc_actual_contract_resource'));

    if (storedContract) {

      console.log(this.contract);

      this.contract = storedContract;

      this.InvoiceMethod.Name = this.contract.InvoiceMethod;

      if (!this.contract.Contractor) {
        this.SelectedBusinesspartner = this.businesspartners.find(element => element.BusinesspartnerID == this.storedPosition.ZdlID);
      }
      else {
        this.SelectedBusinesspartner = this.businesspartners.find(element => element.BusinesspartnerID == this.contract.Contractor);
      }

      if (!this.contract.ContractNumber || this.appProcessStepperPositionComponent.extension == true)
        this.generateContractNumber(storedItems);

      if (this.appProcessStepperPositionComponent.extension != true) {
        const x = await firstValueFrom(this.xrmServiceV2.getDocsForContractStep(this.contract.ContractMainID));

        this.documents = x.documents;
      }

      this.ContractOwner = this.employees.find(element => element.PersonID == this.contract.ContractOwner);

      if (this.contract.accounting_contact)
        this.AccountingContact = this.contacts.find(element => element.PersonID == this.contract.accounting_contact);

      // if (this.contract.Helper)
      //   this.Helper = this.contacts.find(element => element.PersonID == this.contract.Helper);

      if (this.contract.ContractNumber != null)
        this.contractNumberIsCreated = true;

    } else {
      this.ContractOwner = this.employees.find(element => element.PersonID == this.storedPosition.DeliveryResponsible);
      this.contract = new Contract();

      this.generateContractNumber(storedItems);
    }

    this.data_loading = false;
  }

  refreshLists() {

    this.xrmServiceV2.getDataForContractsStep().subscribe(x => {
      this.employees = x.employees;
      this.eps = x.eps
      this.pspElemente = x.psp;
      this.pspElementeBefore = x.psp;
      this.allPSPs = x.psp;
      this.businesspartners = x.bp;
      this.contacts = x.contacts;
      this.externEmployees = x.externEmployees;
      this.contacts.sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0))

      this.checkEmployee();

      this.openNew('Ressourcen-Einkaufsvertrag', 1);

      this.data_loading = false;
    })
  }

  storedItems;

  async loadData() {
    try {
      // Parse sessionStorage items once
      this.storedItems = {
        projectDraft: JSON.parse(sessionStorage.getItem('otc_actual_project')),
        position: JSON.parse(sessionStorage.getItem('otc_actual_position')),
        assignment: JSON.parse(sessionStorage.getItem('otc_actual_assignment')),
        businessPartner: JSON.parse(sessionStorage.getItem('otc_actual_businesspartner')),
        businessPartnerContact: JSON.parse(sessionStorage.getItem('otc_actual_businesspartner_contact')),
        preAssignment: JSON.parse(sessionStorage.getItem('otc_actual_preassignment')),
        contract: JSON.parse(sessionStorage.getItem('otc_actual_contract_resource')),
        lead: JSON.parse(sessionStorage.getItem('otc_actual_lead')),
        roleIDs: new Set(JSON.parse(sessionStorage.getItem('roleID')))
      };

      const apiCalls: { [key: string]: Observable<any> } = {
        employees: this.xrmServiceV2.getEmployees(),
        contacts: this.xrmServiceV2.getContacts(),
        bps: this.xrmServiceV2.getBusinessPartners(),
        eps: this.xrmServiceV2.getEPS(),
        psp: this.xrmServiceV2.getPSPElements(),
        contracts: this.xrmServiceV2.getContracts(),
      };

      // If there's a stored lead, add preassignments and presales to the API calls
      if (this.storedItems.contract) {
        apiCalls['documents'] = this.xrmServiceV2.getDocsForContractStep(this.storedItems.contract.ContractMainID);
      }

      // Make all API calls in parallel
      forkJoin(apiCalls).subscribe({
        next: async (results: any) => {

          this.employees = results.employees;
          this.all_contacts = results.contacts;
          this.businesspartners = results.bps;

          if (this.storedItems.contract) {
            this.documents = results.documents;
          }

          this.eps = results.eps;
          this.pspElemente = results.psp;
          this.pspElementeBefore = results.psp;
          this.allPSPs = results.psp;
          this.contracts = results.contracts;

          // Initialize properties
          this.openNew('Ressourcen-Einkaufsvertrag', 1);

          // Set data from stored items
          this.setDataFromStoredItems(this.storedItems);

          this.loadStoredData(this.storedItems);

          // Load additional data if contract exists
          if (this.storedItems.contract) {
            await this.loadExistingContractData(this.storedItems.contract);
          } else {
            this.handleNoStoredContract(this.storedItems.lead);
          }

          this.selectBusinesspartnerContacts();
          this.selectHelperContacts();

          this.data_loading = false;
        },
        error: (error) => {
          console.error('Error fetching data:', error);
          this.data_loading = false;
          // Handle error (e.g., show error message)
        }
      });

    } catch (error) {
      console.error('Error in loadData:', error);
    }
  }

  private setDataFromStoredItems(storedItems) {
    this.SelectedClient = this.businesspartners.find(element => element.BusinesspartnerID == storedItems.businessPartnerContact.BusinesspartnerID);
    this.generateContractNumber(storedItems);

    this.contacts = this.all_contacts
      .filter(element => element.BusinesspartnerID === this.SelectedClient.BusinesspartnerID)
      .sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));

    this.Helper = this.contacts.find(element => element.ContactID == storedItems.businessPartnerContact.ContactID);
    this.SelectedBusinesspartner = this.businesspartners.find(bp => bp.BusinesspartnerID == "1339");
  }

  private async loadExistingContractData(storedContract) {
    this.contract = storedContract;
    this.ContractStart = new Date(this.contract.ContractStart);
    this.ContractEnd = new Date(this.contract.ContractEnd);
    this.ContractOwner = this.employees.find(element => element.PersonID == this.contract.ContractOwner);
    this.contractNumberIsCreated = this.contract.ContractNumber != null;
  }

  private handleNoStoredContract(storedLead) {
    this.contract = new Contract();
    this.ContractOwner = this.employees.find(element => element.PersonID == storedLead.LeadManagerID);
  }


  async loadDataOLD() {
    try {

      const x = await firstValueFrom(this.xrmServiceV2.getDataForContractsStep());

      this.documents = x.documents;
      this.employees = x.employees;
      this.eps = x.eps;
      this.pspElemente = x.psp;
      this.pspElementeBefore = x.psp;
      this.allPSPs = x.psp;
      this.businesspartners = x.bp;
      this.externEmployees = x.externEmployees;
      this.all_contacts = x.contacts;

      this.openNew('Ressourcen-Einkaufsvertrag', 1);

      this.ContractOwner = new User();
      this.CostCenter = {};
      this.Creator = new User();
      this.PreviousPspElement = new PSPElemente();
      this.ActualPspElement = new PSPElemente();
      this.SelectedBusinesspartner = new BusinesspartnerV2();
      this.SelectedContact = new ContactV2();
      this.SelectedClient = new BusinesspartnerV2();
      this.EngagementPartner = new Employee();

      this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
      this.InvoiceMethod = { Name: "Übermittlungsmethode auswählen" };

      this.contracts = this.businessDivisionService.getMainContracts().pipe(
        map(contracts => contracts.filter(contract => this.RoleIDs.some(el => el === contract.AuthorizationID)))
      );

      this.storedPosition = JSON.parse(sessionStorage.getItem('otc_actual_position'));

      this.storedAssignment = JSON.parse(sessionStorage.getItem('otc_actual_assignment'));

      this.ContractStart = new Date(this.storedPosition.PositionStartDate);
      this.ContractEnd = new Date(this.storedPosition.PositionEndDate);

      this.storedBusinessPartner = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner'));
      const storedBusinessPartnerContact = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner_contact'));

      //this.SelectedClient = this.businesspartners.find(element => element.BusinesspartnerID == storedBusinessPartnerContact.BusinesspartnerID);
      //this.SelectedClient = this.storedBusinessPartner;
      this.SelectedClient = this.businesspartners.find(bp => bp.BusinesspartnerID == "2");

      //this.contacts = this.all_contacts.filter(element => element.BusinesspartnerID == this.SelectedClient.BusinesspartnerID).sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0));

      // if (!this.contract.Helper)
      //   this.Helper = storedBusinessPartnerContact;
      // else {
      //   this.Helper = this.contacts.find(element => element.PersonID == this.contract.Helper);
      // }


      // if (this.storedPosition.ZdlID) {
      //   this.SelectedClient = this.businesspartners.find(element => element.BusinesspartnerID == this.storedPosition.ZdlID);

      //   const storedBusinessPartnerContact = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner_contact'));

      //   this.contacts = this.all_contacts.filter(element => element.BusinesspartnerID == this.SelectedClient.BusinesspartnerID).sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0));

      //   if (!this.contract.Helper)
      //     this.Helper = storedBusinessPartnerContact;
      //   else {
      //     this.Helper = this.contacts.find(element => element.PersonID == this.contract.Helper);
      //   }

      // } else {
      //   this.storedBusinessPartner = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner'));
      //   const storedBusinessPartnerContact = JSON.parse(sessionStorage.getItem('otc_actual_businesspartner_contact'));

      //   //this.SelectedClient = this.businesspartners.find(element => element.BusinesspartnerID == storedBusinessPartnerContact.BusinesspartnerID);
      //   //this.SelectedClient = this.storedBusinessPartner;
      //   this.SelectedClient = this.businesspartners.find(bp => bp.BusinesspartnerID == "2");
      //   this.contacts = this.all_contacts.filter(element => element.BusinesspartnerID == this.SelectedClient.BusinesspartnerID).sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0));

      //   if (!this.contract.Helper)
      //     this.Helper = storedBusinessPartnerContact;
      //   else {
      //     this.Helper = this.contacts.find(element => element.PersonID == this.contract.Helper);
      //   }
      //   //this.Helper = this.contacts.find(element => element.ContactID == storedBusinessPartnerContact.ContactID);

      // }

      this.loadStoredData(this.storedItems);

      this.selectBusinesspartnerContacts();
      this.selectHelperContacts();

      this.checkEmployee();


      this.data_loading = false;
    } catch (error) {
      console.error('Error in loadData:', error);
    }
  }

  save_contract(next) {

    this.contract.PreassignmentID = this.stored_preassignment.PreassignmentID;

    this.save_loading = true;

    this.contract.ContractStart = new Date(moment(this.ContractStart).format('YYYY-MM-DD'));
    this.contract.ContractEnd = new Date(moment(this.ContractEnd).format('YYYY-MM-DD'));
    this.contract.ContractOwner = this.ContractOwner.PersonID;

    this.contract.Status = 'offen';

    this.contract.Helper = this.Helper.PersonID;
    this.contract.ContactContractor = this.SelectedContact.PersonID;


    if (this.AccountingContact)
      this.contract.accounting_contact = this.AccountingContact.PersonID;

    this.contract.ContractType = 'Kundenvertrag';
    this.contract.ContractSubCategoryID = 2;

    if (!this.contract.ContractNumber || this.appProcessStepperPositionComponent.extension == true)
      this.generateContractNumber(this.storedItems);

    if (this.contract.ContractResourceID != null) {

      this.xrmServiceV2.editResourceContract(this.contract).subscribe((response) => {
        if (response.result == 'success') {

          this.myUploader();

          if (next) {

            if (this.storedAssignment.ConsultantPersonType == 'extern') {

              switch (this.appProcessStepperPositionComponent.state) {
                case 'lead':
                  this.appLeadCrudComponent.activePositionStepIndex = 5;
                  this.appLeadCrudComponent.is_intern = false;

                  if (this.appLeadCrudComponent.maxPositionStepIndex == 4)
                    this.appLeadCrudComponent.maxPositionStepIndex++;

                  break;

                case 'edit':

                  this.appLeadCrudComponent.activePositionStepIndex = 5;
                  this.appLeadCrudComponent.is_intern = false;

                  if (this.appLeadCrudComponent.maxPositionStepIndex == 4)
                    this.appLeadCrudComponent.maxPositionStepIndex++;

                  this.appReportMissingdataComponent.activePositionStepIndex = 5;
                  this.appReportMissingdataComponent.is_intern = false;

                  if (this.appReportMissingdataComponent.maxPositionStepIndex <= 4)
                    this.appReportMissingdataComponent.maxPositionStepIndex++;


                  this.appReportProjectDetailComponent.activePositionStepIndex = 5;
                  this.appReportProjectDetailComponent.is_intern = false;

                  if (this.appReportProjectDetailComponent.maxPositionStepIndex == 4)
                    this.appReportProjectDetailComponent.maxPositionStepIndex++;

                  break;
              }


              // this.appLeadCrudComponent.activePositionStepIndex = 4;
              // this.appLeadCrudComponent.is_intern = false;

              // if (this.appLeadCrudComponent.maxPositionStepIndex == 3)
              //   this.appLeadCrudComponent.maxPositionStepIndex++;

            } else {

              switch (this.appProcessStepperPositionComponent.state) {
                case 'lead':
                  this.appLeadCrudComponent.activePositionStepIndex = 5;
                  this.appLeadCrudComponent.is_intern = true;
                  this.appLeadCrudComponent.maxPositionStepIndex = 5;

                  break;

                case 'edit':
                  this.appReportMissingdataComponent.activePositionStepIndex = 5;
                  this.appReportMissingdataComponent.is_intern = true;
                  this.appReportMissingdataComponent.maxPositionStepIndex = 5;

                  this.appReportProjectDetailComponent.activePositionStepIndex = 5;
                  this.appReportProjectDetailComponent.is_intern = true;
                  this.appReportProjectDetailComponent.maxPositionStepIndex = 5;

                  this.appLeadCrudComponent.activePositionStepIndex = 5;
                  this.appLeadCrudComponent.is_intern = true;
                  this.appLeadCrudComponent.maxPositionStepIndex = 5;

                  break;
              }

              // this.appLeadCrudComponent.is_intern = true;
              // this.appLeadCrudComponent.activePositionStepIndex = 5;
              // this.appLeadCrudComponent.maxPositionStepIndex = 5;

            }

          } else {

            this.appLeadCrudComponent.newPreAssignmentDialog = false;
            this.appReportMissingdataComponent.sidebarVisible = false;
            this.appReportProjectDetailComponent.sidebarVisible = false;

          }

          this.contract
            ? sessionStorage.setItem('otc_actual_contract_resource', JSON.stringify(this.contract))
            : sessionStorage.removeItem('otc_actual_contract_resource');

          this.messageService.add({ severity: 'success', summary: 'Information', detail: response.message });

        }
        if (response.result == 'error')
          this.messageService.add({ severity: 'info', summary: 'Information', detail: response.message });

        this.save_loading = false;

      });
    }
    else {

      this.createContract(next);

    }

  }


  openNew(contracttype, ContractSubCategoryID) {
    this.tabIndex = 0;
    this.ContractOwner = new User();
    this.CostCenter = {};
    this.Creator = new User();
    this.PreviousPspElement = new PSPElemente();
    this.ActualPspElement = new PSPElemente();
    this.SelectedBusinesspartner = new BusinesspartnerV2();
    this.SelectedBusinesspartner = this.businesspartners.filter(bp => bp.BusinesspartnerID == "2")[0];
    this.SelectedContact = new ContactV2();
    this.SelectedClient = new BusinesspartnerV2();
    this.EngagementPartner = new Employee();
    this.Helper = new ContactV2();
    this.contract = new Contract();


    //TODO Contracttype und ContractSubCategoryID auf Kundenvertrag festlegen

    this.contract.ContractType = contracttype;
    this.contractDialogNew = true;
    this.contract.ContractSubCategoryID = ContractSubCategoryID;
    this.InvoiceMethod = {};
    this.InvoiceMethod.Name = "Übermittlungsmethode auswählen"
    this.documents = [];
  }

  showPopUpPSP() {
    this.popUpPSP = true;
  }

  hidePopUpPSP() {
    this.popUpPSP = false;
  }

  hideDialog() {
    this.tabIndex = 0;
    this.contractDialogNew = false;
  }

  openNext() {
    this.tabIndex = this.tabIndex + 1;
  }

  openPrev() {

    this.tabIndex = this.tabIndex - 1;

    // this.index = (this.index === 0) ? 2 : this.index - 1;
  }

  selectHelperContacts() {

    if (this.SelectedClient.BusinesspartnerID == 2) {
      this.businessPartnerContacts = this.employees;

      if (!this.contract.Helper) {
        this.Helper = this.businessPartnerContacts.find(element => element.PersonID == this.storedPosition.DeliveryResponsible);
      }
      else {
        this.Helper = this.businessPartnerContacts.find(element => element.PersonID == this.contract.Helper);
      }


    } else {
      this.businessPartnerContacts = this.all_contacts.filter(element => element.BusinesspartnerID == this.SelectedClient.BusinesspartnerID).sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0));

    }
  }


  selectBusinesspartnerContacts() {

    if (this.SelectedBusinesspartner.BusinesspartnerID == 2) {
      this.contacts = this.employees;

      if (!this.contract.ContactContractor) {
        this.SelectedContact = this.businessPartnerContacts.find(element => element.PersonID == this.storedPosition.DeliveryResponsible);
      }
      else {
        this.SelectedContact = this.businessPartnerContacts.find(element => element.PersonID == this.contract.ContactContractor);
      }

    } else {
      this.contacts = this.all_contacts.filter(contact => contact.BusinesspartnerID == this.SelectedBusinesspartner.BusinesspartnerID).sort((a, b) => (a.DisplayName > b.DisplayName) ? 1 : ((b.DisplayName > a.DisplayName) ? -1 : 0));

      console.log(this.contacts);
      console.log(this.contract.ContactContractor);

      this.SelectedContact = this.contacts.find(element => element.PersonID == this.contract.ContactContractor);

      console.log(this.SelectedContact);
    }

  }

  filterPSPElements() {
    if (this.ContractStart != null) {
      this.pspElemente = [];
      this.allPSPs.map(psp => {
        if (moment(this.ContractStart).format('YYYY-MM-DD') < moment(psp.validto).format('YYYY-MM-DD'))
          this.pspElemente.push(psp);
      })
    }
  }

  calculateDiff(pspElement) {
    this.diff = (business(pspElement.validfrom, 'YYYY-MM-DD').businessDiff(business(pspElement.validto, 'YYYY-MM-DD'))) + 1;
  }


  async createContract(next) {

    const tempStart = moment(this.ContractStart).format('YYYY-MM-DD');
    const tempEnd = moment(this.ContractEnd).format('YYYY-MM-DD');

    try {
      const contracts = await this.businessDivisionService.getMainContracts().toPromise();

      const todaysContractCount = contracts.filter(contract =>
        moment(contract.created_at).isSame(moment(), 'day')
      ).length + 1;

      const tempContractNumber = await this.generateContractNumber(this.storedItems);

      const formattedContractNumber = `${tempContractNumber.substring(0, 13)}${todaysContractCount}${tempContractNumber.substring(tempContractNumber.indexOf(' /'))}`;

      if (!this.CostCenter) {
        this.CostCenter = {};
      }

      if (!this.Helper) {
        this.Helper = new ContactV2();
      }

      if (!this.ContractOwner) {
        this.ContractOwner = new User();
      }

      if (!this.SelectedClient) {
        this.SelectedClient = new BusinesspartnerV2();
      }

      if (!this.SelectedContact) {
        this.SelectedContact = new ContactV2();
      }

      if (!this.SelectedBusinesspartner) {
        this.SelectedBusinesspartner = new BusinesspartnerV2();
      }

      if (!this.PreviousPspElement) {
        this.PreviousPspElement = new PSPElemente();
      }

      if (!this.AccountingContact) {
        this.AccountingContact = new ContactV2();
      }

      const storedPreAssignment = JSON.parse(sessionStorage.getItem('otc_actual_preassignment'))

      this.contract.PreassignmentID = storedPreAssignment.PreassignmentID;
      this.contract.ContractNumber = formattedContractNumber;
      this.contract.ContractStart = new Date(tempStart);
      this.contract.ContractEnd = new Date(tempEnd);
      this.contract.ContractOwner = this.ContractOwner.PersonID;
      this.contract.Helper = this.Helper.PersonID;
      this.contract.Client = this.SelectedClient.BusinesspartnerID;
      this.contract.accounting_contact = this.AccountingContact.PersonID;
      this.contract.Contractor = this.SelectedBusinesspartner.BusinesspartnerID;
      //this.contract.BpInvoiceInfo = this.ActualPspElement.BpInvoiceInfo;
      this.contract.InvoiceMethod = this.InvoiceMethod.Name;
      //this.contract.InvoiceTo = this.ActualPspElement.InvoiceTo;
      this.contract.PositionID = this.storedPosition.PositionID;

      const requestBody = {
        'PreassignmentID': storedPreAssignment.PreassignmentID,
        'ContractNumber': formattedContractNumber,
        'ContractSubCategoryID': this.contract.ContractSubCategoryID,
        'ContractType': this.contract.ContractType,
        'ContractTitle': this.contract.ContractTitle,
        //'CostCenter': this.CostCenter.Name,
        'ContractStart': tempStart,
        'ContractEnd': tempEnd,
        'ContractOwner': this.ContractOwner.PersonID,
        'Helper': this.contract.Helper,
        'accounting_contact': this.AccountingContact.PersonID,
        'TravelExpenses': this.contract.TravelExpenses,
        'IncidentalExpenses': this.contract.IncidentalExpenses,
        'TravelExpensesComment': this.contract.TravelExpensesComment,
        'Client': this.SelectedClient.BusinesspartnerID,
        'Contractor': this.SelectedBusinesspartner.BusinesspartnerID,
        'Comment': this.contract.Comment,
        // 'PreviousPspElementID': this.PreviousPspElement.PSPElementeID,
        'BpInvoiceInfo': this.contract.BpInvoiceInfo,
        'InvoiceMethod': this.InvoiceMethod.Name,
        'InvoiceTo': this.contract.InvoiceTo,
        'PaymentDeadline': this.contract.PaymentDeadline,
        'PositionID': this.storedPosition.PositionID,
      };

      const result = await this.http.post<any>(`${environment.apiUrl}/v2/create/contract-resource`, requestBody).toPromise();

      if (result.result === 'success') {
        this.contract.ContractMainID = result.ContractMainID;

        if (this.tempEvent != null) {
          this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Bitte warten, Dateien werden hochgeladen' });
          this.myUploader();
        } else {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vertrag wurde erfolgreich erstellt' });
          this.hideDialog();
        }

        this.save_loading = false;

        this.contract.ContractResourceID = result.ContractResourceID;

        this.contract
          ? sessionStorage.setItem('otc_actual_contract_resource', JSON.stringify(this.contract))
          : sessionStorage.removeItem('otc_actual_contract_resource');

        if (next) {

          if (this.storedAssignment.ConsultantPersonType == 'extern') {

            switch (this.appProcessStepperPositionComponent.state) {
              case 'lead':
                this.appLeadCrudComponent.activePositionStepIndex = 5;
                this.appLeadCrudComponent.is_intern = false;

                if (this.appLeadCrudComponent.maxPositionStepIndex == 4)
                  this.appLeadCrudComponent.maxPositionStepIndex++;

                break;
              case 'extension':
                this.appReportProjectDetailComponent.activePositionStepIndex = 5;
                this.appReportProjectDetailComponent.is_intern = false;

                if (this.appReportProjectDetailComponent.maxPositionStepIndex == 4)
                  this.appReportProjectDetailComponent.maxPositionStepIndex++;

                break;

              case 'edit':
                this.appReportMissingdataComponent.activePositionStepIndex = 5;
                this.appReportMissingdataComponent.is_intern = false;

                if (this.appReportMissingdataComponent.maxPositionStepIndex <= 4)
                  this.appReportMissingdataComponent.maxPositionStepIndex++;


                this.appReportProjectDetailComponent.activePositionStepIndex = 5;
                this.appReportProjectDetailComponent.is_intern = false;

                if (this.appReportProjectDetailComponent.maxPositionStepIndex == 4)
                  this.appReportProjectDetailComponent.maxPositionStepIndex++;

                break;
            }

            // if (this.appProcessStepperPositionComponent.extension == true) {

            //   this.appReportProjectDetailComponent.activePositionStepIndex = 4;
            //   this.appReportProjectDetailComponent.is_intern = false;

            //   if (this.appReportProjectDetailComponent.maxPositionStepIndex == 3)
            //     this.appReportProjectDetailComponent.maxPositionStepIndex++;

            // }
            // else {

            //   this.appLeadCrudComponent.activePositionStepIndex = 4;
            //   this.appLeadCrudComponent.is_intern = false;

            //   if (this.appLeadCrudComponent.maxPositionStepIndex == 3)
            //     this.appLeadCrudComponent.maxPositionStepIndex++;

            // }

          } else {

            switch (this.appProcessStepperPositionComponent.state) {
              case 'lead':
                this.appLeadCrudComponent.activePositionStepIndex = 5;
                this.appLeadCrudComponent.is_intern = true;
                this.appLeadCrudComponent.maxPositionStepIndex = 5;

                break;
              case 'extension':
                this.appReportProjectDetailComponent.activePositionStepIndex = 5;
                this.appReportProjectDetailComponent.is_intern = true;
                this.appReportProjectDetailComponent.maxPositionStepIndex = 5;

                break;

              case 'edit':
                this.appReportMissingdataComponent.activePositionStepIndex = 5;
                this.appReportMissingdataComponent.is_intern = true;
                this.appReportMissingdataComponent.maxPositionStepIndex = 5;


                this.appReportProjectDetailComponent.activePositionStepIndex = 5;
                this.appReportProjectDetailComponent.is_intern = true;
                this.appReportProjectDetailComponent.maxPositionStepIndex = 5;

                break;
            }

            // if (this.appProcessStepperPositionComponent.extension == true) {

            //   console.log('extension in contract client true')

            //   this.appReportProjectDetailComponent.is_intern = true;
            //   this.appReportProjectDetailComponent.activePositionStepIndex = 5;
            //   this.appReportProjectDetailComponent.maxPositionStepIndex = 5;

            // }
            // else {
            //   this.appLeadCrudComponent.is_intern = true;
            //   this.appLeadCrudComponent.activePositionStepIndex = 5;
            //   this.appLeadCrudComponent.maxPositionStepIndex = 5;

            // }

          }

        }
        else {

          this.appLeadCrudComponent.newPreAssignmentDialog = false;
          this.appReportMissingdataComponent.sidebarVisible = false;
          this.appReportProjectDetailComponent.sidebarVisible = false;

        }

      } else {
        this.messageService.add({ severity: 'error', summary: 'Information', detail: result.error });
      }
    } catch (error) {
      console.error('Error in createContract:', error);
    }
  }


  redirectTo() {



    // let routing = this.router.url
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    //   this.router.navigate([routing]));
  }

  checkBPAbbrev() {
    if (this.SelectedClient.BpAbbreviation == null) {
      this.messageService.add({ severity: 'info', summary: 'Success', detail: 'Businesspartner hat kein Kürzel, bitte pflegen.' });
    }
  }

  generateContractNumber(storedItems): Promise<string> {

    this.storedAssignment = storedItems.assignment;
    this.storedPosition = storedItems.position;

    return new Promise((resolve, reject) => {
      if (this.SelectedClient.BpAbbreviation == null) {
        this.SelectedClient = new BusinesspartnerV2();
      }

      this.possiblePSPElements = [];
      this.businessDivisionService.getMainContracts().subscribe({
        next: (contracts) => {
          let todaysContractCount = 1;
          contracts.forEach((contract) => {
            if (
              moment(contract.created_at).format("DD-MM-YYYY") ===
              moment(new Date()).format("DD-MM-YYYY")
            ) {
              ++todaysContractCount;
            }
          });

          let CountProlongation = 0;


          if (this.storedPosition.ProjectID) {

            this.businessDivisionService
              .getProlongationsInProject(
                this.storedAssignment.ConsultantPersonID,
                this.storedPosition.ProjectID
              )
              .subscribe({
                next: (psp) => {
                  psp.forEach((element) => {
                    if (
                      moment(this.contract.ContractStart).format("YYYY-MM-DD") >=
                      moment(element.validto).format("YYYY-MM-DD") &&
                      this.storedAssignment.ConsultantPersonID === element.PersonID
                    ) {
                      ++CountProlongation;
                    }
                    this.possiblePSPElements.push(element);
                  });

                  const BPAbbrevation = this.SelectedClient.BpAbbreviation;
                  let prefix =
                    this.contract.ContractType === "Angebote" ? "A_" : "V_";
                  const today = moment(new Date()).format("DD-MM-YYYY");

                  this.contractNumberGenerationDialog = false;
                  this.contractNumberIsCreated = true;

                  // Resolve the promise with the generated contract number

                  this.contract.ContractNumber = `${prefix}${today} -${todaysContractCount} / ${BPAbbrevation}. ${CountProlongation}`;

                  resolve(
                    `${prefix}${today} -${todaysContractCount} / ${BPAbbrevation}. ${CountProlongation}`
                  );
                },
                error: (error) => {
                  // Handle any errors that occur during subscription
                  reject(error);
                },
              });

          }
          else {

            const BPAbbrevation = this.SelectedClient.BpAbbreviation;
            let prefix =
              this.contract.ContractType === "Angebote" ? "A_" : "V_";
            const today = moment(new Date()).format("YYYY-MM-DD");

            this.contractNumberGenerationDialog = false;
            this.contractNumberIsCreated = true;

            // Resolve the promise with the generated contract number

            this.contract.ContractNumber = `${prefix}${today} -${todaysContractCount} / ${BPAbbrevation}. ${CountProlongation}`;

            resolve(
              `${prefix}${today} -${todaysContractCount} / ${BPAbbrevation}. ${CountProlongation}`
            );

          }


        },
        error: (error) => {
          // Handle any errors that occur during subscription
          reject(error);
        },
      });
    });
  }

  cancel() {
    this.contract = new Contract();
    this.contractDialogNew = false;
  }

  changeHelper(contact) {
    this.Helper = contact;
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) {
      this.contracts = this.contractTemp;
      return;
    }

    let items = [];
    term = term.toLowerCase();
    this.searchItems.filter(contractItems => {

      if (contractItems.ContractNumber.toLowerCase().includes(term)) {
        items.push(contractItems);

      }
      this.checkSearchResultEmpty(items)
      this.contracts = items
    });

  }

  checkSearchResultEmpty(items) {
    if (!items.length) {
      this.searchResultEmpty = true;
    }
    else {
      //this.trainings=this.items;
      this.searchResultEmpty = false;
    }
  }

  addFix() {
    this.searchResult = true;
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
  }


  myUploaderTemp(event) {

    for (let file of event.files) {
      let document = file;

      document.DocName = file.name;
      document.created_at = moment(new Date());

      this.documents.push(document)
    }
    this.tempEvent = event;
    this.hideDocDialog();

  }

  myUploader() {

    for (let file of this.documents) {

      if (file.ContractDocID == null) {

        const formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("ContractMainID", String(this.contract.ContractMainID));

        this.uploadService.uploadContractDoc(formData).subscribe(result => {
          if (result.result === 'success') {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Dokument wurde erfolgreich hochgeladen' });
            this.hideDialog();
          } else {
            this.messageService.add({ severity: 'error', summary: 'Information', detail: result.error });
          }
        });

      }
    }
  }

  deleteSelectedDocuments() {
    this.confirmationService.confirm({
      message: 'Möchtest du die ausgewählten Dokumente dauerhaft löschen?',
      header: 'Confirm',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      key: 'deleteDocument',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        for (let document of this.selectedDocuments) {

          this.businessdivisionService.deleteContractDoc(document.ContractDocID, document.ContractMainID).subscribe(result => {
            if (result.result === 'success') {
              this.businessdivisionService.getContractsByID(this.contract.ContractMainID, this.contract.MainAbbreviationURL, this.contract.SubAbbreviationURL).subscribe(x => {
                this.documents = x.documents;
              })
              this.messageService.add({ severity: 'success', summary: 'Erfolgreich', detail: 'Dokument ' + document.DocName + ' gelöscht', life: 3000 });
            } else {
              this.messageService.add({ severity: 'info', summary: 'Information', detail: result });
            }
          });
        }
        // this.documents = this.documents.filter(val => !this.selectedDocuments.includes(val));
        this.selectedDocuments = null;
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      }
    });
  }


  hideDocDialog() {
    this.newDocDialog = false;
  }

  showDocDialog() {
    this.newDocDialog = true;
  }

  checkEmployee() {
    if (this.SelectedBusinesspartner.BusinesspartnerID != null && this.ActualPspElement.PersonID != null) {
      let isIn = this.externEmployees.filter(emp => emp.PersonID == this.ActualPspElement.PersonID && emp.Employer == this.SelectedBusinesspartner.BusinesspartnerID)[0]
      this.empIsIn = isIn != null;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';

import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';

import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToolbarModule } from 'primeng/toolbar';

import { TabViewModule } from 'primeng/tabview';
import { SplitButtonModule } from 'primeng/splitbutton';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { AutoFocusModule } from 'primeng/autofocus';

import { SidebarModule } from 'primeng/sidebar';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

// services

import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';

import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { KeyFilterModule } from 'primeng/keyfilter';

import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { GraphService, AuthenticationService } from '../../services';
import { NavService } from '../../services/nav.service';
import { AssignmentCrudComponent } from './assignment-crud/assignment-crud.component';
import { BpContactCrudComponent } from './bp-contact-crud/bp-contact-crud.component';
import { BusinesspartnerCrudComponent } from './businesspartner-crud/businesspartner-crud.component';
import { FinalApprovalComponent } from './final-approval/final-approval.component';
import { LeadCrudComponent } from './lead-crud/lead-crud.component';
import { OrderToCashRoutingModule } from './order-to-cash-routing.module';
import { PositionCrudComponent } from './position-crud/position-crud.component';
import { ProcessStepperComponent } from './process-stepper/process-stepper.component';
import { ProjectCrudComponent } from './project-crud/project-crud.component';
import { PspElementCrudComponent } from './psp-element-crud/psp-element-crud.component';
import { ContractClientCrudComponent } from './contract-client-crud/contract-client-crud.component';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ContractAdministrationModule } from 'src/app/components/businessdivision/contract-administration/contractadministration.module';
import { SharedModule } from 'primeng/api';
import { InputNumberModule } from 'primeng/inputnumber';
import { ContractResourceCrudComponent } from './contract-ressource-crud/contract-resource-crud.component';
import { CalendarModule } from 'primeng/calendar';
import { ProcessStepperPositionComponent } from './process-stepper-position/process-stepper-position.component';
import { ContractOfferCrudComponent } from './contract-offer-crud/contract-offer-crud.component';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ReportProjectDetailComponent } from 'src/app/components/xrm/reports/report-project/report-project-detail/report-project-detail.component';
import { ReportMissingdataComponent } from 'src/app/components/xrm/reports/report-missingdata/report-missingdata.component';

@NgModule({
  declarations: [
    BpContactCrudComponent,
    BusinesspartnerCrudComponent,
    LeadCrudComponent,
    ProcessStepperComponent,
    ProcessStepperPositionComponent,
    AssignmentCrudComponent,
    BpContactCrudComponent,
    FinalApprovalComponent,
    PositionCrudComponent,
    ProjectCrudComponent,
    PspElementCrudComponent,
    ContractClientCrudComponent,
    ContractResourceCrudComponent,
    ContractOfferCrudComponent
  ],
  imports: [
    CommonModule,
    TriStateCheckboxModule,
    SidebarModule,
    ConfirmPopupModule,
    AccordionModule,
    AutoFocusModule,
    RouterModule,
    FormsModule,
    RadioButtonModule,
    InputSwitchModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    InputTextModule,
    BreadcrumbModule,
    ToastModule,
    AvatarModule,
    BadgeModule,
    CheckboxModule,
    ButtonModule,
    RippleModule,
    SkeletonModule,
    DividerModule,
    FileUploadModule,
    TableModule,
    ConfirmDialogModule,
    ToolbarModule,
    TabViewModule,
    SplitButtonModule,
    PanelModule,
    FieldsetModule,
    SelectButtonModule,
    DialogModule,
    StepsModule,
    OrderToCashRoutingModule,
    AutoCompleteModule,
    KeyFilterModule,
    DropdownModule,
    ReactiveFormsModule,
    InputTextareaModule,
    CardModule,
    ProgressSpinnerModule,
    InputNumberModule,
    CalendarModule,
    DividerModule
  ],
  exports: [
    RouterModule,
    BpContactCrudComponent,
    BusinesspartnerCrudComponent,
    LeadCrudComponent,
    AssignmentCrudComponent,
    BpContactCrudComponent,
    FinalApprovalComponent,
    PositionCrudComponent,
    ProjectCrudComponent,
    PspElementCrudComponent,
    ContractClientCrudComponent,
    OrderToCashRoutingModule,
    ProcessStepperComponent,
    ProcessStepperPositionComponent,
  ],
  providers: [GraphService, NavService, AuthenticationService, MessageModule, ReportProjectDetailComponent, ReportMissingdataComponent],
})
export class OrderToCashModule {}

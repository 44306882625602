import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-process-stepper-position',
  templateUrl: './process-stepper-position.component.html',
  styleUrls: ['./process-stepper-position.component.css'],
})
export class ProcessStepperPositionComponent implements OnInit {
  @Input() activePositionStepIndex: number;
  @Input() maxPositionStepIndex: number;

  @Input() is_intern: boolean;

  @Input() extension: boolean;
  @Input() edit: boolean;

  @Input() state;

  // @Input() data_for_passing: Object;
  // @Input() is_new_data: Boolean;

  items: MenuItem[];

  constructor(private router: Router) {
    
    this.items = [
      {
        label: 'Angebot',
      },
      {
        label: 'Position',
      },
      {
        label: 'Assignment',
      },
      {
        label: 'Vertrag Kunde',
      },
      {
        label: 'Vertrag Lieferant',
      },
      {
        label: 'Zusammenfassung',
      },
      // {
      //   label: 'Abschluss',
      // },
    ];

    
  }

  ngOnInit() {
    this.refreshItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maxPositionStepIndex) {
      this.refreshItems();
      console.log('test ' + this.maxPositionStepIndex);
    }
  }

  public refreshItems() {
    //das muss leider so, weil visible nicht funktioniert...

    console.log(this.maxPositionStepIndex);

    console.log(this.is_intern);

    console.log(this.extension);

    console.log(this.state);

    if(this.is_intern == false){

      switch (this.maxPositionStepIndex) {
        case 0:
          this.items = [
            {
              label: 'Angebot',
            }
          ];
          break;

        case 1:
          this.items = [
            {
              label: 'Angebot',
            },
            {
              label: 'Position',
            },
          ];
          break;
  
        case 2:
          this.items = [
            {
              label: 'Angebot',
            },
            {
              label: 'Position',
            },
            {
              label: 'Assignment',
            },
          ];
          break;
          
        case 3:
          this.items = [
            {
              label: 'Angebot',
            },
            {
              label: 'Position',
            },
            {
              label: 'Assignment',
            },
            {
              label: 'Vertrag Kunde',
            },
            
          ];
          break;
  
        case 4:
          this.items = [
            {
              label: 'Angebot',
            },
            {
              label: 'Position',
            },
            {
              label: 'Assignment',
            },
            {
              label: 'Vertrag Kunde',
            },
            {
              label: 'Vertrag Lieferant',
            },
          ];
          break;
  
        case 5:
          this.items = [
            {
              label: 'Angebot',
            },
            {
              label: 'Position',
            },
            {
              label: 'Assignment',
            },
            {
              label: 'Vertrag Kunde',
            },
            {
              label: 'Vertrag Lieferant',
            },
            {
              label: 'Zusammenfassung',
            },
          ];
          break;
  
        // case 5:
        //   this.items = [
        //     {
        //       label: 'Position',
        //     },
        //     {
        //       label: 'Assignment',
        //     },
        //     {
        //       label: 'PSP-Element',
        //     },
        //     {
        //       label: 'Vertrag Kunde',
        //     },
        //     {
        //       label: 'Vertrag Lieferant',
        //     },
        //     {
        //       label: 'Abschluss',
        //     },
        //   ];
  
        //   break;
  
        default:
          break;
      }

    } else{

      switch (this.maxPositionStepIndex) {

        case 0:
          this.items = [
            {
              label: 'Angebot',
            }
          ];
          break;

        case 1:
          this.items = [
            {
              label: 'Angebot',
            },
            {
              label: 'Position',
            },
          ];
          break;
  
        case 2:
          this.items = [
            {
              label: 'Angebot',
            },
            {
              label: 'Position',
            },
            {
              label: 'Assignment',
            },
          ];
          break;

          case 3:
            this.items = [
              {
                label: 'Angebot',
              },
              {
                label: 'Position',
              },
              {
                label: 'Assignment',
              },
              {
                label: 'Vertrag Kunde',
              },
              {
                label: 'Vertrag Lieferant',
                disabled: this.is_intern,
                tooltip: 'Kein Ressourceneinkaufsvertrag nötig'
              },
            ];
            break;
    
          case 5:
            this.items = [
              {
                label: 'Angebot',
              },
              {
                label: 'Position',
              },
              {
                label: 'Assignment',
              },
              {
                label: 'Vertrag Kunde',
              },
              {
                label: 'Vertrag Lieferant',
                disabled: this.is_intern,
                tooltip: 'Kein Ressourceneinkaufsvertrag nötig'
              },
              {
                label: 'Zusammenfassung',
              },
            ];
            break;
  
        // case 5:
        //   this.items = [
        //     {
        //       label: 'Position',
        //     },
        //     {
        //       label: 'Assignment',
        //     },
        //     {
        //       label: 'PSP-Element',
        //     },
        //     {
        //       label: 'Vertrag Kunde',
        //     },
        //     {
        //       label: 'Vertrag Lieferant',
        //     },
        //     {
        //       label: 'Abschluss',
        //     },
        //   ];
  
        //   break;
  
        default:
          break;
      }

    }
    
  }

  nextStep() {
    this.maxPositionStepIndex++;
  }

  lastStep() {
    this.maxPositionStepIndex--;
  }
}

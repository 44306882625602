import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';
import { User } from '../model';
import { Subscription } from 'rxjs';
import { EmployeeService } from '../services/employee.service';
import { NavService } from '../services/nav.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  currentUser: User;
  currentRoles: any;
  RoleIDs: any;
  currentUserSubscription: Subscription;
  currentRolesSubscription: Subscription;
  routeIsPublic: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    public router: Router,
    private employeeService: EmployeeService,
    public navServices: NavService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));

    if (this.isRoutePublic(state.url)) {
      return true;
    }

    if (this.currentUser === null) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe((user) => {
        this.currentUser = user;
      });
    } else {
      this.updateRoleIDs();

      if (this.isRouteAccessible(state.url)) {
        console.log('Route is accessible');
        return true;
      }

      this.router.navigate(['/error/404']);
      return false;
    }
    console.log('Routing back');

    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  private updateRoleIDs() {
    if (this.RoleIDs === null) {
      this.currentRolesSubscription = this.employeeService.getRoles(this.currentUser.PersonID).subscribe((roles) => {
        this.currentRoles = roles;
        this.RoleIDs = this.currentRoles.map((x) => x.RoleID);
        if (this.RoleIDs !== null) {
          sessionStorage.setItem('roleID', JSON.stringify(this.RoleIDs));
        }
      });
    } else {
      this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
    }
  }

  private isRoutePublic(url: string): boolean {
    const accessibleRoutes = [
      '/performance-evaluation/detail',
    ];

    if (accessibleRoutes.includes(url)) {
      return true;
    } else {
      return false;
    }
  }

  private isRouteAccessible(url: string): boolean {
    const accessibleRoutes = [
      '/dashboard/default',
      '/error/404',
      '/comingsoon',
      '/maintenance',
      '/forbidden',
      '/auth/login',
      '/performance-evaluation/detail',
    ];

    if (accessibleRoutes.includes(url)) {
      return true;
    }

    const routePermissions = [
      { pattern: '/expenses/.*', allowedRoles: ['114', '107'] },
      { pattern: '/businessdivision/sales.*', allowedRoles: ['141', '107'] },
      { pattern: '/businessdivision/technology.*', allowedRoles: ['107'] },
      { pattern: '/businessdivision/finance.*', allowedRoles: ['135', '107'] },
      { pattern: '/businessdivision/contracts.*', allowedRoles: ['107'] },
      { pattern: '/dashboard/.*', allowedRoles: ['103', '107'] },
      { pattern: '/school/list/*', allowedRoles: ['113', '107'] },
      { pattern: '/school/my-bookings/*', allowedRoles: ['113', '107'] },
      { pattern: '/school/admin/*', allowedRoles: ['119','155','107'] },
      { pattern: '/xrm/*', allowedRoles: ['107', '108'] },
      { pattern: '/teamleitung/*', allowedRoles: ['104', '107'] },
      { pattern: '/service-desk/.*', allowedRoles: ['103', '107'] },
      { pattern: '/my-client/.*', allowedRoles: ['103', '107'] },
      { pattern: '/shared/*', allowedRoles: ['107'] },
    ];

    for (const routePermission of routePermissions) {
      const regExp = new RegExp(routePermission.pattern);
      if (regExp.test(url) && this.RoleIDs.some((el) => routePermission.allowedRoles.includes(el))) {
        return true;
      }
    }

    return false;
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../../guard';
import { BpContactCrudComponent } from './bp-contact-crud/bp-contact-crud.component';
import { BusinesspartnerCrudComponent } from './businesspartner-crud/businesspartner-crud.component';
import { LeadCrudComponent } from './lead-crud/lead-crud.component';


const routes: Routes = [ 
  {
    path: 'dashboard/stepper',
    children: [
      {
        path: 'bp',
        outlet: 'stepperOutlet',
        canActivate:[AuthGuard],
        component: BusinesspartnerCrudComponent,
        data: {
          title: "BP",
          breadcrumb: "Crud"
        }
      },
      {
        path: 'bp-contact',
        outlet: 'stepperOutlet',
        canActivate:[AuthGuard],
        component: BpContactCrudComponent,
        data: {
          title: "BP-Kontakt",
          breadcrumb: "Crud"
        }
      },
      {
        path: 'lead',
        canActivate:[AuthGuard],
        component: LeadCrudComponent,
        data: {
          title: "Lead",
          breadcrumb: "Crud"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderToCashRoutingModule { }

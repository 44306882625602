// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invalid-field.ng-invalid-required.ng-touched {
    border-color: red;
  }

.input.ng-invalid-required .ng-touched {
    color: red
  }

/* 
  input:required {
    border-color: #800000;
    border-width: 3px;
  }
  
  input:required:invalid {
    border-color: #c00000;
  } */


  :host ::ng-deep {
    .p-sidebar.p-sidebar-right {
      width: 50rem;
      height: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/app/shared/components/order-to-cash/businesspartner-crud/businesspartner-crud.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;EACnB;;AAEF;IACI;EACF;;AAEF;;;;;;;;KAQK;;;EAGH;IACE;MACE,YAAY;MACZ,YAAY;IACd;EACF","sourcesContent":[".invalid-field.ng-invalid-required.ng-touched {\n    border-color: red;\n  }\n\n.input.ng-invalid-required .ng-touched {\n    color: red\n  }\n\n/* \n  input:required {\n    border-color: #800000;\n    border-width: 3px;\n  }\n  \n  input:required:invalid {\n    border-color: #c00000;\n  } */\n\n\n  :host ::ng-deep {\n    .p-sidebar.p-sidebar-right {\n      width: 50rem;\n      height: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Address } from '../model/xrm/address';
import { Contact } from '../model/xrm/contact';
import { Project } from '../model/xrm/project';
import { Businesspartner } from '../model/xrm/businesspartner';
import { MarketingItem } from '../model/xrm/marketingitem';
import { HardFactsItem } from '../model/xrm/hardfactsitem';
import { Appointment } from '../model/xrm/appointment';
import { Note } from '../model/xrm/note';
import { Position } from '../model/xrm/position';
import { PSPElemente } from '../model/pspelemente';

@Injectable({ providedIn: 'root' })
export class CrmService {
  constructor(private http: HttpClient) {}

  getEngagementPartner() {
    return this.http.get<any>(`${environment.apiUrl}/engagementPartner`);
  }

  getPersons() {
    return this.http.get<any>(`${environment.apiUrl}/employees`);
  }

  getBranches() {
    return this.http.get<any>(`${environment.apiUrl}/businesspartnerBranche`);
  }

  getBusinesspartners() {
    return this.http.get<Businesspartner>(`${environment.apiUrl}/businesspartners`);
  }

  getBusinesspartnersForMyClient() {
    return this.http.get<Businesspartner>(`${environment.apiUrl}/businesspartners/myClient`);
  }

  getActions() {
    return this.http.get<any>(`${environment.apiUrl}/actions`);
  }

  getAppointmentByID(AppointmentID: number) {
    return this.http.get<Appointment>(`${environment.apiUrl}/appointment/` + AppointmentID);
  }

  getAppointments() {
    return this.http.get<Appointment>(`${environment.apiUrl}/appointments`);
  }

  deleteAppointment(AppointmentID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/appointment/` + AppointmentID);
  }

  getAddresses() {
    return this.http.get<Address>(`${environment.apiUrl}/addresses`);
  }

  getWholeBusinesspartnerData() {
    return this.http.get<any>(`${environment.apiUrl}/wholeBusinesspartnerData`);
  }

  getBusinesspartnersByEp(id: number) {
    return this.http.get<Businesspartner>(`${environment.apiUrl}/businesspartners/` + id);
  }

  getBusinesspartnersByID(BusinesspartnerID: number) {
    return this.http.get<Businesspartner>(`${environment.apiUrl}/businesspartnerbyID/` + BusinesspartnerID);
  }

  getPersonsByID(epID, gaID) {
    if (epID == null) {
      epID = 0;
    }
    if (gaID == null) {
      gaID = 0;
    }
    return this.http.get<any>(`${environment.apiUrl}/crmPersons/` + epID + '/' + gaID);
  }

  getBusinesspartnerAddress(BusinesspartnerID: number) {
    return this.http.get<any>(`${environment.apiUrl}/businesspartnerAddresses/` + BusinesspartnerID);
  }

  deleteBusinesspartner(BusinesspartnerID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/businesspartner/` + BusinesspartnerID);
  }

  deleteContact(ContactID, BusinesspartnerID) {
    return this.http.delete<any>(`${environment.apiUrl}/contact/` + ContactID + '/' + BusinesspartnerID);
  }

  getContacts() {
    return this.http.get<Contact>(`${environment.apiUrl}/contacts`);
  }

  getWholeContactData() {
    return this.http.get<any>(`${environment.apiUrl}/wholeContactData`);
  }
  getContactById(ContactID: number) {
    return this.http.get<Contact>(`${environment.apiUrl}/contact/` + ContactID);
  }

  getWholeBusinesspartnerDetailData(epID, gaID, ContactID, BusinesspartnerID) {
    if (epID == null) {
      epID = 0;
    }
    if (gaID == null) {
      gaID = 0;
    }
    if (ContactID == null) {
      ContactID = 0;
    }
    if (BusinesspartnerID == null) {
      BusinesspartnerID = 0;
    }
    return this.http.get<any>(
      `${environment.apiUrl}/wholeBusinesspartnerDetailData/` +
        epID +
        '/' +
        gaID +
        '/' +
        ContactID +
        '/' +
        BusinesspartnerID
    );
  }

  getAllProjects() {
    return this.http.get<Project>(`${environment.apiUrl}/projects`);
  }

  getXrmDashboardData() {
    return this.http.get<any>(`${environment.apiUrl}/xrm/dashboard-data`);
  }

  getProjectsById(BusinesspartnerID) {
    return this.http.get<Project>(`${environment.apiUrl}/projects/` + BusinesspartnerID);
  }

  getTeams() {
    return this.http.get<any>(`${environment.apiUrl}/teams`);
  }

  getProjectById(ProjectID) {
    return this.http.get<Project>(`${environment.apiUrl}/project/` + ProjectID);
  }

  getWholeProjectDetailData(ProjectID) {
    return this.http.get<any>(`${environment.apiUrl}/wholeProjectsDetailData/` + ProjectID);
  }

  deleteProject(ProjectID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/project/` + ProjectID);
  }

  getMarketingItems() {
    return this.http.get<MarketingItem>(`${environment.apiUrl}/marketing`);
  }

  getHardFactsItems() {
    return this.http.get<HardFactsItem>(`${environment.apiUrl}/hardFacts`);
  }

  getPositionsByProjectID(ProjectID) {
    return this.http.get<Position>(`${environment.apiUrl}/position/` + ProjectID);
  }

  getWholeNewPositionData() {
    return this.http.get<any>(`${environment.apiUrl}/wholePositionData`);
  }

  getPositionByID(PositionID) {
    return this.http.get<any>(`${environment.apiUrl}/position/` + PositionID);
  }

  getWholePositionDetailData(PositionID) {
    return this.http.get<any>(`${environment.apiUrl}/wholePositionDetailData/` + PositionID);
  }

  //Notes

  getNoteByID(NoteID: number) {
    return this.http.get<Note>(`${environment.apiUrl}/note/` + NoteID);
  }

  deleteNote(NoteID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/note/` + NoteID);
  }

  //Position

  deletePosition(PositionID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/position/` + PositionID);
  }

  getPositions() {
    return this.http.get<Position[]>(`${environment.apiUrl}/positions`);
  }

  //Assignments

  deleteAssignment(AssignmentID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/assignment/` + AssignmentID);
  }

  getBpConsultantAssignments(PersonID, BusinesspartnerID, AssignmentID) {
    return this.http.get<any>(
      `${environment.apiUrl}/bpassignments/` + PersonID + '/' + BusinesspartnerID + '/' + AssignmentID
    );
  }

  getAssignmentbyID(AssignmentID) {
    return this.http.get<any>(`${environment.apiUrl}/assignmentbyID/` + AssignmentID);
  }

  //Consultants

  getConsultants() {
    return this.http.get<Businesspartner>(`${environment.apiUrl}/consultants`);
  }

  deleteConsultant(PersonID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/consultant/` + PersonID);
  }

  getConsultant(PersonID: number) {
    return this.http.get<any>(`${environment.apiUrl}/consultant/` + PersonID);
  }

  getWholeConsultantDetailData(PersonID) {
    return this.http.get<any>(`${environment.apiUrl}/wholeConsultantDetailData/` + PersonID);
  }

  //PSP-Elemente

  getPSPElements() {
    return this.http.get<PSPElemente[]>(`${environment.apiUrl}/pspelements`);
  }

  getActivePSPElements() {
    return this.http.get<PSPElemente[]>(`${environment.apiUrl}/activePspelements`);
  }

  getPSPElementByID(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/pspelement/` + id);
  }

  getPSPElementByPersonID(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/pspelements/` + id);
  }

  getAllPSPElementByPersonID(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/allPspelementsByPersonID/` + id);
  }

  getPSPElementsbyProject(ProjectID: number) {
    return this.http.get<PSPElemente[]>(`${environment.apiUrl}/pspelements` + ProjectID);
  }

  deletePSPElement(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/pspelement/delete/` + id);
  }

  //Reports

  getReportPositions() {
    return this.http.get<Position[]>(`${environment.apiUrl}/report/positions`);
  }

  getDeliveryMasterConsultants() {
    return this.http.get<any>(`${environment.apiUrl}/report/delivery-master-list`);
  }

  getMyTeamDeliveryMasterConsultants() {
    return this.http.get<any>(`${environment.apiUrl}/report/delivery-master-list/my-team`);
  }

  getDMAvailableRessources() {
    return this.http.get<any>(`${environment.apiUrl}/report/delivery-master-available`);
  }

  getReportProlongation() {
    return this.http.get<any>(`${environment.apiUrl}/report/project-prolongation`);
  }

  getReportResrictions() {
    return this.http.get<any>(`${environment.apiUrl}/report/project-restrictions`);
  }

  getProjectrestrictionByID(PersonID) {
    return this.http.get<any>(`${environment.apiUrl}/projectrestriction/` + PersonID);
  }

  getProjectprolongationByID(PersonID, AssignmentID) {
    return this.http.get<any>(`${environment.apiUrl}/projectprolongation/` + PersonID + '/' + AssignmentID);
  }

  //CSP
  getCspData() {
    return this.http.get<any>(`${environment.apiUrl}/cspData`);
  }

  getAllChances() {
    return this.http.get<any>(`${environment.apiUrl}/chances`);
  }

  getWholeChanceDetailData(ChanceID) {
    return this.http.get<any>(`${environment.apiUrl}/wholeChanceDetailData/` + ChanceID);
  }

  getMyChances() {
    return this.http.get<any>(`${environment.apiUrl}/myChances`);
  }

  deleteChance(ChanceID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/chance/` + ChanceID);
  }

  deleteChanceRecord(ChancesPRecordID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/chanceRecord/` + ChancesPRecordID);
  }

  deleteUserFromChance(PersonID, ChanceID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/userFromChance/` + PersonID + '/' + ChanceID);
  }

  //SOlution Manager
  getSolutionManagerData() {
    return this.http.get<any>(`${environment.apiUrl}/solutionManagerData`);
  }

  getSolutionManagerDetailData(SolutionManagerID) {
    return this.http.get<any>(`${environment.apiUrl}/solutionManagerDetailData/` + SolutionManagerID);
  }

  getAllSolutionManagers() {
    return this.http.get<any>(`${environment.apiUrl}/solutionManagers`);
  }

  deleteSolutionArtefact(SolutionArtefactID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/solutionArtefact/` + SolutionArtefactID);
  }

  deleteSolutionBP(SolutionBPID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/solutionBP/` + SolutionBPID);
  }

  deleteSolutionChance(SolutionChanceID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/solutionChance/` + SolutionChanceID);
  }

  deleteSolutionProject(SolutionProjectID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/solutionProject/` + SolutionProjectID);
  }

  deleteSolutionManager(SolutionManagerID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/solutionManager/` + SolutionManagerID);
  }
}

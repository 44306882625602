import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
//import { Cps as cps } from '../model';


@Injectable({ providedIn: 'root' })
export class BusinessDivisionService {
  constructor(private http: HttpClient) { }


  //-------------------------Geschäftsbereich Finance------------------------------------------

  getPerformanceRecordUploads(month, year){  
    return this.http.get<any>(`${environment.apiUrl}/performance_records/uploads/`+ month + "/" + year);
  }

  getPerformanceRecordFakturaDetailData(selected_upload){
    return this.http.get<any>(`${environment.apiUrl}/v3/performance_records/upload/detail/`+ selected_upload.PSPElementeID + "/" + selected_upload.PRecordID);
  }

  editPerformanceRecordFakturaDetailData(data, PRecordID){
    const headers = new HttpHeaders();
    return this.http.post<any>(`${environment.apiUrl}/performance_records/upload/edit/`+PRecordID, data, {
      headers: headers
    });
  }

  updatePerformanceRecordUpload(data, PRecordID) {

    const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/performance_records/upload/edit/`+PRecordID, data, {
            headers: headers
        });
   
  }

  deletePerformanceRecordUpload(PRecordID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/performance_records/delete/` + PRecordID);
  }


  createFinanceFakturaComment(actualPSPElement, month, year){

    return this.http.post<any>(`${environment.apiUrl}/financeFakturaComment/create`, {

      'PSPElementeID': actualPSPElement.PSPElementeID,
      'FakturaComment': actualPSPElement.FakturaComment,
      'Month': month,
      'Year': year,
    })

  }

  editFinanceFakturaComment(comment_id, comment){

    return this.http.put<any>(`${environment.apiUrl}/financeFakturaComment/edit/` + comment_id, {

      'FakturaComment': comment

    })

  }


  getFakturaDetail(PSPElementeID: number, PRecordID: number) {
    return this.http.get<any>(`${environment.apiUrl}/financeInvoice/detail/` + PSPElementeID + "/" + PRecordID);
  }

  getInvoices() {
    return this.http.get<any>(`${environment.apiUrl}/financeInvoices`);
  }

  getActivePSPElements() {
    return this.http.get<any>(`${environment.apiUrl}/financeactivepspelements`);
  }

  getAllianceUploads(month, year){

    return this.http.get<any>(`${environment.apiUrl}/alliance/uploads/`+ month + "/" + year);

  }

  getAllianceFakturaDetailData(selected_upload){

    return this.http.get<any>(`${environment.apiUrl}/v3/alliance/upload/detail/`+ selected_upload.access_pspelement_id + "/" + selected_upload.id);

  }

  getUpload(file_name) {

    return this.http.get<any>(`${environment.apiUrl}/show_doc/`+ file_name);

  }

  editAllianceFakturaDetailData(data, accounting_id) {

    const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/alliance/upload/edit/`+accounting_id, data, {
            headers: headers
        });
   
  }

  deleteOutgoingInvoiceUpload(alliance_accounting_id: number) {

    return this.http.delete<any>(`${environment.apiUrl}/alliance/outgoing_invoice/delete/` + alliance_accounting_id)

  }

  editFinanceAllianceFakturaComment(comment_id, comment){

    return this.http.put<any>(`${environment.apiUrl}/alliance/factura_comment/edit/` + comment_id, {

      'AllianceFakturaComment': comment

    })

  }

  createFinanceAllianceFakturaComment(actualPSPElement, month, year){

    return this.http.post<any>(`${environment.apiUrl}/alliance/factura_comment/create`, {

      'PSPElementeID': actualPSPElement.pspelement_id,
      'AllianceFakturaComment': actualPSPElement.AllianceFakturaComment,
      'Month': month,
      'Year': year,
    })

  }


  //----------------------------

  getDBMargeData() {
    return this.http.get<any>(`${environment.apiUrl}/dbMargeData`);
  }

  getDBMargeByPersonID(PersonID) {
    return this.http.get<any>(`${environment.apiUrl}/dbMargeData/person/` + PersonID);
  }

  getQualificationLevels() {
    return this.http.get<any>(`${environment.apiUrl}/qualificationlevels`);
  }

  getQualificationLevels2() {
    return this.http.get<any>(`${environment.apiUrl}/qualificationlevels2`);
  }

  getContractsByType(mainTyp, subType) {
    return this.http.get<any>(`${environment.apiUrl}/contracts/` + mainTyp + "/" + subType);
  }

  getContractCategorys() {
    return this.http.get<any>(`${environment.apiUrl}/contractCategorys`);
  }

  getContractMainCategorys() {
    return this.http.get<any>(`${environment.apiUrl}/contractMainCategorys`);
  }

  getContractsByID(contractID, mainTyp, subType) {
    return this.http.get<any>(`${environment.apiUrl}/contractByID/` + contractID + "/" + mainTyp + "/" + subType);
  }

  getContractData() {
    return this.http.get<any>(`${environment.apiUrl}/contractData`);
  }

  getMainContracts() {
    return this.http.get<any>(`${environment.apiUrl}/mainContracts`);
  }

  getProlongationsInProject(PersonID, ProjectID) {
    return this.http.get<any>(`${environment.apiUrl}/prolongationInProject/` + PersonID + "/" + ProjectID);
  }

  deleteContractDoc(ContractDocID: number, ContractMainID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/contractdatafiles/` + ContractDocID + "/" + ContractMainID);
  }

  getContractDashboardData() {
    return this.http.get<any>(`${environment.apiUrl}/contract_dashboard_data`);
  }


  getAPPAccessToken() {
    return this.http.get<any>(`${environment.apiUrl}/application/accessToken/azWEHsffajQEGmwqeGSh912747JR132tSAEFuheAwrf101hasAffu2nase9aawH2W`);
  }

  //------------------------------------------Geschäftsbereich Vertrieb------------------------------------

  //Alliance Einladungen
  getAllianceInvites() {
    return this.http.get<any>(`${environment.apiUrl}/allianceinvites`);
  }

  //Alliance User Einladungen
  getAllianceInviteUsers(BusinesspartnerID) {
    return this.http.get<any>(`${environment.apiUrl}/allianceinvite_users/` + BusinesspartnerID);
  }

  createAllianceInviteUser(selected_businesspartner, newuser) {

    return this.http.post<any>(`${environment.apiUrl}/allianceinvite_user/create`, {

      'BusinesspartnerID': selected_businesspartner.BusinesspartnerID
      , 'Forename': newuser.Forename
      , 'Surname': newuser.Surname
      , 'EMail': newuser.EMail
      , 'Role': newuser.assigned_role
      , 'Invite': 'true'

    })
  }

  editAllianceInviteUser(user) {

    return this.http.put<any>(`${environment.apiUrl}/allianceinvite_user/edit/` + user.PersonID, {

      'Forename': user.Forename
      , 'Surname': user.Surname
      , 'EMail': user.EMail
      , 'Role': user.assigned_role
      , 'Invite': user.Invite

    })
  }

  deleteAllianceInviteUser(PersonID) {

    return this.http.delete<any>(`${environment.apiUrl}/allianceinvite_user/delete/` + PersonID);

  }

  inviteAllianceCompany(selected_businesspartner) {

    return this.http.post<any>(`${environment.apiUrl}/allianceinvite/new`, {

      'BusinesspartnerID': selected_businesspartner.BusinesspartnerID

    })
  }

  createAllianceInvite(selected_businesspartner) {

    return this.http.post<any>(`${environment.apiUrl}/allianceinvite/create`, {

      'BusinesspartnerID': selected_businesspartner.BusinesspartnerID

    })
  }

  editAllianceInvite(selected_invite) {

    return this.http.put<any>(`${environment.apiUrl}/allianceinvite/edit/` + selected_invite.BusinesspartnerID, {

      'Invited': selected_invite.Invited

    })

  }

  getSalesReport() {
    return this.http.get<any>(`${environment.apiUrl}/sales/report`);
  }

  //------------------------------------Projektangebote------------------------------------

  getAllianceProjectOffers() {
    return this.http.get<any>(`${environment.apiUrl}/alliance_projectoffers`);
  }

  createAllianceProjectOffer(newoffer) {

    return this.http.post<any>(`${environment.apiUrl}/alliance_projectoffer/create`, {

      'access_customer_id': newoffer.access_customer_id
      , 'access_customer_name': newoffer.access_customer_name
      , 'title': newoffer.title
      , 'description': newoffer.description
      , 'start': newoffer.start
      , 'end': newoffer.end
      , 'budget': newoffer.budget
      , 'status': newoffer.status
      , 'location': newoffer.location
      , 'prolongation': newoffer.prolongation
      , 'ep_person_id': newoffer.ep_person_id
      , 'ep_name': newoffer.ep_name
      , 'ep_email': newoffer.ep_email
      , 'ep_mobile': newoffer.ep_mobile

    })
  }

  editAllianceProjectOffer(offer) {

    return this.http.put<any>(`${environment.apiUrl}/alliance_projectoffer/edit/` + offer.id, {

      'access_customer_id': offer.access_customer_id
      , 'access_customer_name': offer.access_customer_name
      , 'title': offer.title
      , 'description': offer.description
      , 'start': offer.start
      , 'end': offer.end
      , 'budget': offer.budget
      , 'status': offer.status
      , 'location': offer.location
      , 'prolongation': offer.prolongation
      , 'ep_person_id': offer.ep_person_id
      , 'ep_name': offer.ep_name
      , 'ep_email': offer.ep_email
      , 'ep_mobile': offer.ep_mobile

    })
  }

  deleteAllianceProjectOffer(id) {

    return this.http.delete<any>(`${environment.apiUrl}/alliance_projectoffer/delete/` + id);

  }


  getAllAllianceCompanies() {
    return this.http.get<any>(`${environment.apiUrl}/alliance/allCompanies`);
  }

  //-----------------------------------------GB Technology-----------------------------------------
  
  getTechnologyDataForUser(personID: number) {
    return this.http.get<any>(`${environment.apiUrl}/technologyDataForUser/`+personID);
  }


}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled-label {
    color: rgb(170, 170, 170);
}

.disabled-background {
    background-color: #e7e7e7 !important;
}

:host ::ng-deep {
    .p-sidebar.p-sidebar-right {
      width: 75rem;
      height: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/app/shared/components/order-to-cash/lead-crud/lead-crud.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI;MACE,YAAY;MACZ,YAAY;IACd;EACF","sourcesContent":[".disabled-label {\n    color: rgb(170, 170, 170);\n}\n\n.disabled-background {\n    background-color: #e7e7e7 !important;\n}\n\n:host ::ng-deep {\n    .p-sidebar.p-sidebar-right {\n      width: 75rem;\n      height: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

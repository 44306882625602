import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription, firstValueFrom } from 'rxjs';
import { ProcessStepperComponent } from 'src/app/shared/components/order-to-cash/process-stepper/process-stepper.component';
import { BusinessDivisionService } from 'src/app/shared/services/businessdivision.service';
import { CrmService } from 'src/app/shared/services/crm.service';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services';

@Component({
  selector: 'app-xrm-dashboard',
  templateUrl: './xrm-dashboard.component.html',
  styleUrls: ['./xrm-dashboard.component.scss'],
})
export class XrmDashboardComponent implements OnInit, OnDestroy {
  activeStepIndex;
  public maxStepIndex;

  newElementDialog = false;

  preassignments;
  my_preassignments;


  loading = true;

  //for check if we need a fresh componen
  //   data_for_passing: Object;
  //   is_new_data: Boolean;

  //orders data for main chart
  orders: any = {
    monthlyData: {
      dateRange: 'letzte 12 Monate',
      orders: [122, 584, 646, 221, 135, 453, 111, 158, 425, 156, 454, 456],
      totalSales: '$109,788.00',
    },
    weeklyData: {
      dateRange: 'letzte 24 Wochen',
      orders: [28, 58, 44, 16, 42, 8, 15, 26, 38, 46, 15, 46, 89, 45, 41, 22, 17, 43, 12, 45, 24, 16, 54, 49],
      totalSales: '$20,136.00',
    },
  };

  //main chart data
  chartData: any;
  chartOptions: any;

  //clients chart Data
  chart1: any;
  chartOptions1: any;

  //pie data for sales
  pieData: any;
  pieOptions: any;

  // dropdown date ranges
  dateRanges: any[] = [
    { name: 'Woche', code: 'WEEK' },
    { name: 'Monat', code: 'MONTH' },
  ];
  selectedDate: any;

  // popup menu items for waiting actions
  items: MenuItem[] = [
    {
      icon: 'pi pi-check',
      label: 'Prüfen',
    },
  ];

  sidebar_title_id;
  sidebar_title;
  sidebarVisible = false;


  active_projects;
  psp_elements_almost_done;

  //config subscription
  subscription: Subscription;

  totalSales = 0;
  top10Sales;
  sales_amount_by_businesspartner;

  sales_data;
  sales_options;

  leads;
  my_leads;
  leads_data;
  leads_options;
  top10Leads;
  totalLeads;

  current_user;


  lineChartData: any;
  lineChartOptions: any;
  amount_consultants_psp;


  constructor(
    private businessDivisionService: BusinessDivisionService,
    private router: Router,
    private crmService: CrmService,
    private authenticationService: AuthenticationService
  ) { }

  async ngOnInit() {

    this.current_user = this.authenticationService.currentUserValue;

    try {
      const x = await firstValueFrom(this.crmService.getXrmDashboardData());

      this.active_projects = x.active_projects;
      this.psp_elements_almost_done = x.psp_elements_almost_done;
      this.totalSales = x.totalSales;

      this.sales_amount_by_businesspartner = x.sales_amount_by_businesspartner;

      this.leads = x.leads;
      this.my_leads = x.leads.filter(element => element.LeadManagerID == this.current_user.PersonID);

      this.top10Leads = x.top10Leads;
      this.totalLeads = x.totalLeads;

      this.top10Sales = x.top10Sales;

      this.amount_consultants_psp = x.amount_consultants_psp;

      this.my_preassignments = x.preassignments.filter(element => element.DeliveryResponsible == this.current_user.PersonID);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      this.loading = false;
      this.initPieChartSales();
      this.initPieChartLeads();
      this.initializeLineChartData(this.amount_consultants_psp);
      this.initChart();
      this.selectedDate = this.dateRanges[0];
    }



  }

  openEditDialog(type, clear_storage) {
    switch (type) {
      case 'bp':
        this.activeStepIndex = 0;
        this.maxStepIndex = 0;
        break;

      case 'contact':
        this.activeStepIndex = 1;
        this.maxStepIndex = 1;
        break;

      case 'lead':
        this.activeStepIndex = 2;
        this.maxStepIndex = 2;
        break;

      case 'project':
        this.activeStepIndex = 3;
        this.maxStepIndex = 3;
        break;

      case 'position':
        this.activeStepIndex = 4;
        this.maxStepIndex = 4;
        break;

      default:
        break;
    }

    if (clear_storage) this.removeSessionStorage();
    // this.data_for_passing = data;
    // this.is_new_data = is_new_data;
    this.newElementDialog = true;
  }

  closeEditDialog() {
    this.newElementDialog = false;
  }

  initPieChartSales() {

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    const businessPartners = Object.values<any>(this.top10Sales)
      .map(item => `${item.BusinesspartnerName}`);

    const businessPartnersValue = Object.values<any>(this.top10Sales)
      .map(item => Number(item.TotalSales));

    this.sales_data = {
      labels: businessPartners,
      datasets: [
        {
          data: businessPartnersValue,
          backgroundColor: [documentStyle.getPropertyValue('--blue-500'), documentStyle.getPropertyValue('--yellow-500'),
          documentStyle.getPropertyValue('--green-500'), documentStyle.getPropertyValue('--blue-500'), documentStyle.getPropertyValue('--yellow-500'),
          documentStyle.getPropertyValue('--red-500'), documentStyle.getPropertyValue('--orange-500'), documentStyle.getPropertyValue('--surface-500'),
          documentStyle.getPropertyValue('--cyan-500'), documentStyle.getPropertyValue('--indigo-500')],
          hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'),
          documentStyle.getPropertyValue('--green-400'), documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'),
          documentStyle.getPropertyValue('--red-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--surface-400'),
          documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--indigo-400')]
        }
      ]
    };

    console.log(this.sales_data);


    this.sales_options = {
      cutout: '60%',
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      }
    };

  }

  initPieChartLeads() {

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    const businessPartners = Object.values<any>(this.top10Leads)
      .map(item => `${item.BusinesspartnerName}`)
      .join(', ');

    const businessPartnersValue = Object.values<any>(this.top10Leads)
      .map(item => `${item.ExpectedVolume * item.Probability}`)
      .join(', ');


    console.log(businessPartners);
    console.log(businessPartnersValue);

    this.leads_data = {
      labels: [businessPartners],
      datasets: [
        {
          data: [businessPartnersValue],
          backgroundColor: [documentStyle.getPropertyValue('--blue-500'), documentStyle.getPropertyValue('--yellow-500'), documentStyle.getPropertyValue('--green-500')],
          hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'), documentStyle.getPropertyValue('--green-400')]
        }
      ]
    };

    console.log(this.leads_data);


    this.leads_options = {
      cutout: '60%',
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      }
    };

  }

  initializeLineChartData(data) {

    this.lineChartData = {
      labels: data.map(item => item.month),
      datasets: [
        {
          label: 'Anzahl',
          data: data.map(item => item.value.toString()),
          fill: false,
          borderColor: '#7c70d6',
          tension: 0.4,
        },
      ],
    };

    this.lineChartOptions = {
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
      },
      scales: {
        x: {
          display: true,
        },
        y: {
          beginAtZero: true, // This ensures the scale starts at zero
          min: 0, // Sets the minimum value to 0
          display: true,
          ticks: {
            stepSize: 1,  // Ensures that the interval between ticks is an integer
            // Additional tick configuration here
          }
        },
      },
    };
  }

  preAssignmentDetail(preassignment) {

    this.router.navigate(['/xrm/edit-lead2order'], {
      queryParams: { stringValue: 'lead', booleanValue: false, edit: preassignment.LeadID }
    });

  }

  onActiveProjectSelect(event) {

    this.router.navigate(['/xrm/reports/project-detail/', event.data.ProjectID]);

  }

  onBPSelect(event) {

    this.router.navigate(['/xrm/reports/bp-detail/', event.data.BusinesspartnerID]);

  }


  initChart() {

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.chartData = {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: [
        {
          label: 'beauftragt',
          data: [
            8000, 8100, 5600, 5500, 4000, 6500, 5900, 8000, 8100,
            5600, 5500, 4000,
          ],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--green-300'),
          tension: 0.4,
          borderWidth: 1,
          backgroundColor: '#4caf5061',
          borderRadius: 6,
        },
        {
          label: 'beauftragt + Lead',
          data: [
            1200, 5100, 6200, 3300, 2100, 6200, 4500, 1200, 5100,
            6200, 3300, 2100,
          ],

          borderColor: documentStyle.getPropertyValue('--red-300'),
          backgroundColor: '#ff3d3238',
          tension: 0.4,
          borderWidth: 1,
          borderRadius: 6,
        },
      ],
    };

    //bar chart options
    this.chartOptions = {
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          labels: {
            color: textColor,
            usePointStyle: true,
            boxHeight: 15,
            pointStyleWidth: 17,
            padding: 14,
          },
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';

              if (label) {
                label += ':';
              }

              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(context.parsed.y);
              }
              return label;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    //pie data and options
    this.pieData = {
      labels: ['Entertainment', 'Platform', 'Shopping', 'Transfers'],
      datasets: [
        {
          data: [300, 50, 100, 80],
          backgroundColor: [
            documentStyle.getPropertyValue('--red-200'),
            documentStyle.getPropertyValue('--orange-200'),
            documentStyle.getPropertyValue('--green-200'),
            documentStyle.getPropertyValue('--cyan-200'),
          ],
          borderColor: surfaceBorder,
        },
      ],
    };

    this.pieOptions = {
      animation: {
        duration: 0,
      },
      responsive: true,
      mantainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: textColor,
            usePointStyle: true,
            padding: 14,
            boxHeight: 15,
            pointStyleWidth: 17,
          },
          position: 'bottom',
        },
      },
    };
  }

  //sum function for main chart data
  sumOf(array: any[]) {
    let sum: number = 0;
    array.forEach((a) => (sum += a));
    return sum;
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  removeSessionStorage() {
    //sessionStorage.clear();

    sessionStorage.removeItem('otc_actual_businesspartner_contact');
    sessionStorage.removeItem('otc_actual_businesspartner');
    sessionStorage.removeItem('otc_actual_project');
    sessionStorage.removeItem('otc_actual_lead');
    sessionStorage.removeItem('otc_actual_position');
    sessionStorage.removeItem('otc_actual_assignment');
    sessionStorage.removeItem('otc_actual_contract_client');
    sessionStorage.removeItem('otc_actual_contract_offer');
    sessionStorage.removeItem('otc_actual_contract_resource');
    sessionStorage.removeItem('otc_actual_preassignment');

    console.log(sessionStorage);
   
  }

  openSidebar(category) {

    this.sidebar_title_id = category;

    switch (category) {

      case 1:

        this.sidebar_title = 'Aktive Projekte';

        break;
      case 2:
        this.sidebar_title = 'Auslaufende Beauftragungen';
        break;

      case 3:
        this.sidebar_title = 'Umsatz aktuelles Jahr';
        break;
      case 4:
        this.sidebar_title = 'Leadvolumen gewichtet';
        break;

      default:
        break;

    }

    this.sidebarVisible = true;


  }

}

import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { XrmDashboardComponent } from 'src/app/components/xrm/xrm-dashboard/xrm-dashboard.component';
import { Contract } from 'src/app/shared/model/businessdivision/contract';
import { Employee } from 'src/app/shared/model/employee';
import { BusinesspartnerV2 } from 'src/app/shared/model/xrm/V2/businesspartnerV2';
import { ContactV2 } from 'src/app/shared/model/xrm/V2/contactV2';
import { ProjectV2 } from 'src/app/shared/model/xrm/V2/projectV2';
import { CrmService } from 'src/app/shared/services/crm.service';
import { XRMServiceV2 } from 'src/app/shared/services/xrmV2.service';

@Component({
  selector: 'app-project-crud',
  templateUrl: './project-crud.component.html',
  styleUrls: ['./project-crud.component.css'],
})
export class ProjectCrudComponent implements OnInit {
  actual_project: ProjectV2;

  //Models
  actual_businesspartner: BusinesspartnerV2 = new BusinesspartnerV2();
  actual_businesspartner_contact: ContactV2 = new ContactV2();
  selected_offer: Contract = new Contract();

  // Helper
  all_projects: ProjectV2[];
  all_offers: Contract[];
  all_contacts: Contract[];
  all_businesspartner: BusinesspartnerV2[];
  all_employees: Employee[];

  ProjectName: String;
  suggestions_projects: ProjectV2[];
  // filtered_projects = [];
  projectDetailCard = false;
  activeIndex = 0;
  selected_project: ProjectV2;
  loading = true;
  submitted = false;
  save_loading = false;

  projectstatus: Array<{ Name: string }> = [
    { Name: '1. Opportunity (Idee)' },
    { Name: '2. Delivery (Projekt)' },
    { Name: '3. Abgeschlossen' },
    { Name: '4. Verloren' },
  ];

  // status: Array<{ Name: string }> = [{ Name: 'Aktuell' }, { Name: 'Wurde zum Angebot' }, { Name: 'Verloren' }];

  //Passing Data

  //Dialog for Offer
  open_offer_dialog = false;
  actual_contract = new Contract();

  constructor(
    private crmService: CrmService,
    private appXRMDashboard: XrmDashboardComponent,
    private xrmServiceV2: XRMServiceV2,
    // private contractsNavigationComponent: ContractsNavigationComponent,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.loading = true;
    //TODO Welche Tabelle nehmen wir als Project?
    this.xrmServiceV2.getDataForProjectStep().subscribe((data) => {
      this.all_employees = data.all_employees;
      this.all_projects = data.all_projects;
      this.all_contacts = data.all_contacts;
      this.all_offers = data.all_offers;
      this.all_businesspartner = data.all_businesspartner;

      this.loadStoredData();
    });
  }

  loadStoredData() {
    let storedBusinessPartnerContact = sessionStorage.getItem('otc_actual_businesspartner_contact');
    this.actual_businesspartner_contact = storedBusinessPartnerContact
      ? JSON.parse(storedBusinessPartnerContact)
      : new ContactV2();

    let storedBusinessPartner = sessionStorage.getItem('otc_actual_businesspartner');
    this.actual_businesspartner = storedBusinessPartner ? JSON.parse(storedBusinessPartner) : new BusinesspartnerV2();

    let storedProject = sessionStorage.getItem('otc_actual_project');

    if (storedProject) {
      this.projectDetailCard = false;
      this.selectProject(JSON.parse(storedProject));
    } else {
      this.actual_project = new ProjectV2();
      this.projectDetailCard = true;
    }
    this.loading = false;
  }

  // In the component code
  searchForProject(search_text: string) {
    this.suggestions_projects = this.all_projects.filter((element) => element.ProjectName.includes(search_text));
  }

  selectProject(data) {
    this.actual_project = data;
    this.ProjectName = this.actual_project.ProjectName;
    this.actual_project
      ? sessionStorage.setItem('otc_actual_project', JSON.stringify(this.actual_project))
      : sessionStorage.removeItem('otc_actual_project');
  }

  openProjectDetailCard(project: ProjectV2) {
    if (project) {
      this.actual_project = project;
    } else {
      this.actual_project = new ProjectV2();
    }
    this.projectDetailCard = true;
  }

  closeProjectDetailCard() {
    this.projectDetailCard = false;
  }

  abortChanges() {
    this.actual_project = new ProjectV2();
    this.closeProjectDetailCard();
  }

  // saveAndSelectContact() {
  //   this.closeContactDetailCard();
  //   this.appXRMDashboard.openEditDialog('bp', false);
  // }

  saveAndSelectProject(add_offer: Boolean) {
    this.save_loading = true;

    if (this.actual_project.ProjectID != null) {
      this.xrmServiceV2.editProject(this.actual_project).subscribe((response) => {
        if (response.result == 'success') {
          this.messageService.add({
            severity: 'success',
            summary: 'Information',
            detail: response.message,
          });

          this.actual_project
            ? sessionStorage.setItem('otc_actual_project', JSON.stringify(this.actual_project))
            : sessionStorage.removeItem('otc_actual_project');
          // if (add_offer) this.openOfferDialog();
          if (!add_offer) this.closeProjectDetailCard();
        }
        if (response.result == 'error')
          this.messageService.add({
            severity: 'info',
            summary: 'Information',
            detail: response.message,
          });
        this.save_loading = false;
      });
    } else {
      // this.xrmServiceV2.createProject(this.actual_project).subscribe((response) => {
      //   if (response.result == 'success') {
      //     this.messageService.add({
      //       severity: 'success',
      //       summary: 'Information',
      //       detail: response.message,
      //     });

      //     this.actual_project = response.newlyCreatedObject;
      //     this.actual_project
      //       ? sessionStorage.setItem('otc_actual_project', JSON.stringify(this.actual_project))
      //       : sessionStorage.removeItem('otc_actual_project');

      //     // if (add_offer) this.openOfferDialog();
      //     if (!add_offer) this.closeProjectDetailCard();
      //   }
      //   if (response.result == 'error')
      //     this.messageService.add({
      //       severity: 'info',
      //       summary: 'Information',
      //       detail: response.message,
      //     });

      //   this.save_loading = false;
      // });
    }
  }

  goStepBack() {
    this.appXRMDashboard.openEditDialog('contact', false);
  }

  //Diolag for Offer
  openOfferDialog(contract: Contract) {
    if (!contract) {
      this.actual_contract = new Contract();
    } else {
      this.actual_contract = contract;
    }
    this.displayDialog = true;

    // this.contractsNavigationComponent.openNewFromStepper('Angebote', 3, this.actual_project);
  }

  displayDialog: boolean = false;
  dialogHeader: string = 'Example Dialog';
  dialogContent: string = 'This is a shared dialog';
  contracttype: string = 'Angebote';
  contractSubCategoryID: number = 3;
  contract_is_created = false;

  contractIsCreated(event: boolean) {
    this.contract_is_created = event;
  }

  closeDialog(event: boolean) {
    this.displayDialog = event;

    //CODE FOR EMPTY PROCJECT CASE
    if (this.contract_is_created) {
      console.log('Contract is created');
    }
  }
}

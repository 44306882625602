import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { SessionOverComponent } from './components/session-over/session-over.component';
// import { CustomizerComponent } from './components/customizer/customizer.component';

import { AppMenuitemComponent } from './components/sidebar/sidebarItem/menuitem.component';

import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';

import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';

import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToolbarModule } from 'primeng/toolbar';

import { TabViewModule } from 'primeng/tabview';
import { SplitButtonModule } from 'primeng/splitbutton';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';

// services
import { NavService } from './services/nav.service';
import { AuthenticationService } from './services/authentication.service';
import { GraphService } from './services';

import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { AppRightpanelComponent } from './components/rightpanel/app.rightpanel.component';
import { AppMyProfileComponent } from './components/my-profile/app.my-profile.component';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';

import { AuthGuard } from './guard';
import { OrderToCashRoutingModule } from './components/order-to-cash/order-to-cash-routing.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';

import { MycurrencyPipe } from 'src/app/shared/pipes/mycurrency.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';


@NgModule({
  declarations: [
    LoaderComponent,
    SessionOverComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    BreadcrumbComponent,
    //CustomizerComponent,
    AppMenuitemComponent,
    AppRightpanelComponent,
    AppMyProfileComponent,
    MycurrencyPipe,
    ShortNumberPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    RadioButtonModule,
    InputSwitchModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    InputTextModule,
    BreadcrumbModule,
    ToastModule,
    AvatarModule,
    BadgeModule,
    CheckboxModule,
    ButtonModule,
    RippleModule,
    SkeletonModule,
    DividerModule,
    FileUploadModule,
    TableModule,
    ConfirmDialogModule,
    ToolbarModule,
    TabViewModule,
    SplitButtonModule,
    PanelModule,
    FieldsetModule,
    SelectButtonModule,
    DialogModule,
    StepsModule,
    OrderToCashRoutingModule,
    AutoCompleteModule,
    KeyFilterModule,
    DropdownModule,
    ReactiveFormsModule,
    InputTextareaModule,
    CardModule,
    CalendarModule,
  ],
  exports: [RouterModule, LoaderComponent, MycurrencyPipe, ShortNumberPipe],
  providers: [GraphService, NavService, AuthenticationService, MessageModule],
})
export class SharedModule {}
